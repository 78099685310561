import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CredentialsTokensType } from '@cargos/sprintpay-models';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { StorageService, StorageTypes } from './storage.service';
@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private _profileType: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _jwtHelper: JwtHelperService = new JwtHelperService();
    private _currentToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private _refreshToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private _authorizationTokenId: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

    constructor(private _storageService: StorageService) {
        if (!!this._currentToken) {
            const authorizationToken: string | null = this._storageService.getElement(
                'currentUser',
                StorageTypes.SESSION
            );
            const refreshToken: string | null = this._storageService.getElement('accessControl', StorageTypes.SESSION);
            if (authorizationToken && refreshToken) {
                this.setAuthTokens({ authorizationToken, refreshToken });
            }
        }
        if (!!this._profileType) {
            const profileType: string | null = this._storageService.getElement('profileType', StorageTypes.SESSION);
            if (profileType) {
                this.setProfileType(profileType);
            }
        }
    }

    /**
     * @method setAuthToken()
     * @param (token: CredentialsTokensType)
     * @description Set tokens credentials: token and accessToken
     */

    setAuthTokens(token: CredentialsTokensType): void {
        this.setCurrentUser(token.authorizationToken);
        this.setAccessControl(token.refreshToken);
    }

    resetAuthTokens(): void {
        this._currentToken.next(null);
        this._refreshToken.next(null);
    }

    /**
     * @method setCurrentUser()
     * @param (token: string)
     * @description Set token function
     */

    setCurrentUser(token: string): void {
        this._storageService.removeElement('currentUser');
        this._storageService.saveElement('currentUser', token);
        this._currentToken.next(token);
    }

    /**
     * @method instant_token()
     * @description Return the current token
     */

    get instant_token(): string | null {
        return this._currentToken.value;
    }

    /**
     * @method instant_token()
     * @description Return the refresh token
     */

    get instant_refresh_token(): string | null {
        return this._refreshToken.value;
    }

    /**
     * @method getCurrentToken()
     * @description
     */

    getCurrentUser(): string | null {
        const token = this._storageService.getElement('currentUser');
        return token ? token : null;
    }

    /**
     * @method getCurrentToken$()
     * @description
     */

    getCurrentToken$(): Observable<string | null> {
        return this._currentToken.asObservable();
    }

    /**
     * @method setAccessControl()
     * @param (token: string)
     * @description
     */

    setAccessControl(token: string): void {
        this._storageService.removeElement('accessControl');
        this._storageService.saveElement('accessControl', token);
        this._refreshToken.next(token);
    }

    /**
     * @method getCurrentAccessControl()
     * @description
     */

    getCurrentAccessControl(): string | null {
        return this._storageService.getElement('accessControl', 'local');
    }

    /**
     * @method getCurrentAccessControl()
     * @description
     */

    getCurrentAccessControl$(): Observable<string | null> {
        return this._refreshToken.asObservable();
    }

    /**
     * @method getDecodedAccessToken()
     * @param (token?: any)
     * @description Return if the user was logged
     */

    getDecodedAccessToken(token?: any): any | null {
        if (token) {
            try {
                return jwt_decode(token);
            } catch (Error) {
                return null;
            }
        } else {
            try {
                return jwt_decode(this.getCurrentUser()!);
            } catch (Error) {
                return null;
            }
        }
    }

    /**
     * ! This method needs to be deprecated
     * @method isAuthenticated()
     * @description Return if the user was logged
     */

    isAuthenticated(): boolean {
        let token = this.getCurrentUser();
        return token !== null ? !this._jwtHelper.isTokenExpired(token) : false;
    }

    /**
     * ! This method needs to be deprecated
     * @method isAuthenticated$()
     * @description Return if the user was logged as observable
     */

    isAuthenticated$(): Observable<boolean> {
        return this.getCurrentToken$().pipe(
            map((token) => (token !== null ? !this._jwtHelper.isTokenExpired(token) : false))
        );
    }

    /**
     * @method setAuthorizationTokenId()
     * @param (authorizationTokenId: number | null)
     * @description Set authorization token id, use to transfer guest cart to authenticated customer
     */

    setAuthorizationTokenId(authorizationTokenId: number | null): void {
        this._authorizationTokenId.next(authorizationTokenId);
    }

    /**
     * @method getAuthorizationTokenId()
     * @description Get authorization token id, use to transfer guest cart to authenticated customer
     */

    getAuthorizationTokenId(): number | null {
        return this._authorizationTokenId.value;
    }

    /**
     * @method getProfileType$()
     * @description
     */

    getProfileType$(): Observable<string> {
        return this._profileType.asObservable();
    }

    /**
     * @method setProfileType()
     * @description
     */

    setProfileType(profile: string): void {
        this._storageService.removeElement('profileType');
        this._storageService.saveElement('profileType', profile);
        this._profileType.next(profile);
    }

    /**
     * @method profiletype$()
     * @description Return if the user was logged as observable
     */

    profiletype$(): Observable<string> {
        return this.getProfileType$().pipe(map((profile) => (profile ? profile : 'FORWARDER')));
    }

    isTokenValid(token: string): boolean {
        if (!token || token === '') {
            return false;
        }

        const parts = token.split('.');

        if (parts.length !== 3) {
            return false;
        }

        const decoded = this._jwtHelper.urlBase64Decode(parts[1]);

        if (!decoded) {
            return false;
        }

        return true;
    }
}
