import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForteException, PaymentMethod, PaymentMethodItem, PaymentMethods } from '@cargos/sprintpay-models';
import { ErrorForteHandlerService, PaymentMethodsRequestService } from '@cargos/sprintpay-services';
import { catchError, from, of, Subject, switchMap, take, takeUntil, throwError } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
    selector: 'app-echeck',
    templateUrl: './echeck.component.html',
})
export class EcheckComponent implements OnInit, OnDestroy {
    public eChecks: PaymentMethodItem[] = [];
    public noEchecks: boolean | undefined;
    public isLoading: boolean = false;
    public deletingEcheck: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private errorForteHandlerService: ErrorForteHandlerService,
        private paymentMethodsRequestService: PaymentMethodsRequestService,
        private paymentMethodsService: PaymentMethodsService
    ) {}

    ngOnInit(): void {
        this.subscribeToEchecks();
        this.subscribeIsEcheckLoading();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method subscribeToEchecks()
     * @description Subscribes to the user's credit cards and updates the component state accordingly
     */
    private subscribeToEchecks(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.ECHECK)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethod: PaymentMethod | undefined) => {
                    this.noEchecks = !paymentMethod?.items?.length;
                    this.eChecks = paymentMethod?.items || [];
                },
            });
    }

    /**
     * @method deleteECheck
     * @param {string} eCheckToken - The token of the eCheck to be deleted.
     * @description Prompts the user for confirmation before deleting the specified eCheck.
     * If confirmed, it calls the service to remove the eCheck and updates the payment methods list.
     */
    deleteECheck(eCheckToken: string | undefined | null): void {
        if (!eCheckToken) {
            return;
        }

        from(
            Swal.fire({
                text: 'You are about to delete an eCheck',
                title: 'Did you want to continue?',
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
            })
        )
            .pipe(
                switchMap((result: SweetAlertResult) => {
                    if (!result.isConfirmed) {
                        return of('Complete');
                    }

                    this.deletingEcheck = true;
                    return this.paymentMethodsRequestService.removeEcheck(eCheckToken).pipe(
                        take(1),
                        catchError((err: ForteException) => {
                            const error = this.errorForteHandlerService.handlerError(err);
                            Swal.fire({
                                title: error.title,
                                text: error.description,
                                icon: 'error',
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                allowOutsideClick: false,
                            });

                            return throwError(() => error);
                        }),
                        switchMap(() => {
                            return this.paymentMethodsService.getPaymentMethodsRequest$().pipe(take(1));
                        })
                    );
                })
            )
            .subscribe({
                complete: () => {
                    this.deletingEcheck = false;
                },
            });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    subscribeIsEcheckLoading(): void {
        this.paymentMethodsService
            .getPaymentMethodsInProcess$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isLoading) => {
                    this.isLoading = isLoading;
                },
            });
    }
}
