import { CommonModule } from '@angular/common';
import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { termsOfUseURL } from 'src/app/utils/constants';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-terms-of-use-link',
    templateUrl: './terms-of-use-link.component.html',
})
export class TermsOfUseLinkComponent {
    public readonly url: string | null;
    public termsOfUseURL: string | null = termsOfUseURL;

    constructor(private domSanitizer: DomSanitizer) {
        this.url = this.domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.domSanitizer.bypassSecurityTrustResourceUrl(termsOfUseURL)
        );
    }
}
