import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentMethod, PaymentMethodItem, PaymentMethods } from '@cargos/sprintpay-models';
import { from, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { LastFourDigitsComponent } from 'src/app/standalone-components/last-four-digits/last-four-digits.component';
import { LogoPaymentMethodComponent } from 'src/app/standalone-components/logo-payment-method/logo-payment-method.component';
import Swal from 'sweetalert2';
import { EcheckListtMaterialModule } from './echeck-material-modules.module';
import { EmptyEcheckistComponent } from './empty-echeck-list/empty-echeck-list.component';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        EcheckListtMaterialModule,
        LogoPaymentMethodComponent,
        LastFourDigitsComponent,
        EmptyEcheckistComponent,
    ],
    selector: 'app-echecks-list',
    templateUrl: './echeck.component.html',
    styleUrls: ['./echeck.component.scss'],
})
export class EcheckListComponent implements OnInit, OnDestroy {
    public echecks?: PaymentMethod;
    public token: string | null = null;
    public paymentMethodsEnum = PaymentMethods;
    private unsubscribe$: Subject<void>;

    @Input() aboveThreshold: boolean = false;
    @Input() loading: boolean;
    @Input() echeckDeletionAllowed: boolean = false;
    @Output() eventOnSelectEcheck: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private summaryService: SummaryService
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeEchecks();
        this.subscribeEcheckItemSelected();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeEchecks(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.ECHECK)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (echecks: PaymentMethod | undefined) => {
                    this.echecks = echecks;
                },
            });
    }

    subscribeEcheckItemSelected(): void {
        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethodSelected) => {
                    this.token = paymentMethodSelected?.token || null;
                },
            });
    }

    selectPaymentMethod(token?: string | null): void {
        if (token) {
            this.eventOnSelectEcheck.emit(token);
        }
    }

    removeEcheck(echeck: PaymentMethodItem): void {
        if (!echeck.paymentToken?.token) {
            return;
        }

        from(
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            })
        )
            .pipe(
                switchMap((result) => {
                    if (!result.isDenied) {
                        return of(null);
                    }
                    this.loading = true;

                    return this.confirmRemoveEcheck(echeck?.paymentToken?.token || '').pipe(
                        take(1),
                        map((confirmation) => confirmation)
                    );
                })
            )
            .subscribe({
                next: (deletionConfirmed) => {
                    this.loading = false;
                    if (deletionConfirmed) {
                        Swal.fire('Removed!', '', 'success');
                    }
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    confirmRemoveEcheck(token: string): Observable<string> {
        return this.paymentMethodsService.removeEcheck(token);
    }
}
