import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControlStatus,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { CartDescriptionException, CartExceptionType } from '@cargos/sprintpay-models';
import { Subject, take, takeUntil } from 'rxjs';
import { PipesSharedModule } from 'src/app/pipes/pipes.module';
import { TermsOfUseForm } from 'src/app/services/summary/models/types';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { InvalidFieldComponent } from 'src/app/standalone-components/field-error/field-error.component';
import { termsOfUseURL } from 'src/app/utils/constants';
import { TermsOfUseLinkComponent } from '../../terms-of-use-link/terms-of-use-link.component';
import { TermsOfUSeFormErrorJSON, TermsOfUseFormGroupType } from './models/terms-of-use-form';
import { TermsOfUseMaterialModule } from './terms-of-use-material-modules.module';

@Component({
    standalone: true,
    imports: [
        NgIf,
        InvalidFieldComponent,
        PipesSharedModule,
        ReactiveFormsModule,
        TermsOfUseMaterialModule,
        TermsOfUseLinkComponent,
    ],
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    public termsOfUseForm: TermsOfUseFormGroupType;
    public formError: TermsOfUSeFormErrorJSON;
    public termsOfUseURL: string;

    @Input() showError = false;

    get termsOfUse(): AbstractControl | null {
        return this.termsOfUseForm.get('termsOfUse');
    }

    constructor(
        private fb: NonNullableFormBuilder,
        private summaryService: SummaryService
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.getTermsOfUseUrl();
        this.createForm();
        this.subscribeFormChanges();
        this.subscribeTermsOfUse();
        this.setFormError();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createForm(): void {
        this.termsOfUseForm = this.fb.group({
            termsOfUse: ['', Validators.required],
        });
    }

    setFormError(): void {
        this.formError = {
            termsOfUse: {
                code: 'required',
                message: CartDescriptionException[CartExceptionType.TERMS_OF_USE_INVALID],
            },
        };
    }

    subscribeFormChanges(): void {
        this.termsOfUseForm.statusChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((statusChanges: FormControlStatus) => {
                this.summaryService.setTermsOfUseValid({
                    isValidForm: statusChanges === 'VALID',
                    termsOfUseFormGroup: this.termsOfUseForm,
                });
            });
    }

    subscribeTermsOfUse(): void {
        this.summaryService
            .getTermsOfUseValid$()
            .pipe(take(1))
            .subscribe((termsOfUse: TermsOfUseForm) => {
                if (termsOfUse?.isValidForm) {
                    this.termsOfUse?.patchValue({ termsOfUse: true });
                } else {
                    this.summaryService.setTermsOfUseValid({
                        isValidForm: false,
                        termsOfUseFormGroup: this.termsOfUseForm,
                    });
                }
            });
    }

    getTermsOfUseUrl(): void {
        this.termsOfUseURL = termsOfUseURL;
    }
}
