export type NotificationsType = {
    name: string;
    alert: boolean;
    type: TypeNotificationType;
    message: string;
    buttonText?: string;
};

export enum TypeNotificationType {
    banner = 'banner',
    alert = 'pop-up',
}
