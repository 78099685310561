import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BranchCredit } from 'src/app/models/credit/branch.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable()
export class CreditConfigurationApiService {
    _customerUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._customerUrl = environment.uris.method.customer;
    }

    getBranchesCreditInformation(): Observable<BranchCredit> {
        const url = `${this._customerUrl}/account/branches`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<BranchCredit>(url, { headers });
    }

    updateBranchCredit(branch: { branchId: string; creditLimit: string }): Observable<BranchCredit> {
        const url = `${this._customerUrl}/account/branches/${branch.branchId}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        const sanitizedString = branch.creditLimit.replace(/,/g, '');

        return this._httpClient.post<BranchCredit>(url, { creditLimit: Number(sanitizedString) }, { headers });
    }

    getTopBranches(): Observable<BranchCredit[]> {
        const url = `${this._customerUrl}/account/branches/top`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<BranchCredit[]>(url, { headers });
    }

    getTopPerformers(): Observable<BranchCredit[]> {
        const url = `${this._customerUrl}/account/branches/performers`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<BranchCredit[]>(url, { headers });
    }
}
