import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import { Customer } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { SelectProfile } from 'src/app/models/profiles/profiles.models';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ProfileTypes } from 'src/app/utils/profile-types';
import { PORTALS_ROUTES, PROFILES_PORTALS_OPTIONS } from 'src/app/utils/switch-config';

@Component({
    selector: 'app-portal-selector',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatDividerModule, MatFormFieldModule, MatSelectModule],
    templateUrl: './portal-selector.component.html',
})
export class PortalSelectorComponent implements OnInit, OnDestroy {
    @Input() userNameOnly?: boolean;

    public profileType = '';
    public userName = '';
    public userEmail = '';
    public userInitials = '';
    public profileTypesEnum = ProfileTypes;
    public selectedProfile!: SelectProfile | undefined;
    public profilesList!: SelectProfile[];
    private customer?: Customer | null;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private _router: Router,
        private _customerService: CustomerService
    ) {}

    ngOnInit(): void {
        this.loadCustomer();
        this.watchActiveProfile();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    get profileHasSelect(): boolean {
        return this.profilesList.length > 0;
    }

    get roleName(): string {
        return this._customerService.getRoleUser();
    }

    get hasMultiSelector(): boolean {
        return this.profilesList.length > 1;
    }

    private loadCustomer(): void {
        this._customerService
            .getCustomer$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((customer) => {
                this.customer = customer;
                this.userName = customer?.name || '';
                this.userEmail = customer?.email || '';
                this.customer = customer;
                this.userInitials = customer?.isGuest
                    ? 'GU'
                    : `${customer?.firstName?.charAt(0) || ''}${customer?.lastName?.charAt(0) || ''}`;
            });

        this._customerService
            .getProfileType$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profileType) => {
                this.profileType = profileType;
                this.loadProfilePortals(this.profileType);
            });
    }

    loadProfilePortals(profileType: string): void {
        const routes = new Set(PROFILES_PORTALS_OPTIONS.get(profileType));
        if (this.customer?.adminPortalEnabled) routes.add(PORTALS_ROUTES[2]);
        this.profilesList = (Array.from(routes) as SelectProfile[]) ?? [];
        this.autoSelectProfile();
    }

    autoSelectProfile(): void {
        this.selectedProfile = this.profilesList.find(
            (profile) => this._router.url.split('/')[1] === profile.route.split('/')[0]
        );
        this._customerService.setActiveProfileView(this.selectedProfile?.activeProfileView ?? ProfileTypes.FORWARDER);
    }

    watchActiveProfile(): void {
        this._router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.autoSelectProfile();
            }
        });
    }

    onProfileChange(profile: SelectProfile): void {
        this._customerService.setActiveProfileView(profile.activeProfileView);
        this._router.navigate([profile.route]);
    }
}
