<section id="paymentMethodsList">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedPanel" hideToggle disabled="true" class="mat-elevation-z0">
            <mat-expansion-panel-header class="p-0">
                <div class="d-flex flex-column flex-grow-1">
                    <ng-content></ng-content>
                    @if (isAuthenticated) {
                    <div class="d-flex flex-xs-column flex-row flex-grow-1 align-items-center mb-32">
                        <ng-container *ngIf="!isSelectingPaymentMethod; else loader">
                            <ng-container *ngIf="!isThresholdAndPaymentMethodsUnAvailable">
                                <div class="d-flex flex-grow-1 w-100">
                                    <app-authenticated-payment-method [aboveThreshold]="aboveThreshold">
                                    </app-authenticated-payment-method>
                                </div>
                                <!-- START: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                                <button
                                    mat-button
                                    type="submit"
                                    class="mat-caption btn__sprintpay btn__sprintpay--link payment-detail--methodChange align-items-center d-flex"
                                    (click)="togglePanel()"
                                >
                                    <mat-icon matIconPrefix *ngIf="expandedPanel" class="close-icon">close</mat-icon>
                                    {{ expandedPanel ? 'Close' : paymentMethodSelected?.method ? 'Change' : 'Select
                                    payment method'}}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="isThresholdAndPaymentMethodsUnAvailable">
                                <app-guest-credit-card
                                    [aboveThreshold]="aboveThreshold"
                                    (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                                ></app-guest-credit-card>
                            </ng-container>
                            <!-- END: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                        </ng-container>

                        <!--START: LOADER -->
                        <ng-template #loader>
                            <div class="d-flex justify-content-center flex-grow-1">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </ng-template>
                        <!--END: LOADER -->
                    </div>
                    } @if (this.isThereInvoicesInCart && paymentMethodSelected?.method === PaymentMethods.CARGO_CREDIT)
                    {
                    <p class="error-Message">
                        <b>*SprintPay Net Terms</b> is unavailable when you are paying open invoices.
                        <br />
                        Choose an alternate payment method to continue.
                    </p>
                    } @if(isEnoughCredit === false && paymentMethodSelected?.method === PaymentMethods.CARGO_CREDIT){
                    <div class="d-flex error-Message">
                        <span class="material-symbols-outlined me-1">exclamation</span>You do not have enough credit to
                        make your payment.
                    </div>
                    }
                </div>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let method of paymentMethodsList">
                @if (method.name === PaymentMethods.CREDIT_CARD) {
                <div class="methods-container">
                    <ng-container *ngIf="isAuthenticated; else creditCardGuest">
                        <app-credit-cards-list
                            [aboveThreshold]="aboveThreshold"
                            (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                        ></app-credit-cards-list>
                        <div class="d-flex">
                            <button
                                mat-button
                                type="submit"
                                class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green"
                                (click)="redirectToCreditCard()"
                            >
                                <mat-icon matIconPrefix>add</mat-icon>
                                ADD NEW CREDIT CARD
                            </button>
                        </div>
                        <mat-divider class="my-2 mx-0" [inset]="true"></mat-divider>
                    </ng-container>
                    <ng-template #creditCardGuest>
                        <app-guest-credit-card
                            (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                        ></app-guest-credit-card>
                    </ng-template>
                </div>
                } @if (method.name === PaymentMethods.ECHECK) {
                <div class="methods-container">
                    <app-echecks-list
                        [aboveThreshold]="aboveThreshold"
                        (eventOnSelectEcheck)="selectPaymentMethod(PaymentMethods.ECHECK, $event)"
                    ></app-echecks-list>
                    <div class="d-flex mb-4">
                        <button
                            mat-button
                            type="submit"
                            class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green"
                            (click)="redirectToEcheck()"
                        >
                            <mat-icon matIconPrefix>add</mat-icon>
                            ADD NEW ECHECK
                        </button>
                    </div>
                    <mat-divider class="my-2 mx-0" [inset]="true"></mat-divider>
                </div>
                } @if (method.name === PaymentMethods.CARGO_CREDIT && aboveThreshold === false) {
                <div class="methods-container">
                    <app-cargo-credit
                        [aboveThreshold]="aboveThreshold"
                        (eventOnSelectCargoCredit)="selectPaymentMethod(PaymentMethods.CARGO_CREDIT)"
                    ></app-cargo-credit>
                    <mat-divider class="my-2 mx-0" [inset]="true"></mat-divider>
                </div>
                } @if (method.name === PaymentMethods.PAYPAL && aboveThreshold === false &&
                isAboveThresholdPaymentRequest === false) {

                <div class="methods-container">
                    <app-paypal
                        [aboveThreshold]="aboveThreshold"
                        (eventOnSelectPaypal)="selectPaymentMethod(PaymentMethods.PAYPAL)"
                    ></app-paypal>
                </div>
                }
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</section>
