import { Injectable } from '@angular/core';
import { Customer, PaymentMethod, PaymentMethods } from '@cargos/sprintpay-models';
import { Observable, combineLatest, distinctUntilChanged, map, of, switchMap, take } from 'rxjs';
import { NotificationsType } from '../../models/notifications/notifications';
import { PaymentMethodSelected } from '../../utils/cart-types';
import { profileComponents } from '../../utils/constants';
import { PaymentMethodsService } from '../payment-methods.service';
import { CustomerAPIService } from '../requests/customer-api.service';
import { PaymentMethodGuest } from '../summary/models/types';
import { SummaryService } from '../summary/summary.service';
import { CartBillService } from '../utils/cart/cart-service';
import { CustomerService } from '../utils/customer-handler.service';
import { SecurityService } from '../utils/security.service';
import { UserSessionService } from '../utils/user-session.service';
import { CargoCreditValidations } from './models/types';

@Injectable({
    providedIn: 'root',
})
export class CustomerFeaturesService {
    private paymentMethodsAboveThreshold: string[] = [PaymentMethods.CREDIT_CARD, PaymentMethods.ECHECK];
    private paymentMethodsProhibitedForBelowThresholdTicketWhenCartAboveThreshold: PaymentMethods[] = [
        PaymentMethods.PAYPAL,
    ];
    constructor(
        private customerAPIService: CustomerAPIService,
        private customerService: CustomerService,
        private securityService: SecurityService,
        private cartBillService: CartBillService,
        private summaryService: SummaryService,
        private paymentMethodsService: PaymentMethodsService,
        private userSessionService: UserSessionService
    ) {}

    getCustomerNotifications(): Observable<NotificationsType[]> {
        return of(this.customerService.isAllowedDisplayNotifications()).pipe(
            switchMap((isAllowed) => {
                if (!isAllowed) {
                    return of([]);
                }

                return this.customerAPIService.getCustomerNotificationsRequest();
            })
        );
    }

    isCustomerOnHold(): boolean {
        const customer: Customer = this.customerService.getCustomer();

        return !!(
            this.securityService.verifyComponentsSecurity(profileComponents.homeCreditHold) || customer.isCreditHold
        );
    }

    isInvoiceCheckLimitValid$(): Observable<boolean> {
        const uniqueRequestor = this.customerService.isUniqueRequestor();
        const invoiceCheckLimit = this.customerService.getInvoiceCheckLimit();

        return this.cartBillService.isTherePaymentsRequestsNoInvoices$().pipe(
            map((isTherePaymentsRequestsNoInvoices) => {
                const subtotal = this.cartBillService.getSubtotal();

                if (!invoiceCheckLimit || !isTherePaymentsRequestsNoInvoices || uniqueRequestor) {
                    return true;
                }

                return subtotal < invoiceCheckLimit;
            })
        );
    }

    isAllowedToPayWithCargoCredit$(): Observable<CargoCreditValidations> {
        if (!this.paymentMethodsService.isPaymentMethodAvailable(PaymentMethods.CARGO_CREDIT)) {
            return of({
                isAllowed: false,
            });
        }

        return this.cartBillService.isThereInvoicesInCart$().pipe(
            switchMap((isThereInvoices) => {
                if (isThereInvoices) {
                    return of({
                        isAllowed: false,
                        isThereInvoicesInCart: true,
                    });
                }

                return this.isEnoughCredit$().pipe(
                    map((isEnoughCredit) => {
                        return {
                            isAllowed: isEnoughCredit,
                            isThereInvoicesInCart: false,
                            isEnoughCredit,
                        };
                    })
                );
            })
        );
    }

    isEnoughCredit$(): Observable<boolean> {
        return this.paymentMethodsService.getCSCreditAvailable$().pipe(
            switchMap((csCreditAvailable) => {
                return this.cartBillService
                    .getSubTotal$()
                    .pipe(map((subTotal: number) => csCreditAvailable > subTotal));
            }),
            distinctUntilChanged()
        );
    }
    isPaymentMethodsSelectedOnCartAboveValid(paymentMethod: PaymentMethodSelected): boolean {
        const paymentMethodName = paymentMethod.method;
        const exist =
            !this.paymentMethodsProhibitedForBelowThresholdTicketWhenCartAboveThreshold.includes(paymentMethodName);
        return exist;
    }

    isPaymentMethodSelectedValid$(aboveThreshold?: boolean): Observable<boolean> {
        return this.summaryService.getPaymentMethodSelected$(aboveThreshold).pipe(
            switchMap((paymentMethodSelected: PaymentMethodSelected | null) => {
                return this.userSessionService.isAuthenticated$().pipe(
                    take(1),
                    switchMap((isAuthenticated) => {
                        if (!isAuthenticated || (isAuthenticated && this.isThresholdAndPaymentMethodsUnAvailable())) {
                            return this.isPaymentMethodGuestValid$(paymentMethodSelected);
                        }

                        return this.isPaymentMethodAuthenticatedValid$(paymentMethodSelected, aboveThreshold);
                    })
                );
            }),
            distinctUntilChanged()
        );
    }

    private isPaymentMethodGuestValid$(paymentMethodSelected: PaymentMethodSelected | null): Observable<boolean> {
        return this.summaryService.getGuestPaymentInformation$().pipe(
            map((guestPayment: PaymentMethodGuest | null) => {
                if (!paymentMethodSelected?.method) {
                    return false;
                }

                if (paymentMethodSelected?.method === PaymentMethods.CREDIT_CARD && guestPayment?.creditCard?.nonce) {
                    return true;
                }

                return true;
            }),
            distinctUntilChanged()
        );
    }

    private isPaymentMethodAuthenticatedValid$(
        paymentMethodSelected: PaymentMethodSelected | null,
        aboveThreshold?: boolean
    ): Observable<boolean> {
        return of(paymentMethodSelected).pipe(
            switchMap((paymentMethodSelected: PaymentMethodSelected | null) => {
                if (!paymentMethodSelected?.method) {
                    return of(false);
                }

                if (aboveThreshold && !this.isValidPaymentMethodForPaymentsAboveThreshold(paymentMethodSelected)) {
                    return of(false);
                }

                if (paymentMethodSelected.method === PaymentMethods.CARGO_CREDIT) {
                    return this.isAllowedToPayWithCargoCredit$().pipe(
                        map((isAllowedToPayWithCargoCredit) => isAllowedToPayWithCargoCredit.isAllowed)
                    );
                }

                if (
                    paymentMethodSelected.method === PaymentMethods.CREDIT_CARD ||
                    paymentMethodSelected.method === PaymentMethods.ECHECK
                ) {
                    return of(!!paymentMethodSelected.token);
                }

                return of(true);
            }),
            distinctUntilChanged()
        );
    }

    private isValidPaymentMethodForPaymentsAboveThreshold(paymentMethodSelected: PaymentMethodSelected): boolean {
        return !!this.paymentMethodsAboveThreshold.find(
            (paymentMethodAboveThreshold) => paymentMethodAboveThreshold === paymentMethodSelected.method
        );
    }

    private arePaymentMethodsSelectedValid$(): Observable<boolean> {
        return combineLatest([
            this.isPaymentMethodSelectedValid$(false),
            this.isPaymentMethodSelectedValid$(true),
        ]).pipe(
            map(([paymentMethodSelected, paymentMethodSelectedAboveThreshold]) => {
                return paymentMethodSelected && paymentMethodSelectedAboveThreshold;
            }),
            distinctUntilChanged()
        );
    }

    isPaymentMethodValid$(): Observable<boolean> {
        return this.cartBillService.isCartAboveThreshold$().pipe(
            switchMap((isCartAboveThreshold) => {
                if (isCartAboveThreshold && this.cartBillService.getCartPaymentRequests(false).length) {
                    return this.arePaymentMethodsSelectedValid$().pipe(
                        map((isPaymentMethodValid) => isPaymentMethodValid)
                    );
                }

                if (isCartAboveThreshold) {
                    return this.isPaymentMethodSelectedValid$(true);
                }

                return this.isPaymentMethodSelectedValid$(false);
            }),
            distinctUntilChanged()
        );
    }

    getPaymentMethodsAboveThreshold(): string[] {
        return this.paymentMethodsAboveThreshold;
    }
    isPaymentMethodsUnAvailable$(isCartAboveThreshold: boolean): Observable<boolean> {
        if (isCartAboveThreshold) {
            return this.paymentMethodsService.getPaymentMethods$().pipe(
                map((paymentMethods: PaymentMethod[]) => {
                    const isCargoCreditAvailable = this.paymentMethodsService.isPaymentMethodAvailable(
                        PaymentMethods.CARGO_CREDIT
                    );
                    const isPayPalAvailable = this.paymentMethodsService.isPaymentMethodAvailable(
                        PaymentMethods.PAYPAL
                    );
                    if (paymentMethods.length === 1 && (isCargoCreditAvailable || isPayPalAvailable)) {
                        return true;
                    }

                    if (paymentMethods.length === 2 && isCargoCreditAvailable && isPayPalAvailable) {
                        return true;
                    }
                    return false;
                })
            );
        }
        return of(false);
    }

    isThresholdAndPaymentMethodsUnAvailable$(): Observable<boolean> {
        return this.cartBillService.isCartAboveThreshold$().pipe(
            switchMap((isCartAboveThreshold) => {
                return this.isPaymentMethodsUnAvailable$(isCartAboveThreshold);
            })
        );
    }

    isThresholdAndPaymentMethodsUnAvailable(): boolean {
        const isCartAboveThreshold = this.cartBillService.isCartAboveThreshold();
        if (isCartAboveThreshold) {
            const paymentMethods = this.paymentMethodsService.instant_payment_methods;

            const isCargoCreditAvailable = this.paymentMethodsService.isPaymentMethodAvailable(
                PaymentMethods.CARGO_CREDIT
            );
            const isPayPalAvailable = this.paymentMethodsService.isPaymentMethodAvailable(PaymentMethods.PAYPAL);
            if (paymentMethods.length === 1 && (isCargoCreditAvailable || isPayPalAvailable)) {
                return true;
            }

            if (paymentMethods.length === 2 && isCargoCreditAvailable && isPayPalAvailable) {
                return true;
            }
            return false;
        }
        return false;
    }
}
