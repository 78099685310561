import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PaymentMethods } from '@cargos/sprintpay-models';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';

@Component({
    selector: 'app-snack-bar-payment-method-add',
    templateUrl: './snack-bar-payment-method-add.component.html',
    styleUrl: './snack-bar-payment-method-add.component.scss',
})
export class SnackBarPaymentMethodAddComponent implements OnInit {
    public paymentMethod: PaymentMethodSelected | null;
    public method: string;
    public lastFourDigits: string;

    @Output() eventOnClose: EventEmitter<boolean> = new EventEmitter();

    constructor(private summaryService: SummaryService) {}

    ngOnInit(): void {
        this.subscribePaymentMethod();
    }

    subscribePaymentMethod(): void {
        this.paymentMethod = this.summaryService.instant_payment_method;
        this.method = this.paymentMethod?.method === PaymentMethods.CREDIT_CARD ? 'Credit card' : 'Echeck';
        this.lastFourDigits = this.paymentMethod?.paymentAccount?.accountingDetails?.lastFourDigits;
    }

    close(): void {
        this.eventOnClose.emit(true);
    }
}
