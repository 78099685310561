<section class="terms-of-use">
    <form [formGroup]="termsOfUseForm">
        <mat-checkbox formControlName="termsOfUse" class="mat-small mat-body-2" required color="primary">
            <span class="term-title"> Please accept our </span>
            <app-terms-of-use-link></app-terms-of-use-link>
        </mat-checkbox>
        <ng-container *ngIf="showError">
            <mat-error>
                <app-field-error [field]="'termsOfUse'" [messageList]="formError['termsOfUse']"> </app-field-error>
            </mat-error>
        </ng-container>
    </form>
</section>
