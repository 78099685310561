import { ComponentType } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { cargosprintPhoneNumber } from 'src/app/utils/constants';
import { ResponseMFA, TypeModuleMFA } from '../verification-code/models/two-factor-models';

export interface VerificationMethodType {
    type: string;
    value: string;
}
@Component({
    selector: 'app-authentication-flux',
    templateUrl: './authentication-flux.component.html',
    styleUrls: ['./authentication-flux.component.scss'],
})
export class AuthenticationFluxComponent implements OnDestroy {
    @ViewChild('twoFactorModal') twoFactorModal?: ComponentType<unknown>;
    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<unknown>;

    @Input() module: TypeModuleMFA = 'GUEST_PAYMENT';
    @Input() messageAuthentication =
        'To continue with your payment, please complete the next verification. We want to make sure your transaction is secure and convenient.';

    public email = '';
    public phoneNumber = '';
    public activationUuid = '';
    public verificationMethod!: VerificationMethodType;
    constructor(
        private _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        private data: {
            email?: string;
            phone?: string;
            activationUuid?: string;
            accountVerifiedHandler?: (event: ResponseMFA) => void;
        }
    ) {
        this.email = data?.email || '';
        this.phoneNumber = data?.phone || '';
        this.activationUuid = data?.activationUuid || '';
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
    }

    onSetVerificationMethod(method: VerificationMethodType): void {
        this.verificationMethod = method;
    }

    openRequestedCode(): void {
        this._matDialog.closeAll();
        setTimeout(() => {
            if (this.verificationMethod) {
                this._matDialog.open(this.twoFactorModal as ComponentType<unknown>, {
                    id: 'two-factor',
                    disableClose: false,
                    data: {
                        mfaType: this.verificationMethod.type,
                        contact: this.verificationMethod.value,
                        activationUuid: this.activationUuid,
                    },
                });
            }
        }, 200);
    }

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    modalAccountVerified(event: ResponseMFA): void {
        if (this.data.accountVerifiedHandler) {
            this.data.accountVerifiedHandler(event);
        }
    }

    get cargosprintPhoneNumber(): string {
        return cargosprintPhoneNumber;
    }
}
