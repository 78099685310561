import { ComponentType } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartBill, PayloadPayPal, PaymentMethods, PaymentMethodsEvents } from '@cargos/sprintpay-models';
import { getCards } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/getCreditCards';
import { getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription, map, of, take, takeUntil } from 'rxjs';
import { Card, Echeck } from 'src/app/models/payments/payment-methods';
import { AuthenticationFluxService } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.service';
import { PaymentMethodsService } from 'src/app/services/payment-methods.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { Balance, CartPayRequest, PaymentMethodSelected } from 'src/app/utils/cart-types';
import { profileComponents } from 'src/app/utils/constants';
import { PromoCodeByAWBService } from '../../../../services/promo-code.service';

interface EventPaymentMethod {
    item: Card | Echeck | undefined;
    method: number | null;
}

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, OnDestroy {
    public loadingPayPalRequest: boolean = false;
    public selectedPayment: PaymentMethodSelected;
    public paymentMethod!: PaymentMethodSelected;
    public accountingDetails: any;
    public subTotal: number;
    public cartBill: CartBill;
    public sprintPayCreditAmount: Balance;
    public spVsTotalAmount: number;
    public spVsTotal: boolean;
    public invoiceLimit: boolean;
    public invoicesInCart: boolean;
    public paymentConfirmation: boolean = false;
    public readonly isGuest: boolean;
    public availablePaymentMethodsCount: number;
    public isSelectingPaymentMethod: boolean;
    public hasNonReservationFee: number = 0;
    public hasAGIPayments?: boolean;
    public nonReservationFee: number = 0;
    public readonly paymentMethods = PaymentMethods;
    private _paymentRequest: any;
    private _openInvoices: any;
    private _unsubscribe$: Subject<void>;
    private _subscription: Subscription;
    private readonly _uniqueRequestor: boolean;
    private readonly _invoiceCheckLimit: any;
    private _guestInformation: any;
    private _paypalButtonActions?: { disable?: () => void; enable?: () => void };

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<unknown>;
    @Output() payCart: EventEmitter<any> = new EventEmitter();
    @Output() payPaypal: EventEmitter<CartPayRequest> = new EventEmitter();

    constructor(
        private _sessionService: SessionService,
        private _customerService: CustomerService,
        private _matDialog: MatDialog,
        private _ngxSpinnerService: NgxSpinnerService,
        private _router: Router,
        private _securityService: SecurityService,
        private _paymentMethodsService: PaymentMethodsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authenticationService: AuthenticationFluxService,
        private _promoCodeForAWB: PromoCodeByAWBService,
        private promoCodeByAWBService: PromoCodeByAWBService,
        private summaryService: SummaryService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.subTotal = 0;
        this.sprintPayCreditAmount = {};
        this.spVsTotalAmount = 0;
        this.spVsTotal = false;
        this.invoiceLimit = false;
        this.invoicesInCart = false;
        this._invoiceCheckLimit = this._customerService.getCustomer().invoiceCheckLimit || null;
        this._uniqueRequestor = !!this._customerService.getCustomer().userType.includes('UNIQUE_REQUESTOR');
        this.availablePaymentMethodsCount = 0;
        this._subscription = Subscription.EMPTY;
        this.isSelectingPaymentMethod = false;
        this.isGuest = this._customerService.getCustomer().isGuest;
        this.getGuestCustomerInformation();
    }

    ngOnInit(): void {
        this._subscribeGetCurrentCart();
        if (!this.isGuest) {
            this._subscribeGetDefaultPaymentMethod();
        }
        this._subscribeGetAvailablePaymentMethods();
        this.getEventsPaymentMethods();
        this._subscription = this._authenticationService.accountCreated.subscribe((verified: boolean) => {
            if (verified) {
                this.payCart.emit({ cartBill: this.cartBill, paymentMethod: this.paymentMethod });
            }
        });
        this._subscription = this._authenticationService.changeVerificationMethod.subscribe((changed: boolean) => {
            if (changed) {
                this.openFactorAuthentication(this._guestInformation);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        this._matDialog.closeAll();
        this._changeDetectorRef.detach();
    }

    /**
     * @method isChangePaymentMethodButtonAvailable()
     * @description Getter to show/hide change payment method button
     */

    get isChangePaymentMethodButtonAvailable(): boolean {
        return (
            this.availablePaymentMethodsCount > 1 ||
            (this.paymentMethod &&
                [PaymentMethods.CREDIT_CARD, PaymentMethods.ECHECK].includes(this.paymentMethod.method))
        );
    }

    /**
     * @method _subscribeGetCurrentCart()
     * @description
     */

    private _subscribeGetCurrentCart(): void {
        // this.summaryService
        //     .getCartBill$()
        //     .pipe(takeUntil(this._unsubscribe$))
        //     .subscribe({
        //         next: (cart) => {
        //             if (this.summaryService.instant_payment_method && cart) {
        //                 this.setConfigIfPaymentMethodSelected(this.summaryService.instant_payment_method, cart);
        //                 return null;
        //             }
        //             return this.summaryService.instant_current_cart
        //                 ? this._getCart(this.summaryService.instant_current_cart)
        //                 : null;
        //         },
        //     });
    }

    getEventsPaymentMethods(): void {
        // this._paymentMethodsService
        //     .onEventPaymentMethod$()
        //     .pipe(
        //         switchMap((paymentMethodAction: PaymentMethodActions | null) => {
        //             if (paymentMethodAction?.setAsDefault) {
        //                 return this.getLastPaymentMethod(paymentMethodAction.event);
        //             }
        //             return of(null);
        //         }),
        //         takeUntil(this._unsubscribe$)
        //     )
        //     .subscribe((lastPaymentMethod: EventPaymentMethod | null) => {
        //         if (lastPaymentMethod && lastPaymentMethod?.item && lastPaymentMethod?.method) {
        //             this.summaryService.showPayMethod({
        //                 paymentAccount: lastPaymentMethod.item,
        //                 method: lastPaymentMethod.method,
        //             });
        //             if (this.summaryService.instant_payment_method && this.cartBill) {
        //                 this.setConfigIfPaymentMethodSelected(this.summaryService.instant_payment_method, this.cartBill);
        //             }
        //         }
        //     });
    }

    getLastPaymentMethod(event: PaymentMethodsEvents): Observable<EventPaymentMethod> {
        if (event === PaymentMethodsEvents.creditCardAdded) {
            return this.getCreditCard().pipe(
                map((cards: any[]) => {
                    const method = PaymentMethods.CREDIT_CARD;
                    const item: Card = cards[0];
                    return { item, method };
                })
            );
        }
        if (event === PaymentMethodsEvents.echeckAdded) {
            return this.getEcheck().pipe(
                map((echeks: any[]) => {
                    const method = PaymentMethods.ECHECK;
                    const lengthEcheck = echeks.length;
                    const item: Echeck = echeks[lengthEcheck - 1];
                    return { item, method };
                })
            );
        }

        return of({ item: undefined, method: null });
    }

    getCreditCard(): any {
        return getCards();
    }

    getEcheck(): any {
        return getEChecks();
    }

    /**
     * @method _subscribeGetDefaultPaymentMethod()
     * @description Preselect payment method
     */

    private _subscribeGetDefaultPaymentMethod(): void {
        this.isSelectingPaymentMethod = true;
        // this._cartPaymentMethodsService
        //     .getPaymentMethodByDefault()
        //     .pipe(
        //         take(1),
        //         switchMap((selectedPayment: PaymentMethodSelected | null) => {
        //             if (!selectedPayment) {
        //                 return of(null);
        //             }

        //             this.summaryService.showPayMethod({
        //                 paymentAccount: selectedPayment.paymentAccount,
        //                 method: selectedPayment.method,
        //             });

        //             return this.getCartBill(selectedPayment.method).pipe(
        //                 take(1),
        //                 map((cart) => {
        //                     return { cart, selectedPayment };
        //                 })
        //             );
        //         }),
        //         finalize(() => {
        //             this.isSelectingPaymentMethod = false;
        //         })
        //     )
        //     .subscribe({
        //         next: (response) => {
        //             if (response && response.cart) {
        //                 this.setConfigIfPaymentMethodSelected(response.selectedPayment, response.cart);
        //             }
        //         },
        //     });
    }

    onPaymentMethodChange(selectedPayment: PaymentMethodSelected): void {
        this.selectedPayment = selectedPayment;
        this.summaryService.setPaymentMethodSelected({
            paymentAccount: selectedPayment.paymentAccount,
            method: selectedPayment.method,
        });
        // this.getCartBill(selectedPayment.method)
        //     .pipe(take(1))
        //     .subscribe({
        //         next: (cart) => {
        //             if (cart) {
        //                 this.loadingPayPalRequest = true;
        //                 this.setConfigIfPaymentMethodSelected(selectedPayment, cart);
        //             }
        //         },
        //     });
    }

    /**
     * @method _subscribeGetAvailablePaymentMethods()
     * @description Get payment methods count
     */

    private _subscribeGetAvailablePaymentMethods(): void {
        // this._cartPaymentMethodsService
        //     .getAvailablePaymentMethods()
        //     .pipe(
        //         map((paymentMethods) => {
        //             this.availablePaymentMethodsCount = paymentMethods.length;
        //         }),
        //         takeUntil(this._unsubscribe$)
        //     )
        //     .subscribe();
    }

    /**
     * @method _getCart()
     * @param (cart: any)
     * @description
     */

    private _getCart(cart: any): void {
        let openInvoicesSubTotal: number = 0;
        let paymentRequestSubTotal: number = 0;
        this.subTotal = 0;
        this._ngxSpinnerService.show();
        this.cartBill = cart;
        this._paymentRequest = cart
            .filter((item: any) => !item.originalInvoice)
            .map((e: any): { e: any } => {
                this.subTotal = this.subTotal + e.amount;
                paymentRequestSubTotal = this.subTotal;
                return { e };
            });
        this._openInvoices = cart
            .filter((item: any) => item.originalInvoice)
            .map((e: any): { e: any } => {
                this.subTotal = this.subTotal + e.amount;
                openInvoicesSubTotal = this.subTotal;
                return { e };
            });
        // this._sessionService.setPayCount(cart.length);
        // this._sessionService.setPaymentRequest(this._paymentRequest.length);
        this.invoiceLimit =
            !this._uniqueRequestor &&
            this._openInvoices.length == 0 &&
            this._invoiceCheckLimit &&
            this._invoiceCheckLimit < openInvoicesSubTotal + paymentRequestSubTotal;

        this._ngxSpinnerService.hide();
    }

    /**
     * @method openDialog()
     * @param (templateRef: any)
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'paymentMethodsCart',
            width: '100%',
            minWidth: '80%',
            maxWidth: '90%',
            panelClass: 'payment-methods-dialog',
        });
    }

    /**
     * @method pay()
     * @description
     */

    pay(): void {
        if (this.isGuest) {
            this.openFactorAuthentication(this._guestInformation);
        } else {
            this.payCart.emit({ cartBill: this.cartBill, paymentMethod: this.paymentMethod });
        }
    }

    /**
     * @method paymentMethodSelected()
     * @param (selectedPayment: PaymentMethodSelected)
     * @description
     */

    setConfigIfPaymentMethodSelected(selectedPayment: PaymentMethodSelected, result: CartBill): void {
        this.spVsTotal = false;
        this.paymentMethod = selectedPayment;
        this.accountingDetails = selectedPayment.paymentAccount;
        if (selectedPayment.method === PaymentMethods.CARGO_CREDIT) {
            this.sprintPayCreditAmount.availableCredit = selectedPayment?.paymentAccount || 0;
        }
        this.cartBill = result;
        this.getPromocodesApplied(result);
        this.subTotal = result.subTotal;
        this.hasNonReservationFee = result.nonReservationFee ?? 0;
        this.invoicesInCart = !!(result?.cart && !!result?.cart?.[0]?.originalInvoice);
        if (selectedPayment.method === PaymentMethods.PAYPAL) {
            this._connectWithPaypal();
        } else {
            this.loadingPayPalRequest = false;
        }
        if (selectedPayment.method === PaymentMethods.CARGO_CREDIT) {
            this.spVsTotal = this.sprintPayCreditAmount?.availableCredit
                ? this.cartBill?.total <= this.sprintPayCreditAmount?.availableCredit
                : false;
            this.spVsTotalAmount =
                this.sprintPayCreditAmount?.availableCredit && this.sprintPayCreditAmount?.availableCredit > 0
                    ? this.sprintPayCreditAmount?.availableCredit - this.cartBill.total
                    : -1;
        } else if (
            selectedPayment.method === PaymentMethods.CREDIT_CARD ||
            selectedPayment.method === PaymentMethods.ECHECK
        ) {
            this.spVsTotal = true;
        }
        this._changeDetectorRef.detectChanges();
    }

    /**
     * @method _connectWithPaypal()
     * @description
     */

    private _connectWithPaypal(): void {
        if (this.cartBill && this.subTotal) {
            this.loadingPayPalRequest = true;
            // this._cartPaymentMethodsService
            //     .connectWithPaypal()
            //     .pipe(take(1))
            //     .subscribe({
            //         next: (paypalCheckoutInstance) => {
            //             window.paypal
            //                 .Buttons({
            //                     fundingSource: PaymentMethods.PAYPAL,
            //                     onInit: (_, actions) => {
            //                         this.loadingPayPalRequest = false;
            //                         if (this.isGuest) {
            //                             this._paypalButtonActions = actions;
            //                             if (!this.paymentConfirmation) {
            //                                 this._paypalButtonActions?.disable?.();
            //                             }
            //                         }
            //                     },
            //                     createOrder: () =>
            //                         paypalCheckoutInstance.createPayment({
            //                             flow: FlowType.Checkout,
            //                             amount: this.cartBill.total,
            //                             currency: Currency.USD,
            //                             intent: Intent.Capture,
            //                             enableShippingAddress: true,
            //                         }),
            //                     onApprove: (data) =>
            //                         paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
            //                             this.onPaymentMethodChange(this.paymentMethod);
            //                             this._payByPaypal(payload);
            //                             return payload;
            //                         }),
            //                     onCancel: () => {
            //                         this.loadingPayPalRequest = false;
            //                     },
            //                 })
            //                 .render('#paypal-button');
            //         },
            //         error: (error: Exception | unknown) => {
            //             this.loadingPayPalRequest = false;
            //             Swal.fire({
            //                 title: error instanceof Exception ? error.title : 'Oops...',
            //                 text: error instanceof Exception ? error.description : 'Something went wrong',
            //                 icon: 'error',
            //                 showConfirmButton: true,
            //                 confirmButtonText: 'Close',
            //                 allowOutsideClick: false,
            //             });
            //         },
            //     });
        }
    }

    /**
     * @method _payByPaypal()
     * @param (response: PayloadPayPal)
     * @description
     */

    private _payByPaypal(response: PayloadPayPal): void {
        if (response) {
            // this.payPaypal.emit({
            //     paymentMethod: { method: PaymentMethods.PAYPAL },
            //     cartBill: this.cartBill,
            //     payload: response,
            // });
        }
    }

    getPromocodesApplied(cartBill: CartBill): void {
        this._promoCodeForAWB
            .getAllPromoCodeApplied$()
            .pipe(take(1))
            .subscribe({
                next: (arrayPromoCode) => {
                    if (cartBill && cartBill.nonReservationFee) {
                        const promoCodeFees = arrayPromoCode.length * 8;
                        this.nonReservationFee = cartBill.nonReservationFee - promoCodeFees;
                    }
                },
            });
    }

    /**
     * @method addNewPaymentMethod()
     * @description verify if the profile components contains the payment methods section
     */

    addNewPaymentMethod(): void {
        const hasPaymentMethods: boolean = this._securityService.verifyComponentsSecurity(
            profileComponents.paymentMethod
        );
        this._router.navigate([hasPaymentMethods ? '/admin/facilityPayments/paymentMethods' : '/admin/home']);
    }

    /**
     * @method openFactorAuthentication()
     * @description verify if the profile components contains the payment methods section
     */

    openFactorAuthentication(information: any): void {
        this._matDialog.closeAll();
        setTimeout(() => {
            this._matDialog.open(this.authenticationFlux as ComponentType<unknown>, {
                id: 'authenticationFlux',
                disableClose: true,
                width: '55em',
                data: { email: information?.email },
            });
        }, 200);
    }

    /**
     * @method getGuestCustomerInformation()
     * @description
     */

    getGuestCustomerInformation(): void {
        if (this.isGuest) {
            this.summaryService
                .getGuestCustomerInformation$()
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe({
                    next: (information) => {
                        this._guestInformation = information;
                    },
                });
        }
    }

    // getCartBill(selectedPayment: PaymentMethodsKeys): Observable<CartBill | null> {
    //     const totalPromoCodeApplied = this.subscribePromoCodesApplied();

    //     return this.summaryService.getCartBill$().pipe(
    //         take(1),
    //         switchMap((cartBill) => {
    //             const nonReservationFeeCart = cartBill?.nonReservationFee;
    //             const additionalFee: number | undefined = nonReservationFeeCart
    //                 ? nonReservationFeeCart - totalPromoCodeApplied
    //                 : undefined;
    //             return this._cartAPIService.getCartBillV1(selectedPayment, additionalFee).pipe(
    //                 map((currentCartBill) => {
    //                     const cartBill = currentCartBill as CartBill;
    //                     this.summaryService.setCartBill(cartBill);
    //                     this.hasAGIPayments =
    //                         cartBill?.cart?.filter(
    //                             (item: any) => item?.externalData?.source?.toLowerCase() === 'alliance'
    //                         )?.length > 0;
    //                     const paymentRequest = cartBill?.cart?.filter((item: any) => !item?.originalInvoice);
    //                     this._sessionService.setPayCount(cartBill?.cart?.length);
    //                     this._sessionService.setPaymentRequest(paymentRequest?.length || 0);
    //                     return cartBill;
    //                 })
    //             );
    //         })
    //     );
    // }

    subscribePromoCodesApplied(): number {
        return this.promoCodeByAWBService.getAllPromoCodeApplied().length * 8;
    }
}
