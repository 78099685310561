<div class="cart-buttons">
    <ng-container *ngIf="isEditable">
        <button class="" (click)="editCart()" mat-button>
            <span *ngIf="!deletingCart">Edit</span>
            <mat-icon class="ms-2 spinner-icon" color="error" *ngIf="deletingCart">
                <mat-spinner diameter="20" color="error"></mat-spinner>
            </mat-icon>
        </button>
        <mat-divider [vertical]="true"></mat-divider>
    </ng-container>
    <button class="" mat-button (click)="deleteCart()">
        <span *ngIf="!deletingCart">Delete</span>
        <mat-icon class="ms-2 spinner-icon" color="error" *ngIf="deletingCart">
            <mat-spinner diameter="20" color="error"></mat-spinner>
        </mat-icon>
    </button>
</div>
