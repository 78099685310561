import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderDotPulseComponent } from './loader-dot-pulse/loader-dot-pulse.component';

@NgModule({
    declarations: [LoaderDotPulseComponent],
    imports: [CommonModule],
    exports: [LoaderDotPulseComponent],
})
export class LoadersModule {}
