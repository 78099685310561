import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromoCodeListResponse } from '@cargos/sprintpay-models';
import { Subject, combineLatest, of, switchMap, take, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { PromoCodeByAWBService } from 'src/app/services/promo-code.service';
import { CartThresholdState } from 'src/app/services/utils/cart/models/types';
import { UserSessionService } from 'src/app/services/utils/user-session.service';

@Component({
    selector: 'app-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrl: './cart-summary.component.scss',
})
export class CartSummaryComponent implements OnInit, OnDestroy {
    public totalAmountAboveThreshold: number | null;
    public totalAmountBelowAboveThreshold: number | null;
    public cartThresholdState = CartThresholdState;
    public isThereInvoicesInCart: boolean;
    public isAuthenticated: boolean = false;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private cartBillService: CartBillService,
        private promoCodeService: PromoCodeByAWBService,
        private userSessionService: UserSessionService
    ) {}

    ngOnInit(): void {
        this.subscribeToCart();
        this.subscribePromoCodesByUser();
        this.subscribeUserSessionStatus();
        this.isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeToCart(): void {
        combineLatest([
            this.cartBillService.isCartAboveThreshold$().pipe(
                switchMap((isCartAboveThreshold) => {
                    if (!isCartAboveThreshold) {
                        return of(null);
                    }
                    return this.cartBillService.getTotalByThreshold$(true);
                })
            ),
            this.cartBillService.isCartBelowThreshold$().pipe(
                switchMap((isCartBelowThreshold) => {
                    if (!isCartBelowThreshold) {
                        return of(null);
                    }
                    return this.cartBillService.getTotalByThreshold$(false);
                })
            ),
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([totalAmountAboveThreshold, totalAmountBelowAboveThreshold]) => {
                this.totalAmountAboveThreshold = totalAmountAboveThreshold;
                this.totalAmountBelowAboveThreshold = totalAmountBelowAboveThreshold;
            });
    }

    subscribePromoCodesByUser(): void {
        this.promoCodeService
            .getPromoCodesByUserRequest()
            .pipe(take(1))
            .subscribe({
                next: (promoCodeListResponse: PromoCodeListResponse[]) => {
                    this.promoCodeService.setPromoCodeListByUser(promoCodeListResponse);
                },
            });
    }

    subscribeUserSessionStatus(): void {
        this.userSessionService
            .isAuthenticated$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isAuthenticated: boolean) => {
                    this.isAuthenticated = isAuthenticated;
                },
            });
    }
}
