import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCalendar, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import * as moment from 'moment/moment';
import { throwError } from 'rxjs';
import { Toggle, ToggleEvent } from 'src/app/models/events/slide-toggle.model';
import { CustomStatuses, RefundStatusDescription } from 'src/app/models/refunds/refunds';
import { Actions } from 'src/app/models/utils/actions.model';
import { DateRangeChangeEvent } from 'src/app/models/utils/date.model';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { paginatorSize, tableCustomStatues } from '../../utils/constants';
import { ProfileTypes } from '../../utils/profile-types';
import { ActionsTableComponent } from '../actions-table/actions-table.component';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { RefundButtonComponent } from '../refund-button/refund-button.component';
import { RefundStatusComponent } from '../refund-status/refund-status.component';

export interface PaginatorObj {
    pageSize: number;
    currentPage: number;
}

@Component({
    selector: 'app-am-table',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatMenuModule,
        MatToolbarModule,
        MatSelectModule,
        RouterModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatTooltipModule,
        ActionsTableComponent,
        RefundButtonComponent,
        RefundStatusComponent,
        MatSlideToggleModule,
        MatDividerModule,
    ],
    templateUrl: './am-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AmTableComponent implements OnInit, AfterViewInit, OnChanges {
    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        event.target.innerWidth;
        this._fixedLayoutStatusReview();
    }

    @Input() am_disableGenerateFile = false;
    @Input() am_showRedirectToBankAccount = false;
    @Input() am_source: any;
    @Input() am_columns: any = [];
    @Input() am_columnsName: any = [];
    @Input() am_filter?: any;
    @Input() am_multi_filter: any;
    @Input() am_sorting?: any;
    @Input() am_pagination?: any;
    @Input() am_paginationSizes?: any;
    @Input() am_totalRows?: any;
    @Input() am_actions?: Actions[];
    @Input() am_toggle?: Toggle;
    @Input() am_addItem?: string;
    @Input() am_filterByColumn?: boolean;
    @Input() am_actionRefund?: Actions;
    @Input() recordIndicators?: any[];
    @Input() am_refresh?: boolean;
    @Input() am_refund?: boolean;
    @Input() am_downloadbar?: boolean;
    @Input() am_generateFile?: boolean;
    @Input() am_superACHDownload?: boolean;
    @Input() am_customStatusColumn?: boolean;
    @Input() am_datePicker?: boolean;
    @Input() am_sortableColumns?: any = [];
    @Input() am_emptyTableMessage?: string = '';
    @Input() am_emptyTableContent?: boolean;
    @Input() am_statusFilter?: CustomStatuses[];
    @Input() am_linkEnabled?: boolean = true;
    @Output() nextPage: EventEmitter<any> = new EventEmitter();
    @Output() filter: EventEmitter<any> = new EventEmitter();
    @Output() executeFunction: EventEmitter<any> = new EventEmitter();
    @Output() ifItemSelected: EventEmitter<any> = new EventEmitter();
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    @Output() downloadBar: EventEmitter<any> = new EventEmitter();
    @Output() actionRefund: EventEmitter<any> = new EventEmitter();
    @Output() actionDownload: EventEmitter<any> = new EventEmitter();
    @Output() actionSuperACHDownload: EventEmitter<any> = new EventEmitter();
    @Output() dateRange: EventEmitter<DateRangeChangeEvent> = new EventEmitter();
    @Output() eventOnSort: EventEmitter<any> = new EventEmitter();
    @Output() actionChangeStatus: EventEmitter<CustomStatuses[]> = new EventEmitter();
    @Output() actionApplyStatus: EventEmitter<void> = new EventEmitter();
    @Output() toggleClick: EventEmitter<ToggleEvent> = new EventEmitter();
    @Output() filterByColumnClick: EventEmitter<void> = new EventEmitter();
    @Output() addItem: EventEmitter<void> = new EventEmitter();
    @Output() eventOnGenerateFile: EventEmitter<void> = new EventEmitter();

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('picker', { static: false }) calendar!: MatCalendar<Date>;
    @ViewChild('statusesSelect') statusesSelect?: MatSelect;
    @ViewChild('toggle') toggleComponent?: MatSlideToggle;

    columnsName: any;
    expandedElement: any;
    currentPageSize: number;
    currentPage: number;
    search: FormControl = new FormControl('', [CustomValidators.preventHTMLContent()]);
    value!: any;
    customStatues!: any;
    selection: SelectionModel<any>;
    dataSource!: MatTableDataSource<any>;
    minDate: any;
    maxDate: any;
    range: FormGroup;
    fixedLayoutStatus: boolean | undefined;
    customStatusesArray: CustomStatuses[];
    readonly mobileQuery: MediaQueryList;
    readonly mobileQueryListener: () => void;
    private readonly _minMaxRange: number;
    private _filterFirstRound: boolean;
    private _valueFirstRound: string;
    private readonly _dateRange: number;
    activeProfileView: string;
    calendarHeader = CalendarHeaderComponent;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _mediaMatcher: MediaMatcher,
        private _router: Router,
        private _customerService: CustomerService,
        private _securityService: SecurityService
    ) {
        this.columnsName = [];
        this.am_columns = [];
        this.customStatues = tableCustomStatues;
        this.selection = new SelectionModel<any>(true, []);
        this._filterFirstRound = false;
        this._valueFirstRound = '';
        this.mobileQuery = _mediaMatcher.matchMedia('(max-width: 1199px)');
        this.mobileQueryListener = () => _changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this.mobileQueryListener, { passive: true });
        this._dateRange = 7; // 7 days
        this._minMaxRange = 90; // 90 days
        this.minDate = moment().format('2011-01-02');
        this.maxDate = moment().format('YYYY-MM-DD');
        this.range = new FormGroup({
            start: new FormControl<Date | null>(null),
            end: new FormControl<Date | null>(null),
        });
        this.fixedLayoutStatus = undefined;
        this.currentPageSize = paginatorSize[0];
        this.currentPage = 0;
        this.activeProfileView = this._customerService.getActiveProfileView();
        this.customStatusesArray = Object.values(CustomStatuses);
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.am_source);
        this.columnsName = [...this.am_columns];
        if (this.am_superACHDownload) {
            this.columnsName = [...this.columnsName, 'downloadAsExcel'];
        }
        if (this.am_actionRefund) {
            this.columnsName = [...this.columnsName, 'action'];
        }
        if (this.am_actions && this.am_actions.length > 0) {
            this.columnsName = [...this.columnsName, 'expand'];
        }
        if (this.am_toggle) {
            this.columnsName = [...this.columnsName, 'toggle'];
        }
        this.selection.isSelected = this._isSelected.bind(this);
        this.initDateRange();
        this._fixedLayoutStatusReview();
    }

    ngOnChanges(changes: SimpleChanges): void {
        !changes['am_source']?.['firstChange'] ? (this.dataSource.data = this.am_source) : '';
        this._fixedLayoutStatusReview();
    }

    private _fixedLayoutStatusReview(): void {
        if (this.dataSource?.data.length > 0) {
            if (this.mobileQuery.matches) {
                this.fixedLayoutStatus = false;
            } else {
                this.fixedLayoutStatus = true;
            }
        } else {
            this.fixedLayoutStatus = false;
        }
    }

    ngAfterViewInit(): void {
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe({
            next: () => {
                let field = this.sort.active;
                if (field === 'customerRef') {
                    field = 'customerReference';
                }
                this.eventOnSort.emit({ field, direction: this.sort.direction });
            },
            error: (err: any): void => {
                throwError(() => err);
            },
        });

        this.am_pagination ? (this.dataSource.paginator = this.paginator) : '';

        this._changeDetectorRef.detectChanges();
    }

    /**
     * @method _isSelected()
     * @param (row: any)
     * @description: Function that checks if row is selected
     */

    private _isSelected(row: any): boolean {
        return !!this.selection.selected.find((el) => el.id === row.id);
    }

    /**
     * @method applyFilter()
     * @param (event: Event)
     * @description: When user types anything on the input filter it will trigger the function to search for matching content
     */

    public applyFilter(event: any): void {
        if (this.search.valid) {
            const filterValue: string = (event.target as HTMLInputElement).value;
            if (filterValue !== this._valueFirstRound && this._valueFirstRound !== '') {
                this._filterFirstRound = false;
            }
            if (!this._filterFirstRound) {
                this._filterFirstRound = true;
                this.currentPage = 0;
                this._valueFirstRound = filterValue;
            }
            const paginatorObj: PaginatorObj = {
                pageSize: this.currentPageSize,
                currentPage: this.currentPage,
            };

            this.filter.emit({ value: filterValue, paginator: paginatorObj });
        }
    }

    /**
     * @method clearSearch()
     * @description: When user clicks the delete button on the input it will clear the filter and return to it's original
     */

    public clearSearch(): void {
        this.currentPage = 0;
        const paginatorObj: PaginatorObj = {
            pageSize: this.currentPageSize,
            currentPage: this.currentPage,
        };
        this.dataSource.filter = '';
        this._filterFirstRound = false;
        this._valueFirstRound = '';
        this.value = '';
        this.filter.emit({ value: '', paginator: paginatorObj });
    }

    /**
     * @method isAllSelected()
     * @description: Review that all checkboxes are selected
     */

    isAllSelected(): boolean {
        return this.dataSource.data
            .filter((item: any) => !item?.disabledCheckbox)
            .map((item: any) => item.id)
            .every((row: any) => this.selection.selected.map((item: any) => item.id).includes(row));
    }

    /**
     * @method currentPageHasSelection()
     * @description: Review that current page has selection
     */

    currentPageHasSelection(): boolean {
        return this.selection.selected
            .map((item: any) => item.id)
            .some((row: any): boolean => this.dataSource.data.map((item: any) => item.id).indexOf(row) >= 0);
    }

    /**
     * @method masterToggle()
     * @description: Triggers all checkboxes are selected
     */

    masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.selected.forEach((selectedRow: any): void => {
                if (this.dataSource.data.find((item: any): boolean => item.id === selectedRow.id)) {
                    this.selection.deselect(selectedRow);
                }
            });
        } else {
            this.dataSource.data.forEach((row: any) => {
                if (!row?.disabledCheckbox) this.selection.select(row);
            });
        }
        this.ifItemSelected.emit(this.selection.selected);
    }

    /**
     * @method toggleRowSelection()
     * @param (row: any)
     * @description: Toggle selection for provided row
     */

    toggleRowSelection(row: any): void {
        if (!!row?.disabledCheckbox) return;
        const selectedRow: any = this.selection.selected.find((item: any): boolean => item.id === row.id);
        selectedRow ? this.selection.deselect(selectedRow) : this.selection.select(row);
        this.ifItemSelected.emit(this.selection.selected);
    }

    /**
     * @method checkboxLabel()
     * @param (row?: any)
     * @description: Review selected label
     */

    checkboxLabel(row?: any): any {
        //return !row ? `${this.isAllSelected() ? 'deselect' : 'select'} all` : `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    /**
     * @method executeButtonFunction()
     * @param (event: any)
     */

    executeButtonFunction(event: any): void {
        this.executeFunction.emit(event);
    }

    /**
     * @method pageChanged()
     * @description Gets page index and page size from paginator
     */

    pageChanged(event: PageEvent): void {
        this.currentPageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.addInvoiceToCart();
    }

    /**
     * @method addInvoiceToCart()
     * @description
     */

    addInvoiceToCart(): void {
        const paginatorObj: PaginatorObj = {
            pageSize: this.currentPageSize,
            currentPage: this.currentPage,
        };

        this.nextPage.emit({ value: this._valueFirstRound, paginator: paginatorObj });
    }

    /**
     * @method selectRecord()
     * @param (record: any)
     * @param (item: any)
     * @description
     */

    selectRecord($event: any): void {
        this.bindActionFunction($event.record, $event.item);
    }

    /**
     * @method bindActionFunction()
     * @param (record: any)
     * @param (item: any)
     * @description
     */

    bindActionFunction(record: any, item: any): void {
        let funcBinding = record.function.prototype[record.function.name].bind(record.function.prototype);
        funcBinding(item.id);
    }

    /**
     * @method getCustomStatusKey()
     * @param (status: any)
     * @description
     */

    getCustomStatusKey(status: any) {
        return this.customStatues[status] ? this.customStatues[status] : 'none';
    }

    /**
     * @method applyRefresh()
     * @description refresh the latest data
     */

    applyRefresh(): void {
        this.refresh?.emit();
    }

    /**
     * @method applyDownload()
     * @description download the selected payments
     */

    applyDownload(): void {
        this.downloadBar?.emit();
    }

    /**
     * @method generateFile()
     * @description generate file
     */

    generateFile(): void {
        this.eventOnGenerateFile?.emit();
    }

    /**
     * @method applyActionRefund()
     * @description download the selected payments
     */

    applyActionRefund(): void {
        this.actionRefund?.emit();
    }

    /**
     * @method applyActionDownload()
     * @description download the selected payments
     */

    applyActionDownload(): void {
        this.actionDownload?.emit();
    }

    /**
     * @method applyActionSuperACHDownload()
     * @description download the selected payment as excel
     */

    applyActionSuperACHDownload(row: any): void {
        this.actionSuperACHDownload?.emit(row);
    }

    /**
     * @method initDateRange()
     * @param ()
     * @description
     */

    initDateRange(): void {
        const today = new Date();
        const minDiffDays: number = 1000 * 60 * 60 * 24 * this._minMaxRange;
        const diffDays: number = 1000 * 60 * 60 * 24 * this._dateRange;
        const startDate: Date = new Date(today.getTime() - (this.am_refund ? minDiffDays : diffDays));

        this.range.patchValue({
            start: startDate,
            end: today,
        });

        this.maxDate = today;
    }

    /**
     * @method onDateChanged()
     * @description
     */

    onDateChanged(): void {
        const today = new Date();
        const minDiffDays = 1000 * 60 * 60 * 24 * this._minMaxRange;
        const maxEndDate = new Date(new Date(this.range.value.start).getTime() + minDiffDays);
        const endDate = today.getTime() > maxEndDate.getTime() ? maxEndDate : today;

        if (!this.range.value.end) {
            this.range.patchValue({
                start: this.range.value.start,
                end: endDate,
            });
        }

        const dateRangeData = {
            start: moment(this.range.value.start).format('YYYY-MM-DD'),
            end: moment(this.range.value.end).format('YYYY-MM-DD'),
        };
        this.dateRange?.emit(dateRangeData);
        this.calendar.startView = 'month';
        this.maxDate = endDate;
    }

    onStartDateChanged(type: string, event: MatDatepickerInputEvent<Date>): void {
        if (event.value) {
            const today = new Date();
            const startDate = new Date(event.value);
            const minDiffDays: number = 1000 * 60 * 60 * 24 * this._minMaxRange;

            if (today.getTime() > startDate.getTime() + minDiffDays) {
                this.maxDate = new Date(startDate.getTime() + minDiffDays);
            }
            this.range.patchValue({
                end: '',
            });
        }
    }

    onEndDateChaged(event: MatDatepickerInputEvent<Date>): void {
        const today = new Date();
        if (event.value) {
            this.maxDate = today;
        }
    }

    /**
     * @method hasSortable()
     * @param (column: string)
     * @description returns the sortable availability
     */

    hasSortable(column: string) {
        return this.am_sortableColumns
            ? this.am_sortableColumns.filter((item: string) => item === column).length > 0
            : true;
    }

    /**
     * @method goToPaymentHistory()
     * @description It will display a SWAL to take the user to the payment history to access the refund actions on the history section table
     */
    goToPaymentHistory(): void {
        Swal.fire({
            title: 'Create Refund Request',
            html: `<span>Click continue to be redirected to your payment history section. Search for your payment, then click the refund symbol&nbsp;</span><span class="material-symbols-outlined align-middle">monetization_on</span><span>&nbsp;to start your refund request</span>`,
            iconHtml: `<span class="material-symbols-outlined" style="font-size: 105px;">monetization_on</span>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#14bb9c',
            allowOutsideClick: false,
            showCloseButton: true,
            customClass: {
                htmlContainer: 'overflow-hidden',
            },
        }).then((result: SweetAlertResult): void => {
            if (result.isConfirmed) {
                if (this.activeProfileView === ProfileTypes.FORWARDER) {
                    this._router.navigate([`/admin/facilityPayments/paymentHistory/paymentList`]);
                } else if (this.activeProfileView === ProfileTypes.FACILITY) {
                    this._router.navigate([`/super-admin/payments`]);
                }
            }
        });
    }

    getDescriptionForStatus(status: CustomStatuses): string {
        return RefundStatusDescription[status];
    }

    revertStatusFilterSelection(event: boolean): void {
        if (!event && this.am_statusFilter) {
            this.actionChangeStatus?.emit([...this.am_statusFilter]);
        }
    }

    toggleStatusFilterSelection(event: boolean): void {
        const statuses = event ? this.customStatusesArray : [];
        this.statusesSelect?.writeValue(statuses);
    }

    applyStatuses(): void {
        this.actionChangeStatus?.emit(this.statusesSelect?.value);
        this.statusesSelect?.close();
        this.actionApplyStatus.emit();
    }

    onToggleClick(event: MouseEvent, element: unknown): void {
        this.toggleClick.emit({ event, value: element });
    }

    onAddItem(): void {
        this.addItem.emit();
    }

    redirectToBankDebit(): void {
        const currentRoute = this._router.url.split('/')[1];
        this._router.navigate([`/${currentRoute}/bank-debits`]);
    }

    toggle(): void {
        this.toggleComponent?.toggle();
    }
}
