import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ImageLoaderComponent } from '../image-loader/image-loader.component';

@Component({
    standalone: true,
    selector: 'app-logo-payment-method',
    templateUrl: './logo-payment-method.component.html',
    styleUrls: ['./logo-payment-method.component.scss'],
    imports: [ImageLoaderComponent, CommonModule],
})
export class LogoPaymentMethodComponent {
    @Input() paymentMethodName: string;
    @Input() alt: string;
    @Input() title: string;
    @Input() width = '3.6875em';
    @Input() height = 'auto';
}
