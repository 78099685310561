<mat-selection-list [multiple]="false" #cargoCreditBalance>
    <mat-list-option
        [value]="cargoCredit"
        (click)="selectPaymentMethod()"
        [selected]="paymentMethodSelected === PaymentMethodsEnum.CARGO_CREDIT"
        checkboxPosition="before"
        color="primary"
    >
        <div class="d-flex flex-row align-items-center pe-3">
            <app-logo-payment-method
                [paymentMethodName]="PaymentMethodsEnum.CARGO_CREDIT"
                [width]="'2.3em'"
                [height]="'24px'"
                class="me-3"
            ></app-logo-payment-method>
            <div class="d-flex flex-column">
                <div class="d-flex">Bill my SprintPay account</div>
            </div>
        </div>
    </mat-list-option>
</mat-selection-list>
