import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';

@Injectable({
    providedIn: 'root',
})
export class ByPortGuard {
    constructor(private summaryValidationService: SummaryValidationService) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.summaryValidationService.validateCartByPort();
    }
}
