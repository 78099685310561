import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { PaymentMethod, PaymentMethods } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { CreditCardsListOptionComponent } from './credit-card-list-option/credit-cards-list-option.component';
import { EmptyCardListComponent } from './empty-card-list/empty-card-list.component';

@Component({
    standalone: true,
    imports: [NgIf, NgFor, CreditCardsListOptionComponent, MatListModule, EmptyCardListComponent],
    selector: 'app-credit-cards-list',
    templateUrl: './credit-cards.component.html',
    styleUrls: ['./credit-cards.component.scss'],
})
export class CreditCardsListComponent implements OnInit, OnDestroy {
    public creditCards?: PaymentMethod;
    public token: string;
    private unsubscribe$: Subject<void>;

    @Input() aboveThreshold: boolean = false;
    @Input() loading: boolean = false;
    @Input() displayEmptyList: boolean = false;
    @Output() eventOnSelectCreditCard: EventEmitter<string> = new EventEmitter<string>();

    constructor(private paymentMethodsService: PaymentMethodsService) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeCreditCards();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCreditCards(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.CREDIT_CARD)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (creditCards: PaymentMethod | undefined) => {
                    this.creditCards = creditCards;
                },
            });
    }

    selectCreditCard(token: string): void {
        this.eventOnSelectCreditCard.emit(token);
    }
}
