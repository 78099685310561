<div id="cart-banner">
    <div class="alert fade show p-0 m-0 w-100" role="alert">
        <div>
            <div class="mat-body-2 message">
                {{threshold?.message}}
                <div class="cart-alert">
                    <mat-icon class="md-icon-16 ms-1"> info </mat-icon>
                    <div class="container-alert-information mat-subtitle-1" [innerHtml]="helpInformation"></div>
                </div>
            </div>
        </div>
    </div>
</div>
