import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    exports: [
        MatRadioModule,
        MatListModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDividerModule,
    ],
})
export class EcheckListtMaterialModule {}
