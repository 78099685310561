<form [formGroup]="promoCodeForm" class="d-flex flex-row flex-shrink-1">
    <div class="promo-code-form w-100">
        <ng-select
            class="promo"
            [items]="promoCodesList"
            bindLabel="name"
            bindValue="name"
            [addTag]="true"
            placeholder="Select a promo code"
            formControlName="promoCode"
            appearance="outline"
            (open)="onOpenDropDown()"
            (close)="onCloseDropDown()"
        >
        </ng-select>
        <mat-error>
            <ng-container *ngIf="errorVisible">
                <ng-container *ngFor="let message of messageConfig">
                    <span class="m-0 message-mat-error"> {{message.message}} </span>
                </ng-container>
            </ng-container>
        </mat-error>
    </div>
    <div class="pt-3">
        <button
            *ngIf="!isLoading && !promoCodeApplied"
            mat-button
            matSuffix
            type="submit"
            color="primary"
            (click)="validatePromoCode(); $event.stopPropagation()"
            [disabled]="isLoading"
        >
            Apply
        </button>

        <button
            *ngIf="!isLoading && !!promoCodeApplied"
            mat-button
            matSuffix
            type="submit"
            color="error"
            (click)="deletePromoCode(); $event.stopPropagation()"
            [disabled]="isLoading"
        >
            Delete
        </button>
        <mat-spinner *ngIf="isLoading" diameter="24" matSuffix class="me-3"></mat-spinner>
    </div>
</form>
