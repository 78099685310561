import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/utils/auth.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../../utils/error-matcher';
import { MatchPassword } from '../../../../utils/match-password';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
})
export class NewPasswordComponent implements OnInit, OnDestroy {
    isValidActivationKey: boolean;
    newPasswordForm!: FormGroup;
    passwordHintHide: boolean;
    matcher: ErrorMatcher;
    private _activationKeys: any;
    private _unsubscribe$: Subject<void>;
    private readonly _authentication: string;

    constructor(
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorHandlerService: ErrorHandlerService,
        private _authService: AuthService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.passwordHintHide = true;
        this.isValidActivationKey = false;
        this._authentication = environment.uris.method.authentication;
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._activatedRoute.queryParams.pipe(takeUntil(this._unsubscribe$)).subscribe({
            next: (params: Params) => {
                this._activationKeys = params['activationKey'];
                this._validateActivationKey(this._activationKeys);
                if (!this._activationKeys) {
                    this._router.navigate(['/login']);
                }
            },
            error: (error: Error) => {
                throwError(() => error);
            },
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.newPasswordForm = this._formBuilder.group(
            {
                password: new FormControl<any | null>(null, [
                    Validators.required,
                    Validators.minLength(8),
                    this._regexValidator(new RegExp('^\\S*$'), { 'non-whitespaces': true }),
                    this._regexValidator(new RegExp('^(?=.*[A-Za-z]).*$'), { letter: true }),
                    this._regexValidator(new RegExp('^(?=.*?[0-9]).*$'), { number: true }),
                ]),
                confirmPassword: new FormControl<any | null>(null, [Validators.required]),
            },
            {
                validators: [MatchPassword.match('password', 'confirmPassword')],
            }
        );
    }

    /**
     * @method getPassword()
     * @description: Convenience getter for easy access to form fields
     */

    get getPassword(): string {
        return this.newPasswordForm.value['password'] as string;
    }

    /**
     * @method getConfirmPassword()
     * @description: Convenience getter for easy access to form fields
     */

    get getConfirmPassword(): string {
        return this.newPasswordForm.value['confirmPassword'] as string;
    }

    /**
     * @method setPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setPassword(event: any) {
        this.newPasswordForm.get('password')!.setValue(event);
    }

    /**
     * @method setConfirmPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setConfirmPassword(event: any) {
        this.newPasswordForm.get('confirmPassword')!.setValue(event);
    }

    /**
     * @method regexValidator()
     * @param (regex: RegExp)
     * @param (error: ValidationErrors)
     * @description: Takes the regex and the name for the validator and return true or false based on the result of the if statements
     */

    private _regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [p: string]: any } | null => {
            return !control.value ? null : regex.test(control.value) ? null : error;
        };
    }

    /**
     * @method validateActivationKey()
     * @param (activationKey: any)
     * @description Review that the token it's still valid
     */

    private _validateActivationKey(activationKey: string): void {
        this.isValidActivationKey = true;

        this._authService
            .validateActivationKey(activationKey)
            .pipe(take(1))
            .subscribe({
                next: (response: boolean) => {
                    this.isValidActivationKey = response;
                },
                error: (error: any) => {
                    this.isValidActivationKey = false;
                    Swal.fire({
                        title: 'Oops...',
                        text: 'Your activation key was already used, recover your password to get new activation key.',
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'Recover password',
                        allowOutsideClick: false,
                    }).then(() => {
                        this._router.navigate(['password/forgot-password']);
                    });
                },
            });
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.newPasswordForm.valid) {
            this._ngxSpinnerService.show();
            const url: string = `${this._authentication}/recoverWithActivationKey`;
            const params = {
                activationKey: this._activationKeys,
                newPassword: this.getPassword,
                verifyNewPassword: this.getConfirmPassword,
            };

            this._restService
                .post(url, { data: params })
                .then((response: any) => {
                    if (response) {
                        Swal.fire({
                            title: 'Good job...',
                            text: 'Your password was updated, now you can sign in.',
                            icon: 'success',
                            confirmButtonText: 'Close',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                        }).then(() => {
                            this._router.navigate(['/login']);
                        });
                    }
                })
                .catch((error: any) => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                })
                .finally(() => {
                    this._ngxSpinnerService.hide();
                });
        } else if (this.newPasswordForm.invalid) {
            return;
        }
    }
}
