import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { regex } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';

@Component({
    selector: 'app-voucher-request',
    templateUrl: './voucher-request.component.html',
})
export class VoucherRequestComponent implements OnInit {
    @Output() voucherNumber: EventEmitter<any> = new EventEmitter();
    public voucherForm!: FormGroup;
    public matcher: ErrorMatcher;
    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder
    ) {
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.voucherForm = this._formBuilder.group({
            voucher: ['', [Validators.required, Validators.pattern(regex.schenkerVoucher)]],
        });
    }

    /**
     * @method voucher()
     * @description: Convenience getter for easy access to form fields
     */

    get voucher(): FormGroup {
        return this.voucherForm.get('voucher') as FormGroup;
    }

    /**
     * @method validateVoucherNumber()
     * @param (event: any)
     * @description
     */

    validateVoucherNumber(event: any): void {
        const voucherValue: string = event.target.value;
        let voucherNumberExp: RegExp = new RegExp(regex.schenkerVoucher);
        if (!voucherNumberExp.test(voucherValue)) {
            this.voucher.setErrors({ incorrect: true });
            this.voucherForm.updateValueAndValidity();
        }
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method continue()
     */

    continue(): void {
        this.voucherNumber.emit(this.voucher.value);
        this.closeDialog();
    }
}
