import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaymentRequest } from '../../models/payments/payment-request.model';
import { DataSecurityService } from '../data-security.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService implements OnDestroy {
    private _currentPayment: BehaviorSubject<any>;
    private unsubscribe$: Subject<void>;

    constructor(private _dataSecurityService: DataSecurityService) {
        this._currentPayment = new BehaviorSubject<any>(this.getElement('currentPayment'));
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method saveElement()
     * @param (key: any)
     * @param (value: any)
     * @description
     */

    saveElement(key: any, value: any): void {
        sessionStorage.setItem(key, this._dataSecurityService.encrypt(JSON.stringify(value)));
        key == 'currentPayment' ? this._currentPayment.next(this.getElement(key)) : '';
    }

    /**
     * @method removeElement()
     * @param (key: any)
     * @description
     */

    removeElement(key: any): void {
        sessionStorage.removeItem(key);
        this._currentPayment.next(this.getElement(key));
        key == 'currentPayment' ? this._currentPayment.next(this.getElement(key)) : '';
    }

    /**
     * @method getElement()
     * @param (key: any)
     * @param (str?: boolean)
     * @description
     */

    getElement(key: any, str?: boolean): any {
        if (sessionStorage.getItem(key) !== null) {
            const sessionValue = this._dataSecurityService.decryptString(sessionStorage.getItem(key)!);
            return str ? sessionValue : sessionValue !== null ? JSON.parse(sessionValue) : null;
        }
    }

    /**
     * @method clean()
     * @description
     */

    clean(): void {
        sessionStorage.clear();
    }

    /**
     * @method getCurrentPayment()
     * @description
     */

    getCurrentPayment(): Observable<any> {
        return this._currentPayment.asObservable();
    }

    /**
     * @method setCart()
     * @param (payments: any)
     * @description
     */

    setCart(payments: any): Promise<any> {
        return new Promise((resolve) => {
            let paymentCart = <Array<PaymentRequest>>[];
            for (let payment of payments) {
                if (payment['cargoFacility__c'] == null) {
                    paymentCart.push({
                        awb: '',
                        amount: 0,
                        hawb: '',
                        notes: '',
                        attachments: '',
                        paymentType: '',
                        facility: undefined,
                        notificationEmail: '',
                        customerReference: '',
                        paidTo: '',
                        id: '',
                    });
                } else {
                    paymentCart.push({
                        awb: '',
                        amount: 0,
                        hawb: '',
                        notes: '',
                        attachments: '',
                        paymentType: '',
                        facility: undefined,
                        notificationEmail: '',
                        customerReference: '',
                        paidTo: '',
                        id: '',
                    });
                }
            }
            resolve(paymentCart);
        });
    }
}
