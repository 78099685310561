<div class="cart-item-details">
    <div class="col-12 d-flex flex-column flex-md-row justify-content-between">
        <div class="d-flex flex-column">
            <div class="mat-caption title">MAWB/AWB/MBL: <span class="information">{{paymentCart.awb}}</span></div>
            <div class="mat-caption title" *ngIf="paymentCart.hawb">
                HAWB, HBL, Container: <span class="information">{{paymentCart.hawb}}</span>
            </div>
            <div class="mat-caption title">Type: <span class="information">{{paymentCart.paymentType}}</span></div>
        </div>
        <div class="d-flex flex-column">
            <app-cart-rates [rates]="rates"></app-cart-rates>
        </div>
    </div>
    <ng-container *ngIf="hasNonReservationFee">
        <div class="col-12 d-flex flex-column flex-md-row justify-content-end">
            <div class="d-flex flex-column flex-wrap-reverse w-50">
                <app-promo-code class="mt-3" [awbNumber]="paymentCart.awb || ''"></app-promo-code>
            </div>
        </div>
    </ng-container>
</div>
