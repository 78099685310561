import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartPayResponse } from './models/types';

@Injectable()
export class PaymentConfirmationService {
    private cartPayResponse: BehaviorSubject<CartPayResponse[] | null> = new BehaviorSubject<CartPayResponse[] | null>(
        null
    );

    /**
     * @method instant_payment_response()
     * @description Return current paymentResponse value
     */

    get instant_payment_response(): CartPayResponse[] | null {
        return this.cartPayResponse.value;
    }

    /**
     * @method getPaymentResponse()
     * @description Return observable paymentResponse value
     */

    getPaymentResponse(): Observable<CartPayResponse[] | null> {
        return this.cartPayResponse.asObservable();
    }

    /**
     * @method setPaymentResponse()
     * @param (paymentResponse: boolean)
     * @description Set paymentResponse value
     */

    setPaymentResponse(CartPayResponse: CartPayResponse[] | null): void {
        this.cartPayResponse.next(CartPayResponse);
    }
}
