import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ThemeStyleFileType, ThemeType } from 'src/app/models/ui/themes';
import { CartBillService } from 'src/app/services';
import { ThemeService } from 'src/app/services/utils/theme.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { SidenavOption, SidenavOptionTypes } from 'src/app/utils/sidenav-types';
import Swal from 'sweetalert2';
import { HelpContactusService } from '../../services/help-contactus.service';
import { TermsOfUseService } from '../../services/terms-of-use.service';
import { CustomerService } from '../../services/utils/customer-handler.service';
import { SessionService } from '../../services/utils/session.service';
import { profileComponents, profileTypes } from '../../utils/constants';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

    public navItems: SidenavOption[] = [];
    public readonly themeAnchor = this._document.getElementById('appTheme');
    public readonly mobileQuery: MediaQueryList;
    public readonly mobileQueryListener: () => void;
    private initialTheme: ThemeType;
    private readonly _customer: any;
    private readonly _startingLevel!: boolean;
    private _unsubscribe$: Subject<void>;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _mediaMatcher: MediaMatcher,
        private _customerService: CustomerService,
        private _tokenService: TokenService,
        private _sessionService: SessionService,
        private _themeService: ThemeService,
        private _renderer: Renderer2,
        private _helpContactusService: HelpContactusService,
        private _termsOfUseService: TermsOfUseService,
        private cartBillService: CartBillService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.initialTheme = this._themeService.instant_theme;
        this.mobileQuery = _mediaMatcher.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () => _changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this.mobileQueryListener, {
            passive: true,
        });
        this._customer = this._customerService.getCustomer();
        this._startingLevel = this._customer.approvalLevels
            ? this._customer.approvalLevels.company.startingLevel
            : false;
        const profileType = this._customerService.getProfileType();
        const customerProfile = profileType !== profileTypes.FORWARDER_FACILITY ? profileType : profileTypes.FORWARDER;
        this._tokenService.setProfileType(customerProfile);
    }

    ngOnInit(): void {
        this._changeTheme();
        this._termsOfUseService.validateShowTermsOfUse(this._customer);
        this.navItems = [
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: 'home',
                label: 'Home',
                icon: 'home',
                route: 'admin/home',
            },
            ...this._buildFacilityPaymentsNavSection(),
            {
                type: SidenavOptionTypes.DIVIDER,
                id: '',
            },
            {
                type: SidenavOptionTypes.SECTION,
                id: profileComponents.invoice,
                label: 'Invoices',
                expandable: true,
                children: [
                    {
                        type: SidenavOptionTypes.SUBSECTION,
                        id: profileComponents.invoiceOpen,
                        label: 'Open',
                        route: 'admin/invoices/open',
                        icon: 'description',
                    },
                    {
                        type: SidenavOptionTypes.SUBSECTION,
                        id: profileComponents.invoicePaid,
                        label: 'Paid',
                        route: 'admin/invoices/paid',
                        icon: 'description',
                    },
                ],
            },
            {
                type: SidenavOptionTypes.DIVIDER,
                id: '',
            },
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: profileComponents.cart,
                label: 'Cart',
                icon: 'shopping_cart',
                route: 'admin/cart',
            },
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: profileComponents.refundsRequests,
                label: 'Refunds',
                icon: 'monetization_on',
                route: 'admin/refunds-requests',
            },
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: 'users',
                label: 'Users',
                icon: 'people',
                route: 'admin/users',
            },
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: profileComponents.facilityReferences,
                label: 'Facility contacts',
                icon: 'apartment',
                route: 'admin/facilityContacts',
            },
            {
                type: SidenavOptionTypes.SECTION_ACTION,
                id: profileComponents.settings,
                label: 'Settings',
                icon: 'settings',
                route: 'admin/settings',
            },
            {
                type: SidenavOptionTypes.HELP,
                id: '',
                label: 'Help / Contact Us',
                icon: 'help',
            },
            {
                type: SidenavOptionTypes.LOGOUT,
                id: '',
                label: 'Logout',
                icon: 'logout',
            },
            {
                type: SidenavOptionTypes.LOGIN,
                id: '',
                label: 'Sign in',
                icon: 'login',
            },
        ];
        this._sessionService.saveElement('startingLevel', this._startingLevel);
    }

    ngOnDestroy(): void {
        this._changeDetectorRef.detach();
        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
        Swal.close();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method _buildFacilityPaymentsNavSection()
     * @description Function to build facility payments navigation section
     */

    private _buildFacilityPaymentsNavSection(): SidenavOption[] {
        return this._customer.isGuest
            ? [
                  {
                      type: SidenavOptionTypes.SECTION_ACTION,
                      id: profileComponents.newPayment,
                      label: 'New payment',
                      route: 'admin/facilityPayments/newPayment',
                      icon: 'monetization_on',
                  },
              ]
            : [
                  {
                      type: SidenavOptionTypes.DIVIDER,
                      id: '',
                  },
                  {
                      type: SidenavOptionTypes.SECTION,
                      id: profileComponents.payments,
                      label: 'Facility payments',
                      expandable: true,
                      children: [
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.newPayment,
                              label: 'New payment',
                              route: 'admin/facilityPayments/newPayment',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.paymentMethod,
                              label: 'Payment methods',
                              route: 'admin/facilityPayments/paymentMethods',
                              icon: 'account_balance_wallet',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.paymentHistory,
                              label: 'Payment history',
                              route: 'admin/facilityPayments/paymentHistory',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.pendingApproval,
                              label: 'Pending approval(s)',
                              route: 'admin/facilityPayments/pendingApproval',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.rejectedPayments,
                              label: 'Rejected payment(s)',
                              route: 'admin/facilityPayments/rejectedPayment',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.voidedPayemnts,
                              label: 'Voided payment(s)',
                              route: 'admin/facilityPayments/voidedPayments',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.uploadRequestFile,
                              label: 'Upload request(s)',
                              route: 'admin/facilityPayments/uploadRequest',
                              icon: 'monetization_on',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.uploadFileByPort,
                              label: 'Upload file by port',
                              route: 'admin/facilityPayments/uploadFileByPort',
                              icon: 'monetization_on',
                          },
                      ],
                  },
                  {
                      type: SidenavOptionTypes.DIVIDER,
                      id: '',
                  },
                  {
                      type: SidenavOptionTypes.SECTION,
                      id: profileComponents.bankDebits,
                      label: 'My Bank Debits',
                      expandable: true,
                      children: [
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.bankInfo,
                              label: 'Bank info',
                              route: 'admin/bank-debits/bank-info',
                              icon: 'account_balance',
                          },
                          {
                              type: SidenavOptionTypes.SUBSECTION,
                              id: profileComponents.debitHistory,
                              label: 'Debit history',
                              route: 'admin/bank-debits/debit-history',
                              icon: 'account_balance',
                          },
                      ],
                  },
              ];
    }

    /**
     * @method redirectLink()
     * @param (url: string)
     * @description: Check the url first to decide whether or not to show the modal and close mat-sidenav if responsive
     */
    redirectLink(url: string): void {
        if (this.mobileQuery.matches) {
            this.sidenav.close();
        }

        if (this._router.url.includes('/admin/facilityPayments/newPayment/flux')) {
            Swal.fire({
                title: 'Alert!',
                text: 'Warning, your changes will be lost!',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: 'Close',
                confirmButtonText: 'Continue',
                allowOutsideClick: false,
            }).then((result: any) => {
                if (result.value) {
                    this._router.navigate([url]);
                } else {
                    Swal.close();
                }
            });
        } else {
            this._router.navigate([url]);
        }
    }

    /**
     * @method helpContactAlert()
     * @description: Logic to show the Swal Help Alert and close mat-sidenav if responsive
     */

    helpContactAlert(): void {
        if (this.mobileQuery.matches) {
            this.sidenav.close().then((): void => {
                this._helpContactusService.showHelpSwal();
            });
        } else {
            this._helpContactusService.showHelpSwal();
        }
    }

    // TODO: Check why is commented - high
    // /**
    //  * @method getCartRequestAndSetCart()
    //  * @description This method retrieves the cart bill and sets the cart bill value
    //  */

    // private _getCart(): void {
    //     this.cartBillService.getCartRequestAndSetCart().pipe(take(1)).subscribe();
    // }

    /**
     * @method _changeTheme()
     * @description
     */
    private _changeTheme(): void {
        this.initialTheme = this._themeService.getThemeBrand();
        this._themeService.setTheme(this.initialTheme);

        if (this.initialTheme === ThemeType.agi) {
            this._renderer.setAttribute(this.themeAnchor, 'href', `/${ThemeStyleFileType.agi}`);
        }
    }
}
