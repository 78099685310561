import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/overlay';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, finalize, map, switchMap, take, takeUntil } from 'rxjs';
import { UserData } from 'src/app/models/auth/signup.model';
import { errorDetails } from 'src/app/models/http/error.model';
import { ResponseMFA } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { SignUpAPIService } from 'src/app/services/requests/signup-api.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { CartService } from 'src/app/services/utils/cart/cart-v1.service';
import { regex, signupErrors } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { FormSignupService } from '../services/form-signup.service';
import { AccountFormGroupType } from './models/account-form';
import { PersonalFormControls, PersonalFormGroupType } from './models/personal-form';

@Component({
    selector: 'app-form-signup',
    templateUrl: './form-signup.component.html',
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class FormSignupComponent implements OnInit {
    public signUpForm!: FormGroup;
    public personalForm!: PersonalFormGroupType;
    public accountForm!: AccountFormGroupType;
    public facilityLogo: string = '';
    public facility: string;
    public emailValid = false;
    public readonly stepperOrientation: Observable<StepperOrientation>;
    public availableEdition: boolean = true;
    private _bootstrap_lg_screen_size: number;
    private readonly _authentication: string;
    private _unsubscribe$: Subject<void>;
    private _activationUuid: string = '';
    @ViewChild('stepper') stepper!: MatStepper;
    @ViewChild('twoFactorModal') twoFactorModal?: ComponentType<unknown>;
    constructor(
        private _formBuilder: NonNullableFormBuilder,
        private _breakpointObserver: BreakpointObserver,
        private _ngxSpinnerService: NgxSpinnerService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _errorHandlerService: ErrorHandlerService,
        private _formSignupService: FormSignupService,
        private _signUpAPIService: SignUpAPIService,
        private _matDialog: MatDialog,
        private _location: Location,
        private _cartService: CartService,
        private summaryService: SummaryService
    ) {
        this._authentication = environment.uris.method.authentication;
        this.facility = '';
        this._bootstrap_lg_screen_size = 992;
        this._unsubscribe$ = new Subject<void>();
        this.stepperOrientation = _breakpointObserver.observe(`(min-width: ${this._bootstrap_lg_screen_size}px)`).pipe(
            map(({ matches }) => (matches ? 'horizontal' : 'vertical')),
            takeUntil(this._unsubscribe$)
        );
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this.setFacility();
        // TODO: Verify that instant_guest_personal_information match with PersonalForm - low
        if (this.summaryService.instant_guest_personal_information) {
            this.personalForm.patchValue(this.summaryService.instant_guest_personal_information);
            Object.entries(this.personalForm.controls).forEach(([key, value]) => {
                if (!!value.value) {
                    this.personalForm.controls[key as keyof PersonalFormControls].disable();
                }
            });
        }

        if (this.summaryService.instant_guest_address_information) {
            this.accountForm.controls.billingAddress.patchValue(true);
            this.accountForm.controls.billingAddress.disable();
        }
    }

    setFacility(): void {
        this._activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: Params) => {
                this.facility = params['facility'].toLowerCase();
                if (this.facility === 'schenker') {
                    window.location.replace(environment.projects.sprintPayv7 + `/schenker/account-type`);
                    return;
                }
                const facility: any = {
                    'dhl-aviation': '/assets/images/facilities/dhl-logo.svg',
                    schenker: '/assets/images/facilities/schenker-logo.svg',
                    polar: '/assets/images/facilities/polar-logo.svg',
                    sprintpay: './assets/images/sprintpay-horizontal-logo.svg',
                    alliance: '/assets/images/facilities/alliance-logo-light.png',
                };
                this.facilityLogo = facility[params ? params['facility'] : 'sprintpay'];
            },
        });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.personalForm = this._formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(3)]],
            lastName: ['', [Validators.required, Validators.minLength(3)]],
            countryCode: [''],
            phone: [''],
            email: ['', [Validators.required, Validators.pattern(regex.email)]],
            emailDomain: [''],
        });

        this.accountForm = this._formBuilder.group({
            accountType: ['', []],
            addressForm: this._formBuilder.group({
                street: ['', [Validators.required]],
                street2: ['', []],
                city: ['', [Validators.required]],
                state: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
                country: ['', [Validators.required]],
                zipCode: ['', [Validators.required]],
            }),
            billingAddressForm: this._formBuilder.group({
                street: ['', []],
                street2: ['', []],
                city: ['', []],
                state: ['', []],
                country: ['', []],
                zipCode: ['', []],
            }),
            billingAddress: [false, []],
            companyForm: this._formBuilder.group({
                companyName: ['', []],
                companyId: ['', []],
                taxID: ['', []],
                branchLocation: ['', []],
            }),
        });
    }

    getUserData(data: any, emailDomainExists: boolean): UserData {
        const newData = {
            email: data.email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            countryCode: data.countryCode,
            phone: data.phone,
            notificationEmail: data.email,
        };

        if (data?.companyForm?.companyName) {
            if (emailDomainExists) {
                if (data?.companyForm?.branchLocation && data?.companyForm?.branchLocation.id >= 0) {
                    return {
                        ...newData,
                        customerAccount: {
                            id: data?.companyForm?.companyId,
                            branch: {
                                id: data?.companyForm?.branchLocation.id,
                            },
                        },
                    };
                }
                return {
                    ...newData,
                    customerAccount: {
                        id: data?.companyForm.companyId,
                    },
                };
            }

            return {
                ...newData,
                ...(data?.companyForm?.taxID ? { taxPayerIdentificationNumber: data?.companyForm?.taxID } : {}),
                customerAccount: {
                    accountName: data?.companyForm?.companyName,
                },
                shippingAddress: {
                    street: data.addressForm.street,
                    street2: data.addressForm.street2,
                    city: data.addressForm.city,
                    state: data.addressForm.state,
                    zipCode: data.addressForm.zipCode,
                    country: data.addressForm.country,
                },
                billingAddress: {
                    street: data.billingAddressForm.street,
                    street2: data.billingAddressForm.street2,
                    city: data.billingAddressForm.city,
                    state: data.billingAddressForm.state,
                    country: data.billingAddressForm.country,
                    zipCode: data.billingAddressForm.zipCode,
                },
            };
        }
        return {
            ...newData,
            shippingAddress: {
                street: data.addressForm.street,
                street2: data.addressForm.street2,
                city: data.addressForm.city,
                state: data.addressForm.state,
                zipCode: data.addressForm.zipCode,
                country: data.addressForm.country,
            },
            billingAddress: {
                street: data.billingAddressForm.street,
                street2: data.billingAddressForm.street2,
                city: data.billingAddressForm.city,
                state: data.billingAddressForm.state,
                country: data.billingAddressForm.country,
                zipCode: data.billingAddressForm.zipCode,
            },
        };
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(event: any): void {
        const verification = event;
        if (
            this.accountForm.valid &&
            this._activationUuid === '' &&
            this.personalForm.valid &&
            verification.type &&
            verification.value
        ) {
            this._ngxSpinnerService.show();
            const personalInformation = this._formSignupService.getPersonalInformation();
            const accountInformation = this._formSignupService.getAccountInformation();
            const securityInformation = this._formSignupService.getSecurityInformation();

            const data = { ...personalInformation, ...accountInformation, ...securityInformation };
            const email = personalInformation.email;
            this._formSignupService
                .getEmailDomainExist()
                .pipe(
                    take(1),
                    map((emailDomainExists) => this.getUserData(data, emailDomainExists)),
                    switchMap((user) => this._signUpAPIService.signUp(user)),
                    finalize(() => this._ngxSpinnerService.hide())
                )
                .subscribe({
                    next: (response: string) => {
                        this.availableEdition = false;
                        const objectResponse = JSON.parse(response);
                        this._activationUuid = objectResponse.activationUuid;
                        this.openDialog(verification.type, verification.value, objectResponse.activationUuid);
                    },
                    error: (error: any) => {
                        if (typeof error?.error === 'string') {
                            const errorParse = JSON.parse(error.error);
                            if (typeof errorParse !== 'string' && errorParse.error && errorParse.error.data) {
                                if (errorParse.error?.data?.isActive) {
                                    Swal.fire({
                                        title: errorParse.error?.title,
                                        text: errorParse.error?.body,
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Forgot password?',
                                        cancelButtonText: 'Sign in',
                                        showCloseButton: true,
                                        confirmButtonColor: '#14bb9c',
                                        allowOutsideClick: false,
                                    }).then((result) => {
                                        if (result.value) {
                                            this._router.navigate(['/password/forgot-password']);
                                        }
                                        if (result.isDismissed) {
                                            this.redirectToLogin();
                                        }
                                    });
                                }
                                if (!errorParse.error?.data.isActive) {
                                    Swal.fire({
                                        title: errorParse.error?.title,
                                        text: errorParse.error?.body,
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonText: 'Validate account',
                                        showCloseButton: true,
                                        confirmButtonColor: '#14bb9c',
                                        allowOutsideClick: false,
                                    }).then((result) => {
                                        if (result.value) {
                                            this.resendEmail(errorParse.error.data.activationKey, email);
                                        }
                                    });
                                }
                            } else {
                                const errorDetails = JSON.parse(error?.error)?.details;
                                this.modalError(error?.error, errorDetails);
                            }
                        } else {
                            this.modalError(error?.error);
                        }
                    },
                });
        }
        if (this._activationUuid !== '' && verification.type && verification.value) {
            this.openDialog(verification.type, verification.value, this._activationUuid);
        }
    }

    resendEmail(activationKey: string, notificationEmail: string): void {
        this._signUpAPIService
            .sendEmail(this.facility, activationKey)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.modalSuccess(notificationEmail);
                },
                error: (error) => this.modalError(error && error.error),
            });
    }

    modalSuccess(notificationEmail: string): void {
        Swal.fire({
            html: `<h4 class='swal2-title pt-0 mb-0' id='swal2-title'>Excellent...</h4>
             </br>One more thing, we send an email to <strong>${notificationEmail}</strong> to confirm your account and be able to make payments`,
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Close',
            allowOutsideClick: false,
        }).then(() => {
            this.redirectToLogin();
        });
    }

    modalError(error: any, errorDetails: errorDetails | undefined = undefined): void {
        Swal.fire({
            html: `${this._errorHandlerService.errorTemplate(error)}`,
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            allowOutsideClick: false,
        }).then(() => {
            if (errorDetails?.errorCode === signupErrors.DUPLICATED_ACCOUNT_NAME) this.onDuplicatedCompany();
        });
    }

    openDialog(mfaType: string, contact: string, activationUuid: string): void {
        this._matDialog.open(this.twoFactorModal as ComponentType<unknown>, {
            id: 'two-factor',
            disableClose: false,
            data: { mfaType, contact, activationUuid },
        });
    }

    modalAccountVerified(event: ResponseMFA): void {
        if (event && event.isVerified) {
            this.availableEdition = !this.availableEdition;
            this.personalForm.reset();
            this.accountForm.reset();
            this.stepper.reset();
            this.summaryService.clearGuestData();
            Swal.fire({
                html: `<div class="row" style="padding: 0 2rem;">
                <!--START: Sprintpay or facility logo -->
                <div class="col-12 accountCreated">
                    <figure class="facility__logo mt-5 mb-5">
                        <img class="d-block mx-auto my-0 h-100" src="./assets/images/sp-logo.svg" alt="{{facility}} logo" />
                    </figure>
                    <figure class="account__logo my-4">
                        <img
                            class="d-block mx-auto my-0 h-100"
                            src="./assets/images/sprintpay.svg"
                            alt="Account illustration logo"
                        />
                    </figure>
                    <p class="d-block w-100 text-center title">Your account has been created successfully</p>
                    <p class="text-center info">You can now start using our payment platform.</p>
                </div>
                <!--END: Sprintpay or facility logo -->
            </div>
                `,
                showConfirmButton: true,
                width: '40em',
                confirmButtonText: 'GO TO SIGN IN',
                customClass: {
                    confirmButton: 'confirmButton',
                },
                allowOutsideClick: false,
            }).then(() => {
                this.redirectToLogin();
            });
        }
    }

    goBack(): void {
        switch (this.facility) {
            case 'alliance':
                window.location.replace(environment.projects.alliance + `/login`);
                break;
            default:
                this._location.back();
                break;
        }
    }

    redirectToLogin(): void {
        switch (this.facility) {
            case 'alliance':
                window.location.replace(environment.projects.alliance + `/login`);
                break;
            default:
                this._router.navigate(['/login']);
                break;
        }
    }

    onDuplicatedCompany(): void {
        this.accountForm.get('companyForm')?.get('companyName')?.setErrors({ duplicatedCompany: true });
        this.stepper.selectedIndex = 1;
    }
}
