<!-- START: GUEST PAYMENT CONFIRMATION -->
<section id="payment-confirmation" class="container-fluid p-4">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center payment-confirmation--height">
        <div class="row justify-content-center">
            <div class="col-12">
                <img class="logo mx-auto my-2 d-block" src="assets/images/sp-logo.svg" alt="Logo" />
                <img
                    class="mx-auto d-block"
                    src="assets/images/payment-methods/success.svg"
                    alt="New user illustration"
                />
            </div>
            <!-- START: NOTIFICATION EMAIL -->
            <div class="col-12 mb-3">
                <h4 class="mat-headline-4 d-block text-center mt-4 mb-3 payment-confirmation-title">
                    Your payment has been successfully processed!
                </h4>
                <span class="mat-subtitle-1 mb-4 d-block text-center payment-confirmation-subtitle"
                    >We have sent your payment receipt to: {{notificationEmail}}</span
                >
            </div>
            <!-- END: NOTIFICATION EMAIL -->
            <!-- START: DOWNLOAD RECEIPT -->
            <div class="col-12">
                <h5 class="d-block text-center mt-2 mb-3">Receipt:</h5>
                <p class="mat-subtitle-1 d-block text-center payment-confirmation-subtitle">
                    Now you can download your receipt.
                </p>
                <button
                    mat-button
                    color="primary"
                    class="d-block mx-auto border-0 rounded-0 text-uppercase text-center btn__sprintpay btn__sprintpay--greenBorder"
                    (click)="getPaymentReceipt()"
                >
                    Download PDF
                </button>
            </div>
            <!-- END: DOWNLOAD RECEIPT -->
        </div>
        <!-- START: CREATE NEW ACCOUNT -->
        <ng-container class="row justify-content-center">
            <div class="col-sm-12 col-lg-8 my-4">
                <div class="card">
                    <h6 class="card--title d-flex align-items-center mat-headline-6">Good news!</h6>
                    <small class="card--description mat-subtitle-1"
                        >By registering right now, you can save a significant amount of time when it comes to making
                        payments.</small
                    >
                    <button
                        class="d-block mx-auto btn__sprintpay btn__sprintpay--link text-uppercase text-center"
                        (click)="validateEmail()"
                    >
                        Create a new account
                    </button>
                </div>
            </div>
        </ng-container>
        <!-- END: CREATE NEW ACCOUNT -->
    </div>
</section>
<!-- END: GUEST PAYMENT CONFIRMATION -->
