import { Injectable } from '@angular/core';
import { PaymentCart, PromoCodeListResponse } from '@cargos/sprintpay-models';
import { PromoCodeService } from '@cargos/sprintpay-services';
import {
    BehaviorSubject,
    Observable,
    catchError,
    distinctUntilChanged,
    forkJoin,
    map,
    of,
    switchMap,
    take,
} from 'rxjs';

export interface AwbPromoCode {
    promoCodeId: string;
    awb: string;
    awb2: string;
}

@Injectable({
    providedIn: 'root',
})
export class PromoCodeByAWBService {
    private promoCode = new BehaviorSubject<AwbPromoCode[]>([]);
    private promoCodeListByUser = new BehaviorSubject<PromoCodeListResponse[]>([]);

    constructor(private promoCodeService: PromoCodeService) {}

    get instantPromocode(): AwbPromoCode[] {
        return this.promoCode.value;
    }

    setPromoCodeListByUser(promoCodeListByUser: PromoCodeListResponse[]): void {
        this.promoCodeListByUser.next(promoCodeListByUser);
    }

    setPromoCodesApplied(awb: string, promoCodeId: string, awb2: string): void {
        const objIndex = this.instantPromocode.findIndex((obj) => obj.awb == awb);
        const newArray = [...this.instantPromocode];

        if (objIndex === -1) {
            newArray.push({ awb, promoCodeId, awb2 });
        } else {
            newArray[objIndex].promoCodeId = promoCodeId;
        }
        this.promoCode.next(newArray);
    }

    getAllPromoCodeApplied(): AwbPromoCode[] {
        return this.instantPromocode.filter((promoCode) => promoCode.promoCodeId);
    }

    getAllPromoCodeApplied$(): Observable<AwbPromoCode[]> {
        return this.promoCode.asObservable();
    }

    isAwbPaidWithPromoCode$(awb: string): Observable<boolean> {
        return this.getAllPromoCodeApplied$().pipe(
            map((allPromoCode: AwbPromoCode[]) => {
                return allPromoCode?.some((promoCode) => promoCode.awb === awb);
            }),
            distinctUntilChanged()
        );
    }

    isAwbPaidWithPromoCode(awb: string): boolean {
        return this.instantPromocode.some((promoCode) => promoCode.awb === awb);
    }

    removePromoCode(awb: string, promoCodeId: string): void {
        const objIndex = this.instantPromocode.findIndex((obj) => obj.promoCodeId == promoCodeId && obj.awb == awb);
        const newArray = [...this.instantPromocode];

        if (objIndex > -1) {
            newArray.splice(objIndex, 1);
        }

        this.promoCode.next(newArray);
    }

    findPromoCode(promoCodeId: string): boolean {
        return this.instantPromocode.some((promoCode) => promoCodeId === promoCode.promoCodeId);
    }

    getPromoCodeByAWB(awb: string): string | undefined {
        const promoCodes = this.getAllPromoCodeApplied();

        return promoCodes.find((promoCodes) => {
            return promoCodes.awb === awb;
        })?.promoCodeId;
    }

    getPromoCodesByUserRequest(): Observable<PromoCodeListResponse[]> {
        return this.promoCodeService.getPromoCodesByUser().pipe(catchError(() => of([])));
    }

    isAllAwbPaidWithPromoCode(paymentCarts: PaymentCart[]): Observable<boolean[]> {
        return forkJoin(
            paymentCarts.map((paymentCart) => this.isAwbPaidWithPromoCode$(paymentCart.awb || '').pipe(take(1)))
        );
    }

    getPromoCodesByUser$(): Observable<PromoCodeListResponse[]> {
        return this.promoCodeListByUser.asObservable().pipe(
            switchMap((promoCodesList) => {
                return this.getAllPromoCodeApplied$().pipe(
                    map((awbPromoCodes: AwbPromoCode[]) => {
                        const promoCodestoRemove = new Set(
                            awbPromoCodes.map((promoCodeUsed) => promoCodeUsed?.promoCodeId)
                        );

                        return promoCodesList.filter((promoCode) => !promoCodestoRemove.has(promoCode?.promoCodeId));
                    })
                );
            })
        );
    }
}
