import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { Subject, take } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { Rates } from '../models/rates.model';

@Component({
    selector: 'app-cart-item-details',
    templateUrl: './cart-item-details.component.html',
    styleUrls: ['./cart-item-details.component.scss'],
})
export class CartItemDetailsComponent implements OnInit, OnDestroy {
    public paymentCart!: PaymentCart;
    private unsubscribe$ = new Subject<void>();
    @Input() id!: number | undefined;
    @Input() rates!: Rates[];
    @Input() hasNonReservationFee: boolean = false;

    constructor(private cartBillService: CartBillService) {}

    ngOnInit(): void {
        this.subscribePaymentById();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribePaymentById(): void {
        this.cartBillService
            .getPaymentCartById$(this.id || 0)
            .pipe(take(1))
            .subscribe({
                next: (value) => {
                    if (value) {
                        this.paymentCart = value;
                    }
                },
            });
    }
}
