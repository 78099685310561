<section id="invoices-list" class="my-3 py-4">
    <!-- START: INVOICE LIST -->
    <ng-container *ngIf="openInvoices && openInvoices.length > 0">
        <!-- START: INVOICE LIST HEADER -->
        <div class="d-flex justify-content-between flex-row align-items-center">
            <!-- START: INVOICE LIST TITLE & COUNT -->
            <div class="d-block">
                <span class="list__section--title d-block mx-3 mb-1">Invoices to pay</span>
                <span class="list__section--items d-block mx-3 mb-4">Item(s): {{openInvoices.length}}</span>
            </div>
            <!-- END: INVOICE LIST TITLE & COUNT -->
            <!-- START: CLEAR CART BUTTON -->
            <button mat-button class="list__section--deleteAll text-uppercase mx-3 px-0" (click)="deleteCart()">
                Delete All
            </button>
            <!-- END: CLEAR CART BUTTON -->
        </div>
        <!-- END: INVOICE LIST HEADER -->
        <!-- START: INVOICE ITEM -->
        <mat-list>
            <mat-list-item
                *ngFor="let invoice of openInvoices; let last = last;"
                class="h-auto mb-3 cart-items-list w-103"
            >
                <!-- START: INVOICE ID -->
                <div mat-line>
                    <span class="invoices-list__main d-block mb-2">INV{{invoice.originalInvoice}}</span>
                </div>
                <!-- END: INVOICE ID -->
                <!-- START: INVOICE PROPERTIES -->
                <div mat-line>
                    <div class="d-flex justify-content-start flex-column flex-lg-row mb-3">
                        <!-- START: AWB TEMPLATE -->
                        <div class="w-100 pe-3">
                            <span class="d-block invoices-list__title mb-3">AWB</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0">{{invoice.awb}}</span>
                        </div>
                        <!-- END: AWB TEMPLATE -->
                        <!-- START: TYPE TEMPLATE -->
                        <div class="w-100 pe-3" *ngIf="invoice.paymentType && invoice.paymentType !== ''">
                            <span class="d-block invoices-list__title mb-3">Type</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0">{{ invoice.paymentType }}</span>
                        </div>
                        <!-- END: TYPE TEMPLATE -->
                        <!-- START: HAWB TEMPLATE -->
                        <div class="w-100 pe-3" *ngIf="invoice.hawb && invoice.hawb !== ''">
                            <span class="d-block invoices-list__title mb-3">HAWB/HBL/Container</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0">{{ invoice.hawb }}</span>
                        </div>
                        <!-- END: HAWB TEMPLATE -->
                        <!-- START: AMOUNT TEMPLATE -->
                        <div class="w-100">
                            <span class="d-block invoices-list__title mb-3">Amount</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0"
                                >{{ invoice.amount | currency }}</span
                            >
                        </div>
                        <!-- END: AMOUNT TEMPLATE -->
                    </div>
                </div>
                <!-- END: INVOICE PROPERTIES -->
                <!-- START: DELETE INVOICE FROM CART BUTTON -->
                <div mat-line>
                    <div class="d-flex justify-content-start flex-row align-items-center mb-4">
                        <button
                            mat-button
                            color="warn"
                            class="invoices-list__btn--delete text-uppercase px-0"
                            (click)="deletePaymentRequest(invoice)"
                        >
                            Delete
                        </button>
                    </div>
                </div>
                <!-- END: DELETE INVOICE FROM CART BUTTON -->
                <div class="divider">
                    <mat-divider *ngIf="!last"></mat-divider>
                </div>
            </mat-list-item>
        </mat-list>
        <!-- END: INVOICE ITEM -->
    </ng-container>
    <!-- END: INVOICE LIST -->
    <!-- START: PAYMENT REQUEST LIST -->
    <ng-container *ngIf="paymentRequest && paymentRequest.length > 0">
        <!-- START: PAYMENT REQUEST LIST HEADER -->
        <div class="d-flex justify-content-between flex-row align-items-center">
            <!-- START: PAYMENT REQUEST LIST TITLE & COUNT -->
            <div class="d-block">
                <span class="list__section--title d-block mx-3 mb-1">Payment(s) Request</span>
                <span class="list__section--items d-block mx-3 mb-4">Item(s): {{paymentRequest.length}}</span>
            </div>
            <!-- END: PAYMENT REQUEST LIST TITLE & COUNT -->
            <!-- START: CLEAR CART BUTTON -->
            <button mat-button class="list__section--deleteAll text-uppercase mx-3 px-0" (click)="deleteCart()">
                Delete All
            </button>
            <!-- END: CLEAR CART BUTTON -->
        </div>
        <!-- END: PAYMENT REQUEST LIST HEADER -->
        <ng-container>
            <!-- START: PAYMENT REQUEST ITEM -->
            <mat-list>
                <mat-list-item
                    *ngFor="let request of paymentRequest; let index = index;let last = last;"
                    class="h-auto mb-3 cart-items-list w-100"
                >
                    <app-awb-split
                        (returnEmptyCart)="returnEmptyCartEvent($event)"
                        [awb]="request.awb"
                        [request]="request.paymentRequest"
                        class="w-100"
                    ></app-awb-split>
                    <div class="divider">
                        <mat-divider *ngIf="!last"></mat-divider>
                    </div>
                </mat-list-item>
            </mat-list>
            <!-- END: PAYMENT REQUEST ITEM -->
        </ng-container>
        <!-- START: PAYMENT REQUEST ITEM -->
        <!-- <ng-template #CartV1>
            <mat-list>
                <mat-list-item
                    *ngFor="let request of paymentRequest; let index = index; let last = last;"
                    class="h-auto mb-3 cart-items-list w-100"
                >
                    <app-awb-split
                        [awb]="request.awb"
                        [facilityName]="request.facilityName"
                        [request]="request"
                        class="w-100"
                        (returnEmptyCart)="returnEmptyCartEvent($event)"
                    ></app-awb-split>
                    <div class="divider">
                        <mat-divider *ngIf="!last"></mat-divider>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-template> -->
        <!-- END: PAYMENT REQUEST ITEM -->
    </ng-container>
    <!-- END: PAYMENT REQUEST LIST -->
</section>
