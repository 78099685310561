export enum CartThresholdState {
    ABOVE_THRESHOLD = 'aboveThreshold',
    BELOW_THRESHOLD = 'belowThreshold',
}

export enum CartFees {
    HANDLING_FEE = 'handlingFee',
    WAIVED_HANDLING_FEE = 'waivedHandlingFee',
    CREDIT_CARD_FEE = 'creditCardFee',
}

export const ALL_FEES_LIST: readonly CartFees[] = Object.values(CartFees) as CartFees[];
