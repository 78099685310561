import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentMethod, PaymentMethods, PaymentMethodsType } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { LogoPaymentMethodComponent } from 'src/app/standalone-components/logo-payment-method/logo-payment-method.component';
import { PaypalMaterialModule } from './paypal-material-modules.module';

@Component({
    standalone: true,
    imports: [LogoPaymentMethodComponent, PaypalMaterialModule],
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.scss'],
})
export class PaypalComponent implements OnInit, OnDestroy {
    public paypal?: PaymentMethodsType | undefined;
    public PaymentMethodsEnum = PaymentMethods;
    public paymentMethodSelected: PaymentMethodsType | null = null;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() aboveThreshold: boolean = false;
    @Output() eventOnSelectPaypal: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private summaryService: SummaryService
    ) {}

    ngOnInit(): void {
        this.subscribePaypal();
        this.subscribePaymentMethodSelected();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribePaypal(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.PAYPAL)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paypal: PaymentMethod | undefined) => {
                    this.paypal = paypal?.name;
                },
            });
    }

    selectPaymentMethod(): void {
        this.eventOnSelectPaypal.emit(PaymentMethods.PAYPAL);
    }

    subscribePaymentMethodSelected(): void {
        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((paymentMethodSelected) => {
                this.paymentMethodSelected = paymentMethodSelected?.method || null;
            });
    }
}
