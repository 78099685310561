import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { promoCodeValue } from 'src/app/utils/constants';
import { Rates } from '../models/rates.model';
@Component({
    selector: 'app-cart-awb',
    templateUrl: './cart-awb.component.html',
    styleUrls: ['./cart-awb.component.scss'],
})
export class CartAwbComponent implements OnInit, OnDestroy {
    public facilityName: string | undefined;
    public panelOpenState = false;
    public rates: Rates[] = [];
    public paymentType: string = '';
    public hawb: string = '';
    public totalbyAwb: number = 0;
    public hasNonReservationFee: boolean;
    public isAwbPaidWithPromoCode: boolean = false;
    private unsubscribe$ = new Subject<void>();
    @Input() paymentRequest: PaymentCart;

    constructor(private cartBillService: CartBillService) {}

    ngOnInit(): void {
        this.facilityName = this.getFacilityName();
        this.paymentType = this.getPaymentTypeForAwb();
        this.hawb = this.getHawb();
        this.subscribeNonReservationFee();
        this.getRates();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getFacilityName(): string | undefined {
        return this.paymentRequest.facility?.name;
    }

    getPaymentTypeForAwb(): string {
        return this.paymentRequest.paymentType || '';
    }

    getHawb(): string {
        return this.paymentRequest.hawb || '';
    }

    getRates(): void {
        const totalFees = this.cartBillService.getFees(this.paymentRequest);
        this.rates = [
            {
                label: 'Subtotal',
                value: this.paymentRequest?.amount || 0,
            },
            {
                label: 'Handling fee',
                value: this.paymentRequest?.handlingFee || 0,
            },
            {
                label: 'Credit card fee',
                value: this.paymentRequest?.creditCardFee || 0,
            },
            {
                label: 'Waived Handling fee',
                value: this.paymentRequest?.waivedHandlingFee || 0,
            },
            {
                label: 'Front Counter Service Fee',
                value: this.paymentRequest?.nonReservationFee || 0,
            },
            {
                label: 'Total',
                value: (this.paymentRequest?.amount || 0) + totalFees,
            },
        ];
        this.totalbyAwb = (this.paymentRequest?.amount || 0) + totalFees;
        if (this.isAwbPaidWithPromoCode) {
            this.addedDiscountFCFee();
        }
    }

    addedDiscountFCFee(): void {
        this.rates.splice(this.rates.length - 1, 0, {
            label: 'Discount Front Counter Service Fee',
            value: -promoCodeValue,
        });
    }

    subscribeNonReservationFee(): void {
        this.cartBillService
            .getFeaturesAmountByAWB(this.paymentRequest.awb || '')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (response) => {
                    this.hasNonReservationFee = response.nonReservationFee;
                    this.isAwbPaidWithPromoCode = response.isAwbPaidWithPromoCode;
                },
            });
    }
}
