import { Injectable } from '@angular/core';
import { Cart } from '@cargos/sprintpay-models';
import { Observable, from, map, of, switchMap, take } from 'rxjs';
import { SecurityService } from 'src/app/services/utils/security.service';
import Swal from 'sweetalert2';
import { Facility } from '../models/payments/facility.model';
import { profileComponents } from '../utils/constants';
import { CartBillService } from './utils/cart/cart-service';

@Injectable({
    providedIn: 'root',
})
export class SummaryValidationService {
    // private cartHasExternalLookupRequest: BehaviorSubject<PaymentCart[]> = new BehaviorSubject<PaymentCart[]>([]);

    constructor(
        private _securityService: SecurityService,
        private cartBillService: CartBillService
    ) {}

    validateCartByPort(): Observable<boolean> {
        return this.addToCartValidate('paymentRequest').pipe(
            take(1),
            switchMap((response) => {
                if (!response.continue) {
                    return from(
                        Swal.fire({
                            html: '<h5>Hey, psst psst...</h5>' + '<p>' + response.message + '</p>',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                            reverseButtons: true,
                        })
                    ).pipe(
                        switchMap((result) => {
                            if (result.isConfirmed) {
                                return this.cartBillService.deleteAndGetCart().pipe(map(() => true));
                            }

                            return of(false);
                        })
                    );
                }

                return of(true);
            })
        );
    }

    addToCartValidate(chargeType: string, facility?: Facility): Observable<{ message?: string; continue: boolean }> {
        return of(this._securityService.verifyComponentsSecurity(profileComponents.cart)).pipe(
            switchMap((isCartAvailable: boolean) => {
                if (isCartAvailable) {
                    return this.cartBillService.getCartBill$().pipe(
                        take(1),
                        map((cartBill: Cart | null) => {
                            if (cartBill?.cartBillResponseDTOList?.length) {
                                if (chargeType === 'invoices') {
                                    const hasPayments = this.cartBillService.isTherePaymentsRequestsNoInvoices();
                                    if (hasPayments) {
                                        return {
                                            message: `You can't add open invoices while you have payment requests in the cart, Do you want to empty the cart and add an open invoice?`,
                                            continue: false,
                                        };
                                    }
                                    return {
                                        continue: true,
                                    };
                                }
                                if (chargeType === 'paymentRequest') {
                                    const hasInvoices = this.cartBillService.isThereInvoicesInCart();
                                    if (hasInvoices) {
                                        return {
                                            message: `You cannot add a payment request while you have open invoices in your cart. Do you want to empty the cart and add a new payment request?`,
                                            continue: false,
                                        };
                                    }

                                    const externalPayment = this.cartBillService.getPaymentRequestsByAwbLookupData();
                                    const currentPayment = facility ? facility.awbLookupData : null;
                                    const externalSource: string =
                                        externalPayment.length &&
                                        externalPayment[0].facility?.awbLookupData?.paymentSource
                                            ? externalPayment[0].facility.awbLookupData.paymentSource
                                            : '';
                                    const currentSource: string = currentPayment ? currentPayment.paymentSource : '';

                                    if (
                                        (currentPayment == null && externalPayment.length == 0) ||
                                        (currentPayment != null &&
                                            externalPayment.length > 0 &&
                                            externalSource === currentSource)
                                    ) {
                                        return {
                                            continue: true,
                                        };
                                    }
                                    return {
                                        message: `You can't add to cart while you have payments of another source, Do you want to empty the cart and add this?`,
                                        continue: false,
                                    };
                                }
                            }
                            return {
                                continue: true,
                            };
                        })
                    );
                }
                return of({
                    continue: true,
                });
            })
        );
    }
}
