import { Injectable } from '@angular/core';
import { TokenService } from '@cargos/sprintpay-services';
import { Observable, of } from 'rxjs';
import { BannerService } from '../standalone-components/banner/services/banner.service';
import { SummaryService } from './summary/summary.service';
import { PaymentFluxService } from './utils/payment-flux.service';

/**
 * Service that is responsible for cleaning the user information that is stored in the cache
 */
@Injectable({
    providedIn: 'root',
})
export class ResetInformationService {
    constructor(
        private summaryService: SummaryService,
        private paymentFluxService: PaymentFluxService,
        private tokenService: TokenService,
        private bannerService: BannerService
    ) {}

    resetUserInformation(): Observable<string> {
        this.resetPaymentMethod();
        this.resetTermsOfUse();
        this.resetPaymentFlux();
        this.resetAuthTokens();
        this.resetNotifications();
        return of('Complete');
    }

    private resetPaymentMethod(): void {
        this.summaryService.setPaymentMethodSelected(null);
        this.summaryService.clearGuestData();
    }

    private resetTermsOfUse(): void {
        this.summaryService.setTermsOfUseValid(null);
    }

    private resetPaymentFlux(): void {
        this.paymentFluxService.removeCurrentPayment();
        this.paymentFluxService.removeDynamicValues();
        this.paymentFluxService.removeDynamicFields();
    }

    private resetAuthTokens(): void {
        this.tokenService.resetAuthTokens();
    }

    private resetNotifications(): void {
        this.bannerService.setNotifications([]);
    }
}
