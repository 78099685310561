import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loader-dot-pulse',
    templateUrl: './loader-dot-pulse.component.html',
    styleUrls: ['./loader-dot-pulse.component.scss'],
})
export class LoaderDotPulseComponent {
    @Input() size: 'small' | 'default' = 'default';
    @Input() color: 'primary' | 'gray' = 'primary';

    constructor() {}
}
