import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ErrorMatcher } from 'src/app/utils/error-matcher';

@Component({
    selector: 'app-refund-request-choice',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatRadioModule, MatSelectModule],
    templateUrl: './refund-request-choice.component.html',
})
export class RefundRequestChoiceComponent {
    @Input() currentPayment: any;
    @Input() refundUrl: any;

    matcher: ErrorMatcher;

    constructor(private _matDialog: MatDialog) {
        this.matcher = new ErrorMatcher();
    }

    /**
     * @method refund()
     * @description
     */

    refund() {
        this.closeDialog();
        window.open(this.refundUrl, '_black');
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }
}
