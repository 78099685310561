import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentMethod, PaymentMethods } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { Balance } from 'src/app/utils/cart-types';

@Component({
    selector: 'app-sp-credit-widget',
    templateUrl: './sp-credit-widget.component.html',
})
export class SpCreditWidgetComponent implements OnInit, OnDestroy {
    public sPCredit: Balance = {};
    public sPCreditPercentage: number = 0;
    public isLoading: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private paymentMethodsService: PaymentMethodsService) {}

    ngOnInit(): void {
        this.subscribePaymentMethodsLoading();
        this.getCSCreditBalance();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribePaymentMethodsLoading(): void {
        this.paymentMethodsService
            .getPaymentMethodsInProcess$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isLoading) => {
                    this.isLoading = isLoading;
                },
            });
    }

    getCSCreditBalance(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.CARGO_CREDIT)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethod: PaymentMethod | undefined) => {
                    const balance = paymentMethod?.balance;
                    if (balance) {
                        this.sPCredit = {
                            availableCredit: balance?.avaliableCredit,
                            creditLimit: balance?.creditLimit,
                        };
                        this.sPCreditPercentage = Number(balance?.creditPercentage);
                    }
                },
            });
    }
}
