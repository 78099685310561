import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { Observable } from 'rxjs';
import { Echeck, PaymentMethodsLatest } from 'src/app/models/payments/payment-methods';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class PaymentMethodsAPIService {
    private paymentsUrl: string;

    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService
    ) {
        this.paymentsUrl = environment.uris.method.payments;
    }

    getPaymentMethodLatest(): Observable<PaymentMethodsLatest[]> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);
        return this.httpClient.get<PaymentMethodsLatest[]>(`${this.paymentsUrl}/paymentmethod/default`, { headers });
    }

    getEChecks(): Observable<Echeck[]> {
        return getEChecks() as unknown as Observable<Echeck[]>;
    }
}
