<!--START: NO INFORMATION -->
<section id="no_information" class="container-fluid">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center no_information--height">
        <div class="row">
            <div class="col-12">
                <img
                    class="mx-auto d-block"
                    src="assets/images/ui/no-information.svg"
                    alt="No information illustration"
                />
            </div>
            <div class="col-12">
                <ng-container *ngIf="!paymentType">
                    <h4 class="d-block text-center mt-4 mb-3 fs-2">You don't have any data!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'rejected'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Looking good so far!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'voided'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Looking good so far!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'pending'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Looking good so far!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'history'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Looking good so far!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'openInvoices'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Way to go!</h4>
                </ng-container>
                <ng-container *ngIf="paymentType === 'paidInvoices'">
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Don't fret!</h4>
                </ng-container>
                <ng-container
                    *ngIf="paymentType !== 'rejected' && paymentType !== 'voided' && paymentType !== 'pending' && paymentType !== 'history' && paymentType !== 'openInvoices' && paymentType !== 'paidInvoices'"
                >
                    <h4 class="d-block text-center mt-4 mb-3 fs-3">Looking good so far!</h4>
                </ng-container>
                <p class="d-block text-center mt-3 mb-4">
                    <ng-container *ngIf="!paymentType">
                        <span class="fs-6" *ngIf="error_message">{{error_message}}</span>
                        <span class="fs-6" *ngIf="!error_message">This data could change depend of the intention</span>
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'rejected'">
                        <span class="fs-6" *ngIf="!error_message"
                            >When a request is moved to rejected status it will display on this screen.</span
                        >
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'voided'">
                        <span class="fs-6" *ngIf="!error_message"
                            >When a request is moved to voided status it will display on this screen.</span
                        >
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'pending'">
                        <span class="fs-6" *ngIf="!error_message"
                            >When a request is moved to pending status it will display on this screen.</span
                        >
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'history'">
                        <span class="fs-6" *ngIf="!error_message">This data could change depend of the intention.</span>
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'openInvoices'">
                        <span class="fs-6" *ngIf="!error_message">You have no open invoices.</span>
                    </ng-container>
                    <ng-container *ngIf="paymentType === 'paidInvoices'">
                        <span class="fs-6" *ngIf="!error_message"
                            >When you have fully paid invoices they will appear on this screen.</span
                        >
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
</section>
<!--END: NO INFORMATION -->
