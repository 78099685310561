import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { IMaskModule } from 'angular-imask';
import { RefundReasonsList } from 'src/app/models/refunds/refunds';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { regex } from 'src/app/utils/constants';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { ProfileTypes } from 'src/app/utils/profile-types';
import { ImageByBrandComponent } from '../image-by-brand/image-by-brand.component';

@Component({
    selector: 'app-refund-request',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        ImageByBrandComponent,
        IMaskModule,
        MatIconModule,
        FormatPaymentReferencePipe,
    ],
    templateUrl: './refund-request.component.html',
})
export class RefundRequestComponent implements OnInit {
    @Input() currentPayment: any;
    @Input() vendorInvoiceNumber: string | undefined;
    @Output() refundPayment: EventEmitter<any> = new EventEmitter();

    public matcher: ErrorMatcher;
    public refundForm!: FormGroup;
    public refundReasons: Array<string>;
    private _amountPaid: any;
    public activeProfileView: string;
    public profileTypes = ProfileTypes;
    public currencyMask = {
        mask: 'num',
        blocks: {
            num: {
                mask: Number,
                thousandsSeparator: ',',
                scale: 2,
                signed: false,
                normalizeZeros: false,
                radix: '.',
                padFractionalZeros: true,
                expose: false,
            },
        },
    };

    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService
    ) {
        this.matcher = new ErrorMatcher();
        this.refundReasons = RefundReasonsList;
        this.activeProfileView = this._customerService.getActiveProfileView();
    }

    ngOnInit(): void {
        this._amountPaid = String(
            this.currentPayment?.amountPaid ? this.currentPayment?.amountPaid : this.currentPayment?.amount
        ).replace('$', '');
        this._setFromBuilder();
        this.amount.setValue(this._amountPaid);
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.refundForm = this._formBuilder.group({
            amount: ['', [Validators.required, CustomValidators.higherValueThanAmount(this._amountPaid)]],
            refundReason: ['', [Validators.required]],
            customerNotes: [''],
            notificationEmail: ['', [Validators.required, Validators.minLength(5), Validators.pattern(regex.email)]],
        });
    }

    /**
     * @method amount()
     * @description: Convenience getter for easy access to form fields
     */
    get amount(): FormGroup {
        return this.refundForm.get('amount') as FormGroup;
    }

    /**
     * @method refundReason()
     * @description: Convenience getter for easy access to form fields
     */
    get refundReason(): FormGroup {
        return this.refundForm.get('refundReason') as FormGroup;
    }

    /**
     * @method customerNotes()
     * @description: Convenience getter for easy access to form fields
     */
    get customerNotes(): FormControl<string> {
        return this.refundForm.get('customerNotes') as FormControl;
    }

    /**
     * @method notificationEmail()
     * @description: Convenience getter for easy access to form fields
     */
    get notificationEmail(): FormGroup {
        return this.refundForm.get('notificationEmail') as FormGroup;
    }

    /**
     * @method refund()
     * @description
     */

    refund() {
        this.refundPayment.emit({ ...this.refundForm.value, activeProfileView: this.activeProfileView });
        this.closeDialog();
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method resetCustomerNotes()
     * @description
     */
    resetCustomerNotes(event: MatSelectChange): void {
        this.customerNotes.setValue('');

        if (event.value === 'Other') {
            this.customerNotes.setValidators([Validators.maxLength(255), Validators.minLength(5), Validators.required]);
            this.customerNotes.markAsPristine();
            this.customerNotes.markAsUntouched();
        } else {
            this.customerNotes.clearValidators();
        }

        this.customerNotes.updateValueAndValidity();
    }
}
