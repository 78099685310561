import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadersModule } from '../loaders/loaders.module';

@Component({
    standalone: true,
    selector: 'app-image-loader',
    templateUrl: './image-loader.component.html',
    styleUrls: ['./image-loader.component.scss'],
    imports: [LoadersModule, CommonModule],
})
export class ImageLoaderComponent {
    public loading = true;
    public failed: boolean;

    @Input() src: string;
    @Input() title = '';
    @Input() alt = '';
    @Input() width = 'auto';
    @Input() height = 'auto';
    @Input() color = 'auto';

    constructor() {}

    onLoad(): void {
        this.loading = false;
        this.failed = false;
    }

    onError(): void {
        this.loading = false;
        this.failed = true;
    }
}
