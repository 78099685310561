<ng-container #authenticationFlux>
    <div class="d-flex justify-content-end p-3">
        <button class="close bg-transparent" matSuffix mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content id="authentication-flux px-0 px-md-4">
        <section class="w-100 p-0 pb-4 p-md-4 d-flex justify-content-center">
            <div class="row container justify-content-center">
                <div class="col-12">
                    <img class="mx-auto d-block mb-5" src="assets/images/sp-logo.svg" alt="Logo" />
                </div>
                <app-verification-method
                    [email]="email"
                    [module]="module"
                    [message]="messageAuthentication"
                    (eventOnVerification)="onSetVerificationMethod($event)"
                ></app-verification-method>

                <div class="row">
                    <button
                        (click)="openRequestedCode()"
                        type="submit"
                        mat-button
                        class="verifyButton text-uppercase btn__sprintpay btn__sprintpay--green"
                    >
                        send code
                    </button>
                </div>
            </div>
        </section>

        <mat-divider></mat-divider>
        <div class="d-flex justify-content-center mt-4 message">
            <p class="w-75 text-center">
                If you are still encountering issues, please contact Support at <b>{{cargosprintPhoneNumber}}</b> or
                send an email to
                <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
            </p>
        </div>
    </mat-dialog-content>
</ng-container>
<ng-template #twoFactorModal>
    <app-two-factor
        [module]="module"
        [enableChangeVerificationMethod]="!!phoneNumber"
        (accountCreated)="modalAccountVerified($event)"
    ></app-two-factor>
</ng-template>
