import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CompanyForm } from 'src/app/models/auth/signup.model';
import { Branch } from 'src/app/models/utils/company';
import { ErrorMatcher } from 'src/app/utils/error-matcher';

@Component({
    selector: 'app-company-form[companyForm]',
    templateUrl: './company-form.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatSelectModule, MatFormFieldModule],
})
export class CompanyFormComponent {
    matcher: ErrorMatcher;
    @Input() companyForm!: FormGroup<CompanyForm>;
    @Input() branchLocations?: Branch[];

    constructor() {
        this.matcher = new ErrorMatcher();
    }

    /**
     * @method companyName()
     * @description: Convenience getter for easy access to form fields
     */

    get companyName(): FormControl<string> {
        return this.companyForm.controls.companyName;
    }

    /**
     * @method branchLocation()
     * @description: Convenience getter for easy access to form fields
     */

    get branchLocation(): FormControl<string | null> {
        return this.companyForm.controls.branchLocation;
    }
}
