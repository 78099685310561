import { Component, OnInit } from '@angular/core';
import { PaymentMethods } from '@cargos/sprintpay-models';
import { PaymentTab } from 'src/app/utils/cart-types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { SecurityService } from '../../../../services/utils/security.service';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
})
export class PaymentMethodsComponent implements OnInit {
    public tabs: PaymentTab[] = [];
    public PaymentMethods = PaymentMethods;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private customerService: CustomerService,
        private securityService: SecurityService
    ) {
        this.breadcrumbService.set('@payment-methods', 'Payment methods');
        this.breadcrumbService.set('@payment-methods-list', 'Payment methods list');
    }

    ngOnInit(): void {
        const components = this.customerService.getCustomerProfile().components;

        if (
            components.some((type) => type.name === PaymentMethods.CREDIT_CARD) &&
            this.verifySecurity(PaymentMethods.CREDIT_CARD)
        ) {
            this.tabs.push({ type: PaymentMethods.CREDIT_CARD, label: 'Credit cards' });
        }

        if (
            components.some((type) => type.name === PaymentMethods.ECHECK) &&
            this.verifySecurity(PaymentMethods.ECHECK)
        ) {
            this.tabs.push({ type: PaymentMethods.ECHECK, label: 'Echeck' });
        }

        if (
            components.some((type) => type.name === PaymentMethods.CARGO_CREDIT) &&
            this.verifySecurity(PaymentMethods.CARGO_CREDIT)
        ) {
            this.tabs.push({ type: PaymentMethods.CARGO_CREDIT, label: 'SprintPay Credit' });
        }
    }

    /**
     * @method verifySecurity()
     * @param (paymentMethod: PaymentMethods)
     * @description Check payment method availability
     */

    private verifySecurity(paymentMethod: string): boolean {
        return this.securityService.verifyComponentsSecurity(paymentMethod);
    }
}
