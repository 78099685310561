import { Injectable } from '@angular/core';
import { distinctUntilChanged, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PayButtonService {
    private payButtonSubmitted: Subject<boolean> = new Subject<boolean>();

    /**
     * Updates the state to indicate whether the pay-button was submitted.
     * @param isSubmitted - A boolean indicating if the pay-button has been submitted.
     */
    setPayButtonSubmitted(isSubmitted: boolean): void {
        this.payButtonSubmitted.next(isSubmitted);
    }

    isPayButtonSubmitted(): Observable<boolean> {
        return this.payButtonSubmitted.asObservable().pipe(distinctUntilChanged());
    }
}
