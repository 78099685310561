import { ComponentType } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PhoneInputComponent } from '@cargos/sprintpay-ui';
import { PhoneFormGroupType } from '@cargos/sprintpay-ui/lib/modules/phone/phone-input/models/phone-input-form';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, Subject, finalize, of, switchMap, take } from 'rxjs';
import {
    ModuleMFAEnum,
    ResponseMFA,
    TypeModuleMFA,
} from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { HandlerVerificationService } from 'src/app/services/handler-verification.service';
import { CustomerAPIService } from 'src/app/services/requests/customer-api.service';
import { SignUpAPIService } from 'src/app/services/requests/signup-api.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { blackListedCountryCodes } from 'src/app/utils/constants';
import * as Utils from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ErrorMatcher } from '../../../../../utils/error-matcher';
import { FormSignupService } from '../../services/form-signup.service';

@Component({
    selector: 'app-step-one',
    templateUrl: './step-one.component.html',
})
export class StepOneComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribe$: Subject<void>;
    public parentForm!: FormGroup;
    public matcher: ErrorMatcher;
    public module: TypeModuleMFA = ModuleMFAEnum.EMAIL_VALIDATION;
    public messageAuthentication: string =
        'This is an extra layer of security that helps protect your account by requiring a form of verification before granting access.';
    private readonly _authentication: string;
    public phoneForm!: PhoneFormGroupType;
    public blackListedCountryCodes = blackListedCountryCodes;

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<unknown>;
    @ViewChild(PhoneInputComponent) PhoneInputComponent: PhoneInputComponent;
    @Input() stepper!: MatStepper;
    @Input() facility!: string;

    constructor(
        private _controlContainer: ControlContainer,
        private _router: Router,
        private _formSignupService: FormSignupService,
        private _errorHandlerService: ErrorHandlerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _signUpAPIService: SignUpAPIService,
        private _handlerVerificationService: HandlerVerificationService,
        private _matDialog: MatDialog,
        private summaryService: SummaryService,
        private _customerApiService: CustomerAPIService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.matcher = new ErrorMatcher();
        this._authentication = environment.uris.method.authentication;
    }

    ngOnInit(): void {
        this.parentForm = this._controlContainer.control as FormGroup;
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        this.phoneForm = this.PhoneInputComponent.phoneForm;
    }

    validateEmail(): void {
        if ((this.phoneForm.valid && this.email?.valid) || this.email?.disabled) {
            this._ngxSpinnerService.show();
            this._signUpAPIService
                .validateEmail(this.email.value)
                .pipe(
                    take(1),
                    finalize(() => this._ngxSpinnerService.hide())
                )
                .subscribe({
                    next: () => {
                        this.openFactorAuthentication(ModuleMFAEnum.EMAIL_VALIDATION);
                    },
                    error: (err: HttpErrorResponse) => this.onValidateEmailError(err),
                });
        } else {
            this.phoneForm.markAllAsTouched();
            this.parentForm.markAllAsTouched();
        }
    }

    onValidateEmailError(error: HttpErrorResponse): Observable<never> {
        this._ngxSpinnerService.hide();
        const verificationState = this._handlerVerificationService.getVerificationState(error?.error);

        let template;
        if (error?.error?.error?.body) {
            template = error.error.error.body;
        } else {
            template =
                error?.error?.status == 422
                    ? this._errorHandlerService.errorMsg(error.error.data)
                    : this._errorHandlerService.errorMsg(error);
        }

        if (verificationState.requestMFA) {
            this._handlerVerificationService.setActivationUuid(verificationState.activationUuid || '');

            this.openFactorAuthentication(ModuleMFAEnum.REGISTRATION);
            return EMPTY;
        }

        if (verificationState.requestPassword) {
            this.summaryService.setGuestPaymentInformation({ personalInformation: { email: this.email?.value } });
            this.navigateToSignUp();
            return EMPTY;
        }

        if (error?.error?.error || verificationState.redirectSignin)
            Swal.fire({
                title: 'Oops...',
                html: `<div>${template}</div>
                        <div class="mat-caption mt-3">Proceed to sign in or you may request a new password.</div>`,
                icon: 'error',
                allowOutsideClick: false,
                cancelButtonText: 'SIGN IN',
                confirmButtonText: 'FORGOT PASSWORD',
                showCancelButton: true,
                showConfirmButton: true,
                showCloseButton: true,
                customClass: {
                    cancelButton: 'order-1 swal2-btn-sprint-pay',
                    confirmButton: 'order-2',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this._router.navigate(['/password/forgot-password']);
                }
                if (result?.dismiss == Swal.DismissReason.cancel) {
                    this.redirectToLogin();
                }
            });

        return EMPTY;
    }

    savePersonalInformation(): void {
        this._formSignupService.setPersonalInformation(this.parentForm.getRawValue());
    }

    /**
     * @method navigateToSignUp()
     * @description Redirect customer to SignUp
     */

    navigateToSignUp(): void {
        this._router.navigate(['signup-guest']);
    }

    openFactorAuthentication(module: TypeModuleMFA): void {
        this.module = module;

        this._matDialog.open(this.authenticationFlux as ComponentType<unknown>, {
            id: 'authenticationFlux',
            disableClose: true,
            width: '55em',
            data: {
                email: this.email?.value,
                activationUuid: this._handlerVerificationService.instant_activationUuid,
                accountVerifiedHandler: (event: ResponseMFA) =>
                    this.module === 'REGISTRATION'
                        ? this._accountVerifiedHandler(event)
                        : this.validateEmailExistenceSuccess(event),
            },
        });
    }

    private _accountVerifiedHandler(response: ResponseMFA): void {
        if (response?.isVerified) {
            Swal.fire({
                html: `<div class="row" style="padding: 0 2rem;">
            <!--START: Sprintpay or facility logo -->
            <div class="col-12 accountCreated">
                <figure class="facility__logo mt-5 mb-5">
                    <img class="d-block mx-auto my-0 h-100" src="./assets/images/sp-logo.svg" alt="{{facility}} logo" />
                </figure>
                <figure class="account__logo my-4">
                    <img
                        class="d-block mx-auto my-0 h-100"
                        src="./assets/images/sprintpay.svg"
                        alt="Account illustration logo"
                    />
                </figure>
                <p class="d-block w-100 text-center title">Your account has been created successfully</p>
                <p class="text-center info">You can now start using our payment platform.</p>
            </div>
            <!--END: Sprintpay or facility logo -->
        </div>
            `,
                showConfirmButton: true,
                width: '40em',
                confirmButtonText: 'GO TO SIGN IN',
                customClass: {
                    confirmButton: 'confirmButton',
                },
                allowOutsideClick: false,
            }).then(() => {
                this.redirectToLogin();
            });
        }
    }

    redirectToLogin(): void {
        switch (this.facility) {
            case 'alliance':
                window.location.replace(environment.projects.alliance + `/login`);
                break;
            default:
                this._router.navigate(['/login']);
                break;
        }
    }

    validateEmailExistenceSuccess(event): void {
        if (!event.isVerified) return;

        const emailDomain = Utils.getEmailDomain(this.email?.value || '');

        this._customerApiService
            .emailDomainExists(emailDomain)
            .pipe(
                take(1),
                switchMap((exists) => {
                    this._formSignupService.setEmailDomainExist(exists);
                    return exists ? this._customerApiService.getBranchesByEmailDomain(emailDomain) : of([]);
                })
            )
            .subscribe({
                next: (branches) => {
                    this.countryCode?.setValue(this.PhoneInputComponent.getCountryCode());
                    this.phone?.setValue(this.phoneInput?.value || '');
                    this.stepper?.next();
                    this._formSignupService.setEmail(this.email?.value || '');
                    this._formSignupService.setPhoneNumber(this.phone?.value || '');
                    this._formSignupService.setBrachLocation(branches || []);
                    this._formSignupService.setCountryCode(this.PhoneInputComponent.getCountryCode());
                    this.savePersonalInformation();
                },
                error: (err: HttpErrorResponse) => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(err.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    get phoneInput(): AbstractControl<string> | null {
        return this.phoneForm.get('phone');
    }

    get countryCode(): AbstractControl<string> | null {
        return this.parentForm.get('countryCode');
    }

    get phone(): AbstractControl<string> | null {
        return this.parentForm.get('phone');
    }

    get email(): AbstractControl<string> | null {
        return this.parentForm.get('email');
    }
}
