<div id="standard_flux" class="mt-4">
    <div id="standard_flux--container">
        <div class="mt-4 d-block">
            <h6 class="mat-subheading">Payment Details:</h6>
            <form [formGroup]="form">
                <div class="mb-sm-5">
                    <mat-form-field appearance="outline" class="input_width mt-2">
                        <mat-label>Search by {{labelForHouse}}</mat-label>
                        <input
                            formControlName="search"
                            mask="0000000-00"
                            [showMaskTyped]="true"
                            [dropSpecialCharacters]="false"
                            matInput
                            id="search"
                            placeholder="Enter HBL"
                            autocomplete="off"
                            (keydown.enter)="$event.preventDefault(); searchForHBL()"
                        />
                        <mat-icon class="me-2" matPrefix>search</mat-icon>
                        <button
                            [disabled]="!getSearchInput?.value || !form.valid"
                            mat-button
                            matSuffix
                            class="btn__sprintpay--link search_btn"
                            (click)="searchForHBL()"
                        >
                            Search
                        </button>
                    </mat-form-field>
                    <ng-container *ngIf="noInformation && !isLoading">
                        <div class="col-12 mt-5">
                            <img
                                class="mx-auto d-block"
                                src="assets/images/ui/no-information.svg"
                                alt="No information illustration"
                            />
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!noInformation && !isLoading">
                        <div class="my-3">
                            <app-standard-reference></app-standard-reference>
                            <div class="py-2">
                                <app-payment-detail-flux></app-payment-detail-flux>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="">
                                <!--START: PICKUP DATE -->
                                <div class="form-group m-0">
                                    <mat-form-field class="d-block" appearance="outline">
                                        <mat-label>Pickup date</mat-label>
                                        <input
                                            matInput
                                            #pickupDateInputRef
                                            formControlName="pickupDate"
                                            [min]="minDateAllowed"
                                            [matDatepicker]="pickupDate"
                                            (dateChange)="setPickupDate()"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="pickupDate"></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #pickupDate
                                            (closed)="pickupDateInputRef.blur()"
                                        ></mat-datepicker>
                                        <mat-error *ngIf="form.hasError('required')">
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--END: PICKUP DATE -->
                            </div>
                        </div>
                        <app-house-charges [dataSource]="dataSourceHBL"></app-house-charges>
                    </ng-container>
                </div>
                <ng-container *ngIf="isLoading">
                    <div class="d-flex justify-content-center">
                        <mat-spinner></mat-spinner>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>
</div>
<!--START: STEPPER NEXT AND BACK BUTTON -->
<ng-container *ngIf="totalAmountSelected !== 0 || submittedItems">
    <div class="row stickyBottom">
        <div class="col-sm-12 col-md-4 mb-3 mb-lg-0">
            <div class="d-flex justify-content-start w-100">
                <button
                    matStepperPrevious
                    mat-button
                    type="button"
                    (click)="backStep()"
                    class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                >
                    Back
                </button>
            </div>
        </div>
        <div class="col-sm-12 col-md-8">
            <div class="d-flex justify-content-end">
                <div class="d-flex justify-content-end w-100 continue-btn-holder">
                    <label *ngIf="totalAmountSelected !== 0" class="px-3 d-flex align-items-center fw-bold"
                        >Total Selected: {{totalAmountSelected | currency}}</label
                    >
                    <button
                        mat-button
                        type="button"
                        (mousedown)="checkout()"
                        class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                    >
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!--END: STEPPER NEXT AND BACK BUTTON -->
