import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { ErrorMatcher } from '../../../utils/error-matcher';

@Component({
    selector: 'app-input',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
    dynamic_minlength!: number;
    dynamic_maxlength!: number;
    dynamic_pattern!: string;
    dynamic_required!: boolean;
    showTooltip: boolean;
    matcher: ErrorMatcher = new ErrorMatcher();

    @Input() field!: DynamicFieldDTO;
    @Input() id!: number;
    @Input() dynamicFormGroup!: FormGroup;

    constructor(private _paymentFluxService: PaymentFluxService) {
        this.showTooltip = false;
        this.dynamic_minlength = 0;
        this.dynamic_maxlength = 524288;
        this.dynamic_pattern = '';
    }

    ngOnInit(): void {
        const dynamicValues = this._paymentFluxService.getDynamicValues();
        if (dynamicValues) {
            Object.keys(dynamicValues).forEach((key: string) => {
                if (key === this.field?.id?.toString()) {
                    this.dynamicFormGroup.controls[this.field.name].setValue(dynamicValues[key]);
                }
            });
        }
        this._checkValidations();
    }

    /**
     * @method _checkValidations()
     * @description Review and set the validators for the field
     */

    private _checkValidations(): void {
        if (this.field?.inputValidations?.['minlength']) {
            const minLength = <number>this.field.inputValidations['minlength'].value || 0;
            this.dynamic_minlength = minLength;
            this.addValidators(this.field.name, Validators.minLength(minLength));
        }
        if (this.field?.inputValidations?.['maxlength']) {
            const maxLength = <number>this.field.inputValidations['maxlength'].value || 524288;
            this.dynamic_maxlength = maxLength;
            this.addValidators(this.field.name, Validators.maxLength(maxLength));
        }
        if (this.field?.inputValidations?.['pattern']) {
            const pattern = (this.field.inputValidations['pattern'].value as string) || '';
            this.dynamic_pattern = pattern;
            this.addValidators(this.field.name, Validators.pattern(pattern));
        }
        this.dynamic_required = this.field.inputValidations
            ? this.field.inputValidations['required']
                ? (this.field.inputValidations['required'].value as boolean)
                : false
            : false;
        this.dynamicFormGroup.get(this.field.name)!.updateValueAndValidity();
    }

    addValidators(fieldName: string, validators: ValidatorFn | ValidatorFn[]): void {
        this.dynamicFormGroup.get(fieldName)!.addValidators(validators);
    }
}
