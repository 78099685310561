import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { forwarderUserSavePayload } from 'src/app/models/customer/forwarder-admin-user.model';
import { NotificationsType } from 'src/app/models/notifications/notifications';
import { Filters } from 'src/app/models/ui/table.model';
import { CompanyDetails } from 'src/app/models/utils/company';
import { environment } from 'src/environments/environment';
import { RestService } from '../rest.service';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class CustomerAPIService {
    private _customersUrl: string;
    private _paymentsUrl: string;
    private _authUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService,
        private _restService: RestService
    ) {
        this._customersUrl = environment.uris.method.customer;
        this._paymentsUrl = environment.uris.method.payments;
        this._authUrl = environment.uris.method.authentication;
    }

    getCustomerNotificationsRequest(): Observable<NotificationsType[]> {
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<NotificationsType[]>(this._paymentsUrl + '/customer/notifications', { headers });
    }

    getCustomerTermsOfUseRequest(
        customerId: number,
        ip: string,
        accessToken: string | null,
        utcDate: string
    ): Observable<NotificationsType[]> {
        return this._restService.post(this._authUrl + '/addCustomerTermsOfUse?dateTermsAcceptance=' + utcDate, {
            data: { customer: customerId, ip, token: accessToken },
        });
    }

    getCustomersAccounts(filters: Filters): Observable<any> {
        const url = this._customersUrl + '/customer/accounts' + (filters.searchTerms ? '/search' : '');
        let headers: HttpHeaders = new HttpHeaders();
        let params: HttpParams = new HttpParams();

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get(url, { headers, params });
    }

    getCustomer(id: number): Observable<any> {
        const url = this._customersUrl + '/customer/accounts/' + id;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get(url, { headers });
    }

    saveUser(body: forwarderUserSavePayload): Observable<unknown> {
        const url = this._customersUrl + '/customer/accounts';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        const sanitizedString = Number(body.invoiceCheckLimit?.toString().replace(/,/g, ''));
        if (sanitizedString) {
            body = { ...body, invoiceCheckLimit: sanitizedString };
        } else {
            delete body.invoiceCheckLimit;
        }

        return this._httpClient.post(url, body, { headers });
    }

    updateUser(id: number, body: unknown): Observable<unknown> {
        const url = this._customersUrl + '/customer/accounts/' + id + '/update';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, body, { headers });
    }

    getAllBranches(filters: Filters = {}): Observable<Pagination<CompanyDetails>> {
        const url = `${this._customersUrl}/account/branches/all`;
        let headers = new HttpHeaders();
        let params: HttpParams = new HttpParams();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this._httpClient.get<Pagination<CompanyDetails>>(url, { headers, params });
    }

    getBranches(): Observable<CompanyDetails[]> {
        const url = this._customersUrl + '/account/branches';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<CompanyDetails[]>(url, { headers });
    }

    getRoles(): Observable<any[]> {
        const url = this._customersUrl + '/roles';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<any[]>(url, { headers });
    }

    searchBranch(filters: Filters): Observable<Pagination<CompanyDetails>> {
        const url = this._customersUrl + '/account/branches/search';
        let headers: HttpHeaders = new HttpHeaders();
        let params: HttpParams = new HttpParams();

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Pagination<CompanyDetails>>(url, { headers, params });
    }

    getParentBranch(): Observable<CompanyDetails> {
        const url = this._customersUrl + '/account/branches/root';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<CompanyDetails>(url, { headers });
    }

    updateCustomer(emails: string): Observable<{ authorizationToken: string }> {
        const url = `${this._authUrl}/updateCustomer`;
        let headers: HttpHeaders = new HttpHeaders();
        let body = {
            isDebitNotificationEnabled: true,
            debitNotificationEmails: emails,
        };

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post<{ authorizationToken: string }>(url, body, { headers });
    }

    updateCustomersNotificationEmail(
        emails: string,
        customerAccountId: number
    ): Observable<{ authorizationToken: string }> {
        const url = `${this._customersUrl}/account/branches/${customerAccountId}`;
        let headers: HttpHeaders = new HttpHeaders();
        let body = {
            isDebitNotificationEnabled: true,
            debitNotificationEmails: emails,
        };

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post<{ authorizationToken: string }>(url, body, { headers });
    }

    getDebitNotificationEmail(customerId: string, activeProfileView: string): Observable<string[]> {
        const url = `${this._customersUrl}/customer/${customerId}/debitNotificationEmail?activeProfileView=${activeProfileView}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<string[]>(url, { headers });
    }

    getBranchesByEmailDomain(emailDomain: string): Observable<CompanyDetails[]> {
        const url = `${this._customersUrl}/account/branches/signup?emailDomain=${emailDomain}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<CompanyDetails[]>(url, { headers });
    }

    emailDomainExists(emailDomain: string): Observable<boolean> {
        const url = `${this._customersUrl}/account/branches/exists?emailDomain=${emailDomain}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<boolean>(url, { headers });
    }
}
