import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ImageLoaderComponent } from '../image-loader/image-loader.component';

@Component({
    standalone: true,
    selector: 'app-card-type',
    templateUrl: './card-type.component.html',
    styleUrls: ['./card-type.component.scss'],
    imports: [CommonModule, ImageLoaderComponent],
})
export class CardTypeComponent {
    @Input() cardType?: string;
    @Input() width = '3.6875em';
    @Input() height = 'auto';

    get logo(): string {
        return this.cardType?.toLowerCase() || 'generic';
    }
}
