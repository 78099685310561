import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    Cart,
    Exception,
    FlowType,
    Intent,
    PayloadPayPal,
    PaymentMethods,
    PaymentMethodsType,
    PaymentRequest,
    PaymentResponse,
    PaymentToken,
    SaleStatus,
} from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, combineLatest, map, of, switchMap, take, takeUntil } from 'rxjs';
import { CartBillService, InitialConfigService, PaymentMethodsService } from 'src/app/services';
import { RequestService } from 'src/app/services/requests.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { Currency } from 'src/app/utils/cart-types';
import Swal from 'sweetalert2';
import { CartPayService } from '../../services/cart-pay.service';
import { CartPayResponse } from '../../services/models/types';
import { PaymentConfirmationService } from '../../services/payment-confirmation.service';
import { PayButtonService } from '../pay-button/services/pay-button.service';

@Component({
    selector: 'app-paypal-button',
    templateUrl: './paypal-button.component.html',
    styleUrl: './paypal-button.component.scss',
})
export class PaypalButtonComponent implements OnInit, OnDestroy {
    public isGuest = false;
    public isTermsOfUseValid = false;
    private unsubscribe$ = new Subject<void>();
    private paypalButtonActions: { disable?: () => void; enable?: () => void } | null = null;
    private cartBill: Cart | null = null;

    constructor(
        private userSessionService: UserSessionService,
        private summaryService: SummaryService,
        private paymentMethodsService: PaymentMethodsService,
        private payButtonService: PayButtonService,
        private cartPayService: CartPayService,
        private cartBillService: CartBillService,
        private ngxSpinnerService: NgxSpinnerService,
        private paymentConfirmationService: PaymentConfirmationService,
        private tokenService: TokenService,
        private requestService: RequestService,
        private initialConfigService: InitialConfigService
    ) {}

    ngOnInit(): void {
        this.subscribeToCurrentCartBill();
        this.subscribeTermsOfUse();
        this.subscribeToUserAuthenticated();
        this.connectWithPaypal();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeToUserAuthenticated(): void {
        this.userSessionService
            .isAuthenticated$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isAuthenticated: boolean) => {
                this.isGuest = !isAuthenticated;
            });
    }

    subscribeTermsOfUse(): void {
        this.summaryService
            .isTermsOfUseValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (termsOfUseValid: boolean) => {
                    this.isTermsOfUseValid = termsOfUseValid;
                    if (termsOfUseValid) {
                        this.paypalButtonActions?.enable?.();
                    }
                },
            });
    }

    private connectWithPaypal(): void {
        this.paymentMethodsService
            .connectWithPaypal()
            .pipe(take(1))
            .subscribe({
                next: (paypalCheckoutInstance) => {
                    window.paypal
                        .Buttons({
                            fundingSource: PaymentMethods.PAYPAL,
                            onInit: (_, actions) => {
                                this.paypalButtonActions = actions;
                                if (!this.isTermsOfUseValid) {
                                    this.paypalButtonActions?.disable?.();
                                }
                            },
                            createOrder: () =>
                                paypalCheckoutInstance.createPayment({
                                    flow: FlowType.Checkout,
                                    amount: this.cartBill?.total || 0,
                                    currency: Currency.USD,
                                    intent: Intent.Capture,
                                    enableShippingAddress: true,
                                }),
                            onApprove: (data) =>
                                paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
                                    this.payByPaypal(payload);
                                    return payload;
                                }),
                        })
                        .render('#paypal-button');
                },
                error: (error: Exception | unknown) => {
                    Swal.fire({
                        title: error instanceof Exception ? error.title : 'Oops...',
                        text: error instanceof Exception ? error.description : 'Something went wrong',
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    private payByPaypal(payload: PayloadPayPal): void {
        this.payButtonService.setPayButtonSubmitted(true);
        this.ngxSpinnerService.show();
        const isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();

        this.getPaymentsRequests$()
            .pipe(
                take(1),
                switchMap((paymentRequests: PaymentRequest[]) => {
                    return this.cartPayService.pay(paymentRequests, payload).pipe(
                        take(1),
                        switchMap((payResults: PaymentResponse[]) => {
                            const successfulPayments = payResults.filter(
                                (payResult: PaymentResponse) => payResult.saleStatus === SaleStatus.SALE_SUCCESS
                            );
                            if (successfulPayments.length) {
                                // ! Test this feature, to validate if the cart is updated afer pay - Nancy
                                return this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(
                                    take(1),
                                    map(() => this.getCartResponses(payResults))
                                );
                            }

                            return of(this.getCartResponses(payResults));
                        })
                    );
                })
            )
            .subscribe({
                next: (cartPayResponses: CartPayResponse[]) => {
                    this.ngxSpinnerService.hide();
                    const successfulPayments = cartPayResponses.filter(
                        (payResult) => payResult.saleStatus === SaleStatus.SALE_SUCCESS
                    );

                    if (successfulPayments.length) {
                        const customerInformation = payload.details;
                        this.summaryService.setGuestPaymentInformation({
                            personalInformation: {
                                firstName: customerInformation?.firstName || '',
                                lastName: customerInformation?.lastName || '',
                                email: customerInformation?.email || '',
                                phone: customerInformation?.phone || '',
                            },
                            addressInformation: {
                                street: customerInformation?.shippingAddress?.line1 || '',
                                street2: '',
                                state: customerInformation?.shippingAddress?.state || '',
                                city: customerInformation?.shippingAddress?.city || '',
                                country: customerInformation?.shippingAddress?.countryCode || '',
                                zipCode: customerInformation?.shippingAddress?.postalCode || '',
                            },
                        });

                        this.paymentConfirmationService.setPaymentResponse(cartPayResponses);
                    } else {
                        Swal.fire({
                            title: 'Payment Error',
                            html: '<p>Something went wrong on our side. Please try again shortly to complete your payment.</p>',
                            icon: 'error',
                            customClass: {
                                htmlContainer: 'swal-container',
                            },
                            allowOutsideClick: false,
                            confirmButtonText: 'GOT IT',
                        });
                    }
                },
            });
    }

    private getCartResponses(payResults: PaymentResponse[]): CartPayResponse[] {
        const isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();

        return <CartPayResponse[]>payResults.map((payResult: PaymentResponse) => ({
            saleStatus: payResult.saleStatus || SaleStatus.SALE_ERROR,
            paymentMethod: <PaymentMethodsType>payResult.paymentMethod || undefined,
            totalPayments: payResult.totalPayments,
            completedPayments: payResult.completedPayments || [],
            notificationEmail: payResult.notificationEmail,
            isThereInvoicesInCart,
            transactionId: payResult.transactionId || undefined,
        }));
    }

    subscribeToCurrentCartBill(): void {
        this.cartBillService
            .getCartBill$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((cartBill: Cart | null) => {
                this.cartBill = cartBill;
            });
    }

    // TODO: This logic is duplicated in the paypal button - low
    getPaymentsRequests$(): Observable<PaymentRequest[]> {
        return combineLatest([this.getPaymentRequestBelowThreshold(), this.getPaymentRequestAboveThreshold()]).pipe(
            map(([paymentRequestBelowThreshold, paymentRequestAboveThreshold]) => {
                return <PaymentRequest[]>(
                    [paymentRequestBelowThreshold, paymentRequestAboveThreshold].filter((request) => request !== null)
                );
            })
        );
    }

    // TODO: Verify how customerReference is used un beta - high
    private getPaymentRequestBelowThreshold(): Observable<PaymentRequest> {
        return this.summaryService.getPaymentTokenOfPaymentMethodSelected$(false).pipe(
            map((paymentToken: PaymentToken | null) => {
                const payments = this.cartBillService.getCartPaymentRequests(false);
                const clientCart = this.requestService.updateRequestBeforePay(payments);

                return {
                    clientCart,
                    paymentMethod: this.summaryService.instant_payment_method?.method,
                    paymentToken,
                    variableTerms: true,
                } as PaymentRequest;
            })
        );
    }

    // TODO: Verify how customerReference is used un beta - high
    private getPaymentRequestAboveThreshold(): Observable<PaymentRequest | null> {
        return this.cartBillService.isCartAboveThreshold$().pipe(
            switchMap((isCartAboveThreshold: boolean) => {
                if (!isCartAboveThreshold) {
                    return of(null);
                }

                return this.summaryService.getPaymentTokenOfPaymentMethodSelected$(true).pipe(
                    map((paymentToken: PaymentToken | null) => {
                        const payments = this.cartBillService.getCartPaymentRequests(true);
                        const clientCart = this.requestService.updateRequestBeforePay(payments);

                        return {
                            clientCart,
                            paymentMethod: this.summaryService.instant_payment_method_above_threshold?.method,
                            paymentToken,
                            variableTerms: true,
                        } as PaymentRequest;
                    })
                );
            })
        );
    }
}
