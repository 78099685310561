import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ModuleMFAEnum } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { ErrorMatcher } from '../../../../../utils/error-matcher';
import { FormSignupService } from '../../services/form-signup.service';

export interface VerificationMethodType {
    type: string;
    value: string;
}
@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html',
})
export class StepThreeComponent implements OnInit, OnDestroy {
    public ModuleMFAEnum = ModuleMFAEnum;
    public matcher: ErrorMatcher;
    public email: string = '';
    public phoneNumber: string = '';
    public verificationMethod: VerificationMethodType = {
        type: '',
        value: '',
    };
    public password: string = '';
    public termsOfUse: boolean = false;
    private _unsubscribe$: Subject<void>;
    public messageAuthentication =
        'This is an extra layer of security that helps protect your account by requiring a form of verification before granting access. It adds an additional step to the usual username and password login process.';

    @Output() eventOnSubmit = new EventEmitter<any>();
    @Input() notCompleted: boolean = false;

    constructor(private _formSignupService: FormSignupService) {
        this.matcher = new ErrorMatcher();
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeEmail();
        this.subscribePhoneNumber();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    saveInformation(): void {
        this._formSignupService.setSecurityInformation({ password: this.password, confirmPassword: this.password });
        this.eventOnSubmit.emit(this.verificationMethod);
    }

    onSetPassword(password: string): void {
        this.password = password;
    }

    onSetTermsOfUse(termsOfUse: boolean): void {
        this.termsOfUse = termsOfUse;
    }

    onSetVerificationMethod(method: VerificationMethodType): void {
        this.verificationMethod = method;
    }

    subscribeEmail(): void {
        this._formSignupService
            .getEmail()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (email: string) => {
                    this.email = email;
                },
            });
    }

    subscribePhoneNumber(): void {
        this._formSignupService
            .getPhoneNumber()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (phone: string) => (this.phoneNumber = phone),
            });
    }
}
