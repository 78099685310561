import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, ComponentPaymentMethodName, PaymentCart } from '@cargos/sprintpay-models';
import { CartService } from '@cargos/sprintpay-services';
import { Observable } from 'rxjs';
import { PayInvoicesPayload } from 'src/app/models/payments/payment-methods';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class CartAPIService {
    private cartUrl: string;
    constructor(
        private httpClient: HttpClient,
        private cartService: CartService,
        private tokenService: TokenService
    ) {
        this.cartUrl = environment.uris.method.cart;
    }

    deleteCart(): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.delete(this.cartUrl + '/deleteCart', { headers });
    }

    getCartBill(
        paymentMethod?: ComponentPaymentMethodName | undefined,
        thresholdPaymentMethod?: ComponentPaymentMethodName | undefined,
        additionalFee?: number
    ): Observable<Cart> {
        return this.cartService.getCartBillV2(paymentMethod, thresholdPaymentMethod, additionalFee);
    }

    buildEndPoint(uri: string, params: { [key: string]: string | number | boolean | null | undefined }): string {
        let finalParams: string = '';
        for (let key in params) {
            if (params[key] != null) {
                if (finalParams != '') {
                    finalParams += '&';
                }
                finalParams += key + '=' + encodeURIComponent(params[key] as string | number | boolean);
            }
        }
        return finalParams ? `${uri}?${finalParams}` : uri;
    }

    payOpenInvoices(
        paymentMetaData: { activeProfileView: string; openInvoiceIds: string[] },
        paymentToken: PayInvoicesPayload
    ): Observable<any> {
        const url = this.cartUrl + '/pay';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post(url, { paymentMetaData, paymentToken }, { headers });
    }

    deletePaymentRequestById(id: number): Observable<PaymentCart[]> {
        return this.cartService.removePaymentRequest(id);
    }

    deletePaymentRequests(body: number[]): Observable<PaymentCart[]> {
        const url = this.cartUrl + '/payment-requests';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.delete<PaymentCart[]>(url, { body });
    }
}
