import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorInputFieldComponent } from '@cargos/sprintpay-models';

@Component({
    standalone: true,
    selector: 'app-field-error',
    templateUrl: './field-error.component.html',
    styleUrls: ['./field-error.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    imports: [CommonModule, MatFormFieldModule],
})
export class InvalidFieldComponent {
    public messageConfig: ErrorInputFieldComponent[];

    constructor(private parent: FormGroupDirective) {}

    @Input() field: string;
    @Input()
    set messageList(messageList: ErrorInputFieldComponent[] | ErrorInputFieldComponent) {
        this.messageConfig = Array.isArray(messageList) ? messageList : [messageList];
    }

    get input(): AbstractControl | null {
        return this.parent.form?.get(this.field);
    }

    get errorVisible(): boolean {
        if (!this.input?.errors) {
            return false;
        }

        return this.input.touched || this.input.dirty;
    }
}
