/**
 * Calculates the available credit and its percentage relative to the credit limit.
 *
 * @param creditAvailable - The amount of credit currently available.
 * @param creditLimit - The maximum credit limit.
 * @returns A tuple containing the available credit and the credit percentage.
 */
export const calculateSprintpayCredit = (creditAvailable: number = 0, creditLimit: number = 0): [number, number] => {
    const availableCredit =
        creditAvailable <= 0 || creditLimit <= 0 ? 0 : creditAvailable > creditLimit ? creditLimit : creditAvailable;

    const creditPercentage =
        availableCredit === 0 || creditLimit <= 0 ? 0 : Math.round(((availableCredit * 100) / creditLimit) * 10) / 10;

    return [availableCredit, creditPercentage];
};
