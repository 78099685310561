import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Facility } from '../models/payments/facility.model';
import { DynamicFieldByHouse } from '../models/utils/dynamic-fields-by-house';
import { FacilityAPIService } from './requests/facility-api.service';

@Injectable({
    providedIn: 'root',
})
export class FacilityService {
    constructor(private _facilityApiService: FacilityAPIService) {}

    /**
     * @method setFromBuilder()
     * @param (facility: Facility)
     * @description
     */

    facilityValidation(facility: Facility): Facility {
        facility.isStGeorge = !!facility.paidTo && facility.paidTo.toLowerCase().includes('st. george');
        facility.isWFS = !!facility.paidTo && facility.paidTo.toLowerCase().includes('worldwide flight services');
        return facility;
    }

    /**
     * @method prefixValidation()
     * @param (facility: Facility)
     * @description
     */

    prefixValidation(facility: Facility): string {
        const arrayPrefix = facility.prefixes ? facility.prefixes.split(',') : '';
        return arrayPrefix
            ? arrayPrefix.map((element: any) => {
                  return this._cleanData(element);
              })
            : '';
    }

    /**
     * @method convertUrl()
     * @param (data: any)
     * @description
     */

    convertUrl(data: any): string {
        if (data.includes('https://')) {
            const arrayData = data.split(' ');
            for (let index = 0; index < arrayData.length; index++) {
                let element = arrayData[index];
                if (element.startsWith('https://') && arrayData[index - 1].toLowerCase().includes('here')) {
                    let a = `<a href = '${element}' target = '_NEW'>`;
                    arrayData.splice(index - 2, 0, a);
                    arrayData.splice(index + 1, 1);
                    arrayData.splice(index + 1, 0, '</a>');
                }
            }
            return arrayData.join(' ');
        }
        return data;
    }

    /**
     * @method _cleanData()
     * @param (value: any)
     * @description
     */

    private _cleanData(value: any): string {
        return value.replace(/ /g, '');
    }

    /**
     * @method getPaymentTypes()
     * @param (facility: Facility)
     * @description
     */

    getPaymentTypes(id: string): Observable<string[]> {
        return this._facilityApiService.getFacilityDetailsRequest(id).pipe(
            map((facility: Facility) => {
                return facility.paymentTypes ? facility.paymentTypes : [];
            })
        );
    }

    /**
     * @method getChargesByMbl()
     * @param (facility: Facility)
     * @description
     */

    getChargesByMbl(MBL: string, facilityId: string): Observable<any> {
        return this._facilityApiService.facilityLookup(MBL, facilityId, 'MASTER').pipe(
            map((charges: DynamicFieldByHouse) => {
                return charges.dynamicFields;
            })
        );
    }

    /**
     * @method getChargesByHbl()
     * @description
     */

    getChargesByHbl(
        HBL: string,
        facilityId: string,
        operationType: string = 'HOUSE',
        pickupDate?: string
    ): Observable<any> {
        return this._facilityApiService.facilityLookup(HBL, facilityId, operationType, pickupDate).pipe(
            map((charges: DynamicFieldByHouse) => {
                return charges.dynamicFields;
            })
        );
    }
}
