import * as moment from 'moment';
import { CellContent } from 'src/app/directives/change-cell-content.directive';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ButtonIconComponent } from 'src/app/standalone-components/dynamic-table/custom-columns/button-icon/button-icon.component';
import { ECheckCardComponent } from 'src/app/standalone-components/dynamic-table/custom-columns/echeck-card/echeck-card.component';
import { RedirectColumnComponent } from 'src/app/standalone-components/dynamic-table/custom-columns/redirect-column/redirect-column.component';
import { StatusComponent } from 'src/app/standalone-components/dynamic-table/custom-columns/status/status.component';
import { paymentMethod } from '../constants';

export class ForwarderAdminUser extends CustomerService {
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');
    private static readonly paymentReferencePipe = new FormatPaymentReferencePipe();

    static readonly forwarderAdminRefundRequest_ColumnsHistory = [
        'select',
        'id',
        'DATE_REQUESTED',
        'PAYMENT',
        'mawb',
        'CUSTOMER_REFERENCE',
        'REQUESTED_AMOUNT',
        'STATUS',
    ];
    static readonly forwarderAdminRefundRequest_ColumnsNameHistory = [
        'select',
        'Refund Number',
        'Created date',
        'Payment reference #',
        'MAWB',
        'Customer Reference number',
        'Requested amount',
        'Status',
    ];
    static readonly forwarderAdminRefundRequest_getRefundRequestPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        link: `./payment-detail/${item.id}`,
        CUSTOMER_REFERENCE: item.customerReference ?? 'N/A',
        PAYMENT: ForwarderAdminUser.paymentReferencePipe.transform(item.payment?.vendorInvoiceNumber, item.payment.id),
        mawb: item?.payment?.awb || 'N/A',
        DATE_REQUESTED: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        refundReason: item.refundReason ? item.refundReason : 'N/A',
        REQUESTED_AMOUNT: item.requestedAmount ? '$' + Number.parseFloat(item.requestedAmount).toFixed(2) : 'N/A',
        STATUS: item.status ? item.status : 'N/A',
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });

    static readonly forwarderAdminRefundRequest_ColumnsSortableRefundRequestPayments = [
        'id',
        'PAYMENT',
        'REQUESTED_AMOUNT',
        'DATE_REQUESTED',
        'STATUS',
        'CUSTOMER_REFERENCE',
    ];

    static readonly debitHistory_columns = [
        'select',
        'debitNumber',
        'createdDate',
        'amount',
        'paymentMethod',
        'status',
        'actions',
    ];

    static readonly debitHistory_columnsNames = ['', 'Debit number', 'Date', 'Amount', 'Payment method', 'Status', ''];

    static readonly debitHistory_sortableColumns = ['debitNumber', 'createdDate', 'amount', 'status'];

    static readonly debitHistory_customColumns: CellContent[] = [
        { column: 'debitNumber', component: RedirectColumnComponent },
        { column: 'status', component: StatusComponent },
        { column: 'actions', component: ButtonIconComponent },
        { column: 'paymentMethod', component: ECheckCardComponent },
    ];

    static readonly debitHistory_getItems = (item: any) => ({
        id: item.id ? item.id : '',
        createdDate: item.createdDate ? moment(item.createdDate).format('MM/DD/YY') : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentMethod: item.paymentMethodInfo ? item.paymentMethodInfo : 'N/A',
        status: item.status ? item.status : 'N/A',
        redirect: {
            viewValue: item.id ? item.id : '',
            redirectTo: './debit-detail/' + (item.id ? item.id : ''),
        },
        actions: {
            button: {
                iconName: 'save_alt',
            },
        },
    });

    static readonly forwarderAdminInvoiceOpen_Columns = [
        'select',
        'id',
        'createdDate',
        'customerReference',
        'requesterEmail',
        'facilityReference',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];

    static readonly forwarderAdminInvoiceOpen_ColumnsName = [
        'select',
        'Invoice number',
        'Created date',
        'Customer reference',
        'Requester email',
        'Facility reference',
        'Amount',
        'Short Payment Amount',
        'Balance due',
        'Due date',
    ];

    static readonly forwarderAdminInvoiceOpen_SortableColumns = ['createdDate', 'amount', 'dueDate'];

    static readonly invoice_getCompletedOpenInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        requesterEmail: item.paymentNotificationEmail
            ? item.paymentNotificationEmail
            : item.customer && item.customer.email
              ? item.customer.email
              : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        shortPaymentAmount: item.paymentShort ? item.paymentShort : 'N/A',
        balanceDue: item.amount
            ? '$' +
              (
                  Number.parseFloat(item.amount) -
                  (Number.parseFloat(item.shortPayment) ? Number.parseFloat(item.shortPayment) : 0)
              ).toFixed(2)
            : 'N/A',
        dueDate: item.dueDate ? moment.utc(item.dueDate).format('MMM DD, YYYY') : '',
        overDue:
            moment.utc(item.dueDate).startOf('day').diff(ForwarderAdminUser.currentDate, 'days') >= 0 &&
            moment.utc(item.dueDate).startOf('day').diff(ForwarderAdminUser.currentDate, 'days') < 7
                ? 'almostDue'
                : moment.utc(item.dueDate).startOf('day').diff(ForwarderAdminUser.currentDate, 'days') < 0
                  ? 'overdue'
                  : 'onTime',
        id: 'INV' + item.id,
        link: `../invoice-detail/${item.id}`,
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });

    static readonly forwarderAdminInvoicePaid_Columns = [
        'select',
        'id',
        'createdDate',
        'customerReference',
        'facilityReference',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];

    static readonly forwarderAdminInvoicePaid_ColumnsName = [
        'select',
        'Invoice Number',
        'Created date',
        'Customer reference',
        'Facility reference',
        'Amount',
        'Payment date',
        'Payment method',
    ];

    static readonly forwarderAdminInvoicePaid_SortableColumns = [
        'createdDate',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];

    static readonly invoice_getCompletedPaidInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentDate: item.paymentDate ? moment(item.paymentDate).format('MMM DD, YYYY') : '',
        paymentMethod: item.paymentMethod ? (paymentMethod[item.paymentMethod.name] ?? item.paymentMethod.name) : 'N/A',
        confirmationId: item.confirmationId,
        id: 'INV' + item.id,
        link: `../invoice-detail/${item.id}`,
        hasDownloadableContent: true,
    });

    static readonly forwarderAdminPaymentHistory_ColumnsName = [
        '',
        'Payment Reference #',
        'Created Date',
        'Customer Name',
        'MAWB#',
        'Branch',
        'Amount Paid',
        'Payment type',
        'Notification email',
    ];

    static readonly forwarderAdminPaymentHistory_Columns = [
        'select',
        'linkId',
        'createdDate',
        'customerName',
        'awb',
        'branch',
        'amount',
        'paymentType',
        'notificationEmail',
    ];

    static readonly payment_getHistory = (payment: any) => ({
        id: payment.name,
        idNumber: payment.id,
        createdDate: moment(payment.createdDate).format('MMM DD, YYYY') ?? '',
        customerName: payment.customer.name,
        awb: payment.awb,
        amount: payment.amount ? '$' + Number.parseFloat(payment.amount).toFixed(2) : 'N/A',
        paymentType: payment.paymentType,
        notificationEmail: payment.notificationEmail
            ? payment.notificationEmail
            : payment.customer && payment.customer.notificationEmail
              ? payment.customer.notificationEmail
              : 'N/A',
        branch: payment?.invoice?.location?.name ? payment?.invoice?.location?.name : 'N/A',
        podName: payment.podName ?? '',
        paymentConfirmation: payment.isPaymentConfirmationAvailable ?? '',
        externalPaymentConfirmation: payment.isExternalPaymentConfirmationAvailable ?? '',
        attachments: payment.attachments ?? null,
        link: `./payment-detail/${payment.vendorInvoiceNumber ? payment.vendorInvoiceNumber : payment.id}`,
        hasDownloadableContent: true,
        linkId: ForwarderAdminUser.paymentReferencePipe.transform(payment?.vendorInvoiceNumber, payment.id),
    });

    static readonly payment_getPaymentDetails = (payment: any) => [
        { label: 'Second facility reference #', value: payment?.hawb ? payment?.hawb : 'N/A', show: true },
        { label: 'Amount', value: payment?.amount ? '$' + Number(payment?.amount).toFixed(2) : 'N/A', show: true },
        { label: 'Payment type', value: payment?.paymentType ? payment?.paymentType : 'N/A', show: true },
        { label: 'Paid to', value: payment?.paidTo ? payment?.paidTo : 'N/A', show: true },
        { label: 'Customer reference', value: payment?.customerReference ?? 'N/A', show: true },
        {
            label: 'Branch',
            value: payment?.customerAccount?.accountName ? payment?.customerAccount?.accountName : 'N/A',
            show: true,
        },
        { label: 'Cargo facility', value: payment?.facility?.name ? payment?.facility?.name : 'N/A', show: true },
    ];

    static readonly invoice_getInvoiceDetails = (invoice: any) => [
        { title: 'Invoice number', value: invoice?.name ? invoice?.name : 'N/A' },
        {
            title: 'Created date',
            value: invoice?.createdDate ? moment(invoice?.createdDate).format('MMM DD, YYYY') : 'N/A',
        },
        { title: 'Due date', value: invoice?.dueDate ? moment(invoice?.dueDate).format('MMM DD, YYYY') : 'N/A' },
        { title: 'Balance due', value: invoice?.balanceDue ? '$' + Number(invoice?.balanceDue).toFixed(2) : 'N/A' },
        { title: 'Total amount', value: invoice?.amount ? '$' + Number(invoice?.amount).toFixed(2) : 'N/A' },
        { title: 'AWB/MBL#', value: invoice?.awb ? invoice?.awb : 'N/A' },
        { title: 'HAWB', value: invoice?.hawb ? invoice?.hawb : 'N/A' },
        { title: 'Payment method', value: invoice?.paymentMethod?.name ? invoice?.paymentMethod?.name : 'N/A' },
        { title: 'Paid to', value: invoice?.paidTo ? invoice?.paidTo : 'N/A' },
        // { title: 'Facility reference', value: invoice?.awb ? invoice?.awb : 'N/A' },
        {
            title: 'Payment date',
            value: invoice?.paymentDate ? moment(invoice?.paymentDate).format('MMM DD, YYYY') : 'N/A',
        },
    ];

    static readonly invoice_getCustomerDetails = (invoice: any) => [
        { title: 'Customer reference', value: invoice?.customerReference ? invoice?.customerReference : 'N/A' },
        { title: 'Requester email', value: invoice?.customer?.email ? invoice?.customer?.email : 'N/A' },
    ];

    static getCustomerInformationHistorySection(item: any) {
        return [
            {
                label: 'Customer Name',
                value: item.customer ? item.customer.name : 'N/A',
                show: true,
            },
            {
                label: 'Notification email',
                value: item?.notificationEmail ? item?.notificationEmail : 'N/A',
                show: true,
            },
            {
                label: 'Created Date',
                value: item?.createdDate ? moment(item?.createdDate).format('MMM DD, YYYY') : 'N/A',
                show: true,
            },
        ];
    }
}
