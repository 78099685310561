import {
    AccountingDetails,
    Cart,
    PayloadPayPal,
    PaymentMetaData,
    PaymentMethodItem,
    PaymentMethods,
    PaymentMethodsType,
    PaymentToken,
} from '@cargos/sprintpay-models';
import { HostedFieldsAccountDetails } from 'braintree-web/hosted-fields';

// export enum PaymentMethods {
//     CREDIT_CARD = 'credit-card',
//     ECHECK = 'echeck',
//     CARGOSPRINT_CREDIT = 'cargo-credit',
//     PAYPAL = 'paypal',
// }

// export enum PaymentMethodsCartBill {
//     CREDIT_CARD = 'CREDIT_CARD',
//     ECHECK = 'ECHECK',
//     CARGOSPRINT_CREDIT = 'CARGOSPRINT_CREDIT',
//     PAYPAL = 'PAYPAL',
//     PAYMENT_SOURCE_CREDIT = 'PAYMENT_SOURCE_CREDIT',
//     UNKNOWN = 'UNKNOWN',
//     GOOGLE_PAY = 'GOOGLE_PAY',
//     APPLE_PAY = 'APPLE_PAY',
// }

// export type PaymentMethodsKeys = keyof typeof PaymentMethods;

export interface PaymentAccount {
    details?: HostedFieldsAccountDetails;
    accountingDetails?: AccountingDetails;
    paymentToken?: PaymentToken;
    paymentMethodItem?: PaymentMethodItem;
    availableBalance?: number;
    address?: string;
    paypal?: boolean;
}

export interface PaymentMethodSelected {
    paymentAccount?: any;
    method: PaymentMethodsType;
    token?: string;
}

export interface EcheckAccountTypeOptions {
    value: string;
    viewValue: string;
}

export interface PaymentTab {
    type: PaymentMethods;
    label: string;
}

export const CARGOSPRINT_ECHECK_ACCOUNT_NUMBER = '0198548840';
export const CARGOSPRINT_ECHECK_ROUTING_NUMBER = '064000017';

// temporary type, will be changed to use @cargos/sprintpay-models
export interface Balance {
    availableCredit?: number;
    creditLimit?: number;
    creditPercentage?: number;
}

export interface PayloadGuest {
    paymentMetadata: PaymentMetaData;
    nonce: string;
    customerReference: string;
    paymentMethodNumber?: string;
    paymentInstitution?: string;
    paymentMethodExpirationMonth?: string;
    paymentMethodExpirationYear?: string;
}

export interface CartPayRequest {
    paymentMethod: {
        method: PaymentMethodsType;
        paymentAccount?: PaymentAccount;
    };
    cartBill: Cart;
    payload: PayloadPayPal | PaymentToken | PayloadGuest;
    paymentMetaData?: PaymentMetaData;
}

export enum Currency {
    USD = 'USD',
}
