<div id="form-header">
    <figure class="facility__logo mt-5 mb-4">
        <img class="d-block mx-auto my-0 h-100" src="{{facilityLogo}}" alt="{{facility}} logo" />
    </figure>
    <figure class="account__logo my-4">
        <img
            class="d-block mx-auto my-0 h-100"
            src="./assets/images/signup/account-illustration.svg"
            alt="Account illustration logo"
        />
    </figure>
    <h5 class="d-block w-100 text-center">{{title}}</h5>
</div>
