<button
    (click)="validateAndPay()"
    [disabled]="(!isPaymentMethodSelectedValid) || (!isInvoiceCheckLimitValid) || !isTermsOfUseValid"
    mat-button
    type="submit"
    class="d-block btn__sprintpay btn__sprintpay--green w-100 text-uppercase"
>
    Pay now
</button>
<ng-template #authenticationFlux>
    <app-authentication-flux></app-authentication-flux>
</ng-template>
