import { CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardMethodsType, PaymentMethod, PaymentMethods, PaymentMethodsType } from '@cargos/sprintpay-models';
import { Subject, map, takeUntil } from 'rxjs';
import { CartBillService, InitialConfigService, PaymentMethodsService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';
import { profileComponents } from 'src/app/utils/constants';
import { AuthenticatedPaymentMethodComponent } from './authenticated-payment-method/authenticated-payment-method.component';
import { CargoCreditComponent } from './cargo-credit/cargo-credit.component';
import { CreditCardsListComponent } from './credit-card-list/credit-cards.component';
import { EcheckListComponent } from './echecks-list/echeck.component';
import { GuestCreditCardComponent } from './guest-credit-card/guest-credit-card.component';
import { GuestPaymentMethodComponent } from './guest-payment-method/guest-payment-method.component';
import { PaymentMethodsListMaterialModule } from './payment-methods-list-material-modules.module';
import { PaypalComponent } from './paypal/paypal.component';

@Component({
    standalone: true,
    selector: 'app-payment-methods-list',
    imports: [
        NgIf,
        NgFor,
        AuthenticatedPaymentMethodComponent,
        PaymentMethodsListMaterialModule,
        GuestPaymentMethodComponent,
        CurrencyPipe, // TODO: Check this - low
        CreditCardsListComponent,
        EcheckListComponent,
        CargoCreditComponent,
        PaypalComponent,
        GuestCreditCardComponent,
    ],
    templateUrl: './payment-methods-list.component.html',
    styleUrls: ['.//payment-methods-list.component.scss'],
})
export class PaymentMethodsListComponent implements OnInit, OnDestroy {
    public paymentMethodSelected: PaymentMethodSelected | null = null;
    public paymentMethodsList: PaymentMethod[] = [];
    public isSelectingPaymentMethod = false;
    public expandedPanel: boolean;
    public isGuest: boolean;
    public readonly PaymentMethods = PaymentMethods;
    public isChangePaymentMethodButtonAvailableForGuest: boolean = false;
    public isAuthenticated: boolean = false;
    public isThereInvoicesInCart: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
    public isEnoughCredit?: boolean = false;
    public isAboveThresholdPaymentRequest: boolean = false;
    public completedLoading = false;
    public isEnableSomePaymentMethod = false;
    public isThresholdAndPaymentMethodsUnAvailable: boolean;

    @Input() aboveThreshold: boolean = false;

    constructor(
        private customerService: CustomerService,
        private securityService: SecurityService,
        private router: Router,
        private paymentMethodsService: PaymentMethodsService,
        private cartBillService: CartBillService,
        private summaryService: SummaryService,
        private initialConfigService: InitialConfigService,
        private userSessionService: UserSessionService,
        private customerFeaturesService: CustomerFeaturesService
    ) {
        this.isGuest = this.customerService.isGuest();
        this.expandedPanel = this.isGuest;
    }

    ngOnInit(): void {
        this.subscribeGetCurrentCart();
        this.subscribeGetDefaultPaymentMethodAndCart();
        this.subscribePaymentMethods();
        this.subscribeToDefaultPaymentMethod();
        this.subscribeSessionStatus();
        this.subscribeIsAllowedToPayWithCargoCredit();
        this.subscribeIsCartAboveThreshold();
        this.subscribeIsThresholdAndCargoCredit();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method subscribePaymentMethods()
     * @description Subscribes to the payment methods observable and updates the state to determine
     *              if the "Change Payment Method" button should be available for guest users.
     */
    private subscribePaymentMethods(): void {
        this.paymentMethodsService
            .getPaymentMethods$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethods: PaymentMethod[]) => {
                    this.paymentMethodsList = paymentMethods;
                    this.isChangePaymentMethodButtonAvailableForGuest =
                        this.isChangePaymentMethodButtonAvailableForGuestUser(paymentMethods.length);
                },
            });
    }

    /**
     * @method isChangePaymentMethodButtonAvailableForGuestUser
     * @description Determines whether the "Change Payment Method" button should be available for guest users.
     * @param {number} paymentMethodsLength - The number of available payment methods.
     * @returns {boolean} - True if the button should be available, false otherwise.
     */
    isChangePaymentMethodButtonAvailableForGuestUser(paymentMethodsLength: number): boolean {
        return !!(
            paymentMethodsLength > 1 ||
            (this.paymentMethodSelected &&
                PaymentMethods.CARD_METHODS.includes(<CardMethodsType>this.paymentMethodSelected.method))
        );
    }

    /**
     * @method subscribeGetDefaultPaymentMethodAndCart()
     * @description Preselect payment method
     */
    private subscribeGetDefaultPaymentMethodAndCart(): void {
        this.isSelectingPaymentMethod = true;

        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (selectedPayment: PaymentMethodSelected | null) => {
                    this.isSelectingPaymentMethod = false;
                    if (selectedPayment) {
                        this.isEnableSomePaymentMethod = true;
                        this.paymentMethodSelected = selectedPayment;
                    }
                },
                error: () => {
                    this.isSelectingPaymentMethod = false;
                },
            });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method selectPaymentMethod()
     * @param (paymentAccount: any) Object in array
     * @param (method: keyof typeof PaymentMethods) Method of payment
     * @description: Triggers an observable in the SUMMARY.ts that handles the logic to change the payment summary in relation to the desired payment method
     */

    selectPaymentMethod(method: PaymentMethodsType, token?: string): void {
        if (this.isAuthenticated) {
            this.expandedPanel = false;
        }
        if (this.aboveThreshold) {
            this.summaryService.setPaymentMethodSelectedAboveThreshold({ method, token });
        } else {
            this.summaryService.setPaymentMethodSelected({ method, token });
        }
    }

    /**
     * @method subscribeGetCurrentCart()
     * @description Subscribes to the current cart and updates the cart bill and subtotal.
     *              This method listens for changes in the cart bill and updates the component state accordingly.
     *              It also checks for invoice limits and sets the invoice limit flag if necessary.
     */

    private subscribeGetCurrentCart(): void {
        this.cartBillService
            .getCartBill$()
            .pipe(
                map((cartBill) => {
                    this.isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();
                    const isTherePaymentsRequestsNoInvoices = this.cartBillService.isTherePaymentsRequestsNoInvoices();

                    return {
                        cartBill,
                        isThereInvoicesInCart: this.isThereInvoicesInCart,
                        isTherePaymentsRequestsNoInvoices,
                    };
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: ({ isThereInvoicesInCart }) => {
                    this.isThereInvoicesInCart = isThereInvoicesInCart;
                },
            });
    }

    togglePanel(): void {
        this.expandedPanel = !this.expandedPanel;
    }

    newPaymentMethodGuest(): void {
        this.router.navigate(['./admin/cart/newPaymentMethods']);
    }

    /**
     * @method redirectToPaymentMethods()
     * @description verify if the profile components contains the payment methods section
     */

    redirectToPaymentMethods(): void {
        const hasPaymentMethods: boolean = this.securityService.verifyComponentsSecurity(
            profileComponents.paymentMethod
        );
        this.router.navigate([hasPaymentMethods ? '/admin/facilityPayments/paymentMethods' : '/admin/home']);
    }

    subscribeToDefaultPaymentMethod(): void {
        this.initialConfigService
            .isSettingDefaultPaymentMethod$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isSettingDefaultPaymentMethod: boolean) => {
                    this.isSelectingPaymentMethod = isSettingDefaultPaymentMethod;
                    this.completedLoading = true;
                },
                error: () => {
                    this.isSelectingPaymentMethod = false;
                    this.completedLoading = true;
                },
            });
    }

    subscribeSessionStatus(): void {
        this.userSessionService
            .isAuthenticated$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isAuthenticated: boolean) => {
                    this.isAuthenticated = isAuthenticated;
                },
            });
    }

    subscribeIsAllowedToPayWithCargoCredit(): void {
        this.customerFeaturesService
            .isAllowedToPayWithCargoCredit$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isAllowedToPayWithCargoCredit) => {
                this.isEnoughCredit = isAllowedToPayWithCargoCredit.isEnoughCredit;
            });
    }

    subscribeIsCartAboveThreshold(): void {
        this.cartBillService
            .isCartAboveThreshold$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((exist) => {
                this.isAboveThresholdPaymentRequest = exist;
            });
    }

    redirectToCreditCard(): void {
        this.router.navigate(['./admin/cart/newPaymentMethods/credit-card']);
    }

    redirectToEcheck(): void {
        this.router.navigate(['./admin/cart/newPaymentMethods/echeck']);
    }

    subscribeIsThresholdAndCargoCredit(): void {
        this.customerFeaturesService
            .isPaymentMethodsUnAvailable$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isThresholdAndPaymentMethodsUnAvailable) => {
                    this.isThresholdAndPaymentMethodsUnAvailable = isThresholdAndPaymentMethodsUnAvailable;
                },
            });
    }
}
