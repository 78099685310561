import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, filter, switchMap, takeUntil } from 'rxjs';
import { LoginService } from './pages/auth/services/login.service';
import { InitialConfigService } from './services/initial-configuration';
import { CustomerService } from './services/utils/customer-handler.service';
import { UserSessionService } from './services/utils/user-session.service';
import { permissionsName, profileTypes } from './utils/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    private redirectToPortalRoutes = [
        '/login',
        '/password',
        '/signup',
        '/signup-guest',
        '/activate',
        '/activate-guest',
    ];
    constructor(
        private customerService: CustomerService,
        private ngxPermissionsService: NgxPermissionsService,
        private router: Router,
        private loginService: LoginService,
        private userSessionService: UserSessionService,
        private ngZone: NgZone,
        private initialConfigurationService: InitialConfigService
    ) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeRouterEventsForPermissionsWorkaround();
        this.subscribeInitialConfiguration();
        this.checkSessionTokenChange();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeInitialConfiguration(): void {
        this.initialConfigurationService.setInitialConfiguration().pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    checkSessionTokenChange(): void {
        this.userSessionService
            .hasToken$()
            .pipe(
                filter((token) => token),
                switchMap(() => this.userSessionService.isAuthenticated$()),
                filter((authenticated) => authenticated),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => this.redirectToAdminPortal());
    }

    private redirectToAdminPortal(): void {
        const route = `/${this.router.url.split('/')[1]}`;
        if (!this.redirectToPortalRoutes.includes(route)) return;
        const customer = this.customerService.getCustomer();
        const customerProfile =
            customer?.profileType && customer.profileType !== profileTypes.FORWARDER_FACILITY
                ? customer.profileType
                : profileTypes.FORWARDER;
        this.customerService.setActiveProfileView(customerProfile);
        const url = this.loginService.getRedirectLink();
        this.ngZone.run(() => this.router.navigate([url]));
    }

    private subscribeRouterEventsForPermissionsWorkaround(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
            if (event instanceof NavigationStart && event.url.includes('admin/facilityPayments/newPayment?')) {
                this.ngxPermissionsService.addPermission(permissionsName.guest);
            }
        });
    }
}
