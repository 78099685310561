import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeType } from 'src/app/models/ui/themes';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SidenavOption, SidenavOptionTypes } from 'src/app/utils/sidenav-types';
import { BranchSelectorComponent } from '../branch-selector/branch-selector.component';
import { LogoByBrandComponent } from '../logo-by-brand/logo-by-brand.component';
import { PortalSelectorComponent } from '../portal-selector/portal-selector.component';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    standalone: true,
    imports: [CommonModule, SharedModule, PortalSelectorComponent, LogoByBrandComponent, BranchSelectorComponent],
})
export class SidenavComponent implements OnDestroy {
    themeTypes = ThemeType;
    readonly sidenavOptionTypes: typeof SidenavOptionTypes;

    @Input() navItems?: SidenavOption[];
    @Input() themeType?: ThemeType;
    @Input() branchSelector = false;
    @Output() redirectLink: EventEmitter<string> = new EventEmitter();
    @Output() helpContactAlert: EventEmitter<void> = new EventEmitter();

    readonly mobileQueryListener: () => void;
    readonly mobileQuery: MediaQueryList;

    constructor(
        private _router: Router,
        private _tokenService: TokenService,
        private _mediaMatcher: MediaMatcher,
        private _changeDetectorRef: ChangeDetectorRef,
        private _customerService: CustomerService,
        private _userSessionService: UserSessionService
    ) {
        this.sidenavOptionTypes = SidenavOptionTypes;
        this.mobileQuery = this._mediaMatcher.matchMedia('(max-width: 991.98px)');
        this.mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    }

    /**
     * @method prepareIdString()
     * @param (label: string | undefined)
     * @description Generates id from item label
     */

    prepareIdString(label?: string): string {
        if (!label) return crypto.randomUUID();
        return label.split(' ').join('_').split('(s)').join('').split('_/_').join('_');
    }

    /**
     * @method getRoute()
     * @description: Returns part of route to highlight active sidenav item
     */

    get getRoute(): string {
        return this._router.url.slice(1);
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method navigate()
     * @param (url: string | undefined)
     * @description: Emits redirect event
     */

    navigate(url?: string): void {
        if (url) {
            this.redirectLink.emit(url);
        }
    }

    /**
     * @method showHelpAlert()
     * @description: Logic to show the Swal Help Alert and close mat-sidenav if responsive
     */

    showHelpAlert(): void {
        this.helpContactAlert.emit();
    }

    /**
     * @method logout()
     * @description Logout the user from the application
     */

    logout(): void {
        this._userSessionService.logout();
    }

    /**
     * @method signIn()
     * @description Save guest token and navigate to login form screen
     */

    signIn(): void {
        this._tokenService.setAuthorizationTokenId(
            this._tokenService.getDecodedAccessToken()?.AUTHORIZATION_TOKEN_ID || ''
        );
        this.logout();
    }

    /**
     * @method goToHomePage()
     * @description Navigate to Home page
     */

    goToHomePage(): void {
        if (this._customerService.isGuest()) {
            this._router.navigateByUrl('');
        }
    }
}
