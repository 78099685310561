import { Component, Input, OnInit } from '@angular/core';
import { MethodCreditCardsService } from '../services/method-credit-cards.service';

@Component({
    standalone: true,
    selector: 'app-expiration-date',
    templateUrl: './expiration-date.component.html',
    styleUrls: ['./expiration-date.component.scss'],
})
export class ExpirationDateComponent implements OnInit {
    public expired: boolean;

    @Input() expirationMonth: string;
    @Input() expirationYear: string;

    constructor(private methodCreditCardsService: MethodCreditCardsService) {}

    ngOnInit(): void {
        this.hasExpired();
    }

    hasExpired(): void {
        this.expired = this.methodCreditCardsService.expiredCard(this.expirationYear, this.expirationMonth);
    }
}
