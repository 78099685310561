import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    BraintreeException,
    Exception,
    PaymentMethod,
    PaymentMethodItem,
    PaymentMethods,
} from '@cargos/sprintpay-models';
import { ErrorBraintreeHandlerService, PaymentMethodsRequestService } from '@cargos/sprintpay-services';
import { catchError, from, of, Subject, switchMap, take, takeUntil, throwError } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
})
export class CreditCardComponent implements OnInit, OnDestroy {
    public creditCards: PaymentMethodItem[] = [];
    public noCreditCards: boolean | undefined;
    public currentMonth: number = new Date().getMonth() + 1;
    public currentYear: number = new Date().getFullYear();
    public deletingCard: boolean = false;
    public isLoading: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private errorBraintreeHandlerService: ErrorBraintreeHandlerService,
        private router: Router,
        private paymentMethodsService: PaymentMethodsService,
        private paymentMethodsRequestService: PaymentMethodsRequestService
    ) {}

    ngOnInit(): void {
        this.subscribeToCreditCards();
        this.subscribeCreditCardsLoading();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method subscribeToCreditCards()
     * @description Subscribes to the user's credit cards and updates the component state accordingly
     */
    private subscribeToCreditCards(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.CREDIT_CARD)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethod: PaymentMethod | undefined) => {
                    this.creditCards = paymentMethod?.items || [];
                    this.noCreditCards = !paymentMethod?.items.length;
                },
            });
    }

    /**
     * @method deleteCard()
     * @param (tokenCard: string | null | undefined)
     * @description Deletes the card using the provided payment token
     */
    deleteCard(tokenCard: string | null | undefined): void {
        if (!tokenCard) {
            return;
        }

        from(
            Swal.fire({
                text: 'You are about to delete a credit card',
                title: 'Did you want to continue?',
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
            })
        )
            .pipe(
                switchMap((result: SweetAlertResult) => {
                    if (!result.isConfirmed) {
                        return of('Complete');
                    }

                    this.deletingCard = true;
                    return this.paymentMethodsRequestService.removeCard(tokenCard).pipe(
                        take(1),
                        catchError((err: BraintreeException) => {
                            const error: Exception = this.errorBraintreeHandlerService.handlerError(err);
                            Swal.fire({
                                title: error.title,
                                text: error.description,
                                icon: 'error',
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                allowOutsideClick: false,
                            });

                            return throwError(() => error);
                        }),
                        switchMap(() => {
                            return this.paymentMethodsService.getPaymentMethodsRequest$().pipe(take(1));
                        })
                    );
                })
            )
            .subscribe({
                complete: () => {
                    this.deletingCard = false;
                },
            });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method openWarningPopup()
     * @description Shows a warning popup before adding a new credit card
     */
    openWarningPopup(): void {
        Swal.fire({
            title: 'Warning...',
            html: `<div style="margin:0 20px 40px 20px; text-align: left;"> Please only add your credit card and not your customers' credit card as you will be held liable if your customer disputes the charges.</div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn__sprintpay btn__sprintpay--green',
                cancelButton: 'btn__sprintpay btn__sprintpay--gray me-4',
                closeButton: 'mat-icon-button',
                title: 'swal2-title-custom',
            },
            buttonsStyling: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.router.navigate(['admin/facilityPayments/paymentMethods/newPaymentMethods/credit-card']);
            }
        });
    }

    subscribeCreditCardsLoading(): void {
        this.paymentMethodsService
            .getPaymentMethodsInProcess$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isLoading) => {
                    this.isLoading = isLoading;
                },
            });
    }
}
