import { Injectable } from '@angular/core';
import { CartException, CartExceptionType } from '@cargos/sprintpay-models';
import { Observable, defaultIfEmpty, distinctUntilChanged, forkJoin, map, of, switchMap, take, throwError } from 'rxjs';
import { CustomerFeaturesService } from '../features/features.service';
import { TermsOfUseForm } from '../summary/models/types';
import { SummaryService } from '../summary/summary.service';
import { PaymentValidation } from './models/payment-types';

@Injectable({
    providedIn: 'root',
})
export class PaymentHandlerService {
    constructor(
        private summaryService: SummaryService,
        private customerFeaturesService: CustomerFeaturesService
    ) {}

    isAllowedToMakeAPayment(): Observable<boolean> {
        return forkJoin(this.getValidations()).pipe(
            defaultIfEmpty([]),
            take(1),
            switchMap((validations: PaymentValidation[]) => {
                const allExceptions: CartException[] = validations.reduce((result, validation) => {
                    if (!validation.isValid && validation.exception) {
                        return [...result, validation.exception];
                    }
                    return result;
                }, [] as CartException[]);

                if (allExceptions.length) {
                    return throwError(() => allExceptions);
                }

                return of(true);
            }),
            distinctUntilChanged()
        );
    }

    private getValidations(): Observable<PaymentValidation>[] {
        const validations = [
            this.isTermsOfUseAcceptedToPay$().pipe(take(1)),
            this.isPaymentMethodValidToPay().pipe(take(1)),
            this.isInvoiceCheckLimitValid$().pipe(take(1)),
        ];

        return validations;
    }

    private isPaymentMethodValidToPay(): Observable<PaymentValidation> {
        return this.customerFeaturesService.isPaymentMethodValid$().pipe(
            map((isPaymentMethodValid) => {
                if (!isPaymentMethodValid) {
                    return {
                        isValid: false,
                        exception: CartException.fromJson({
                            code: CartExceptionType.PAYMENT_METHOD_INVALID,
                        }),
                    };
                }

                return {
                    isValid: true,
                };
            })
        );
    }

    private isInvoiceCheckLimitValid$(): Observable<PaymentValidation> {
        return this.customerFeaturesService.isInvoiceCheckLimitValid$().pipe(
            map((isInvoiceCheckLimitValid) => {
                if (!isInvoiceCheckLimitValid) {
                    return {
                        isValid: false,
                        exception: CartException.fromJson({
                            code: CartExceptionType.INVOICE_CHECK_LIMIT_INVALID,
                        }),
                    };
                }

                return {
                    isValid: true,
                };
            })
        );
    }

    private isTermsOfUseAcceptedToPay$(): Observable<PaymentValidation> {
        return this.summaryService.getTermsOfUseValid$().pipe(
            map((termsOfUseForm: TermsOfUseForm) => termsOfUseForm?.isValidForm),
            map((isTermsOfUseAccepted) => {
                if (!isTermsOfUseAccepted) {
                    return {
                        isValid: false,
                        exception: CartException.fromJson({
                            code: CartExceptionType.TERMS_OF_USE_INVALID,
                        }),
                    };
                }

                return {
                    isValid: true,
                };
            })
        );
    }
}
