import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Locations } from 'src/app/models/utils/locations.model';
import { FacilityService } from 'src/app/services/facility.service';
import { RestService } from 'src/app/services/rest.service';
import { Geodis } from 'src/app/utils/companies/geodis';
import { Domains, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepper-types';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-geodis-reference',
    templateUrl: './geodis-reference.component.html',
})
export class GeodisReferenceComponent implements OnInit {
    geodisReferenceForm!: FormGroup;
    typeOptions!: any[];
    urlNextStep: string;
    onEdition: boolean;
    locationAssigned: any;
    locations: Locations[];
    matcher: ErrorMatcher = new ErrorMatcher();
    paymentMethodTypes: any[];
    private _customer: any;
    private readonly _currentPayment: any;
    private readonly _companyName: string;
    private readonly _paymentsURL: String;
    private readonly _approvalLevels: any;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _router: Router,
        private _stepperService: StepperService,
        private _restService: RestService,
        private _facilityService: FacilityService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        if (!this._currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this._paymentsURL = environment.uris.method.payments;
        this.onEdition = this._currentPayment && this._currentPayment.redirectRoute ? true : false;
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this.urlNextStep =
            this._currentPayment && this._currentPayment.redirectRoute
                ? this._currentPayment.redirectRoute
                : this._companyName
                  ? '../paymentDetails'
                  : '../customerReference';
        this._approvalLevels = this._customer.approvalLevels
            ? this._customer.approvalLevels.company.paymentReference.map((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
        const facility = this._currentPayment.facility;
        this._facilityService
            .getPaymentTypes(facility.id)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isCustomerEmailInDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : JSON.parse(JSON.stringify(Geodis.paymentTypes));
                    }
                },
            });
        this.locationAssigned =
            this._customer.approvalLevels && this._customer.approvalLevels.company
                ? this._customer.approvalLevels.company.firstLocation
                : null;
        this.paymentMethodTypes = [];
        this.locations = [];
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._getPaymentAmount(this._approvalLevels);
        if (this.locationAssigned) {
            this.geodisReferenceForm?.get('locationId')?.setValue(this.locationAssigned.id);
            this.geodisReferenceForm?.get('locationName')?.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        if (this.paymentMethodTypes.length <= 1) {
            this.paymentAmount.setValue(this.paymentMethodTypes[0]);
        }
        if (this._currentPayment && this._currentPayment.geodisCustomerReference) {
            this.geodisReferenceForm.patchValue(this._currentPayment.geodisCustomerReference);
            this.type ? this.type.setValidators([Validators.required]) : '';
        }
    }

    /**
     * @method _getPaymentAmount()
     * @param (approvalLevels: any)
     * @description Convenience setter for easy access to form fields
     */

    private _getPaymentAmount(approvalLevels: any): void {
        this.paymentMethodTypes = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.geodisReferenceForm = this._formBuilder.group({
            paymentAmount: new FormControl<string | null>(null, [Validators.required]),
            type: new FormControl<string | null>(null, [Validators.required]),
            locationId: new FormControl<string | null>(null, [Validators.required]),
            locationName: new FormControl<string | null>(null, [Validators.required]),
        });
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.geodisReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.geodisReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.geodisReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method getGeodisValues()
     * @description
     */

    getGeodisValues(): void {
        if (this.geodisReferenceForm.invalid) {
            this.geodisReferenceForm.markAllAsTouched();
        } else {
            if (
                this._currentPayment.id &&
                this._currentPayment.geodisCustomerReference.paymentAmount !==
                    this.geodisReferenceForm.get('paymentAmount')?.value
            ) {
                this._currentPayment.externalData
                    ? (this._currentPayment.externalData.type = this.geodisReferenceForm.get('paymentAmount')!.value)
                    : '';
                this._paymentFluxService.setData('geodisCustomerReference', this.geodisReferenceForm.getRawValue());
                Swal.fire({
                    title: 'Important !',
                    icon: 'info',
                    text: 'You have changed some key information! Please review your request before continuing.',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#14bb9c',
                    allowOutsideClick: false,
                }).then((): void => {
                    this._stepperService[StepperSteps.STEP_ZERO].next(true);
                    this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
                });
            } else {
                this._stepperService[StepperSteps.STEP_ZERO].next(true);
                if (this.onEdition) {
                    this._stepperService[StepperSteps.STEP_FIRST].next(true);
                    this._stepperService[StepperSteps.STEP_SECOND].next(true);
                }
                this._paymentFluxService.setData('geodisCustomerReference', this.geodisReferenceForm.getRawValue());
                this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
            }
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this._paymentsURL + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.geodisReferenceForm.get('locationName')!.setValue(item.name);
        }
    }
}
