import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-last-four-digits',
    templateUrl: './last-four-digits.component.html',
    styleUrls: ['./last-four-digits.component.scss'],
})
export class LastFourDigitsComponent {
    @Input() lastFourDigits: string;
}
