import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerFeaturesService } from '../services/features/features.service';
import { CustomerService } from '../services/utils/customer-handler.service';
import { ProfileTypes } from '../utils/profile-types';
@Injectable({
    providedIn: 'root',
})
export class CustomerOnHoldGuard {
    constructor(
        private _customerFeaturesService: CustomerFeaturesService,
        private _router: Router,
        private _customerService: CustomerService
    ) {}

    canActivate(): boolean {
        const isCustomerOnHold = this._customerFeaturesService.isCustomerOnHold();
        if (isCustomerOnHold) {
            const profileType = this._customerService.getActiveProfileView();
            if (profileType == ProfileTypes.FORWARDER_ADMIN) {
                this._router.navigate(['/forwarder-admin']);
            } else if (profileType == ProfileTypes.FACILITY) {
                this._router.navigate(['/super-admin/payments']);
            } else {
                this._router.navigate(['/admin/home']);
            }
        }
        return true;
    }
}
