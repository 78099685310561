import { Component, Input } from '@angular/core';
import { Rates } from '../models/rates.model';

@Component({
    selector: 'app-cart-rates',
    templateUrl: './cart-rates.component.html',
    styleUrls: ['./cart-rates.component.scss'],
    // styleUrls: ['../cart-items.component.scss'],
})
export class CartRatesComponent {
    @Input() rates!: Rates[];
    constructor() {}
}
