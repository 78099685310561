import { PaymentMethod, PaymentMethodItem, PaymentMethodsType } from '@cargos/sprintpay-models';
import { CreditCardFormGroupType } from '@cargos/sprintpay-ui';
import { HostedFieldsTokenizePayload } from 'braintree-web';
import { AddressInformation, PersonalInformation } from 'src/app/models/customer/types';
import { TermsOfUseFormGroupType } from 'src/app/pages/admin/cart/payment-detail-v2/terms-of-use/models/terms-of-use-form';

export interface PaymentMethodGuest {
    // TODO: Remplace CreditCardFormGroupType with a new type - low
    creditCardInformation?: CreditCardFormGroupType;
    creditCard?: HostedFieldsTokenizePayload;
    addressInformation?: AddressInformation;
    personalInformation?: PersonalInformation;
}

export interface TermsOfUseForm {
    isValidForm: boolean;
    termsOfUseFormGroup: TermsOfUseFormGroupType | null;
}

export const termsOfUseValidInitialValues = {
    isValidForm: false,
    termsOfUseFormGroup: null,
};
export const paymentMethodGuestInitialValues = null;

export interface PaymentMethodComplete {
    paymentMethodComplete: PaymentMethod | PaymentMethodItem | null;
    name: PaymentMethodsType;
    token?: string;
}

export interface PaymentMethodsSelected {
    paymentMethodSelected: PaymentMethodsType | undefined;
    paymentMethodSelectedAboveThreshold: PaymentMethodsType | undefined;
}
