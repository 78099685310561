<ng-container *ngIf="loading">
    <app-loader-dot-pulse aria-label="Loading..." title="Loading..." size="small"> </app-loader-dot-pulse>
</ng-container>
<img
    [src]="src"
    [attr.alt]="alt"
    [attr.title]="title"
    [ngStyle]="{'width': width, 'height': height, 'min-width': width}"
    [class.hidden]="loading || failed"
    (load)="onLoad()"
    (error)="onError()"
/>
