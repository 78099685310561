<div id="authenticated-payment-method" class="authenticated-payment-method d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
        <ng-container *ngIf="!!paymentMethodSelected; else emptyPayment">
            <div class="d-flex flex-grow-1">
                <app-payment-method-selected
                    [aboveThreshold]="aboveThreshold"
                    [width]="paymentMethodSelected === PaymentMethods.ECHECK ? '45px' : '80px'"
                ></app-payment-method-selected>
            </div>
        </ng-container>
        <ng-template #emptyPayment>
            <mat-icon color="accent" class="material-symbols-outlined md-icon-24"> error_outlined </mat-icon>
            <div class="ms-2 no-payment-title mat-caption d-flex flex-grow-1">Select your payment method.</div>
        </ng-template>
    </div>

    <ng-container *ngIf="showError">
        <div class="ms-2 payment-method-error">{{msgError}}</div>
    </ng-container>
</div>
