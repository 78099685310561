import { Injectable } from '@angular/core';
import { PaymentCart, PaymentCartType } from '@cargos/sprintpay-models';
import { CartService } from '@cargos/sprintpay-services';
import { Observable, of, switchMap, take } from 'rxjs';
import { ApprovalFlowLevelsService } from './approval-flow-levels.service';
import { CartHandlerService } from './utils/cart/cart-handler';
import { CartBillService } from './utils/cart/cart-service';

@Injectable({
    providedIn: 'root',
})
export class PaymentRequestService {
    constructor(
        private cartService: CartService,
        private approvalFlowService: ApprovalFlowLevelsService,
        private cartHandlerService: CartHandlerService,
        private cartBillService: CartBillService
    ) {}

    /**
     * @method startApprovalFlow()
     * @param paymentRequest - The payment request to start the approval flow
     * @param locationId - The ID of the location where the approval flow is started
     * @description Starts the approval flow for a payment request and updates the cart
     * @returns An Observable of type Cart or null if the request is not added to the cart
     */
    startApprovalFlow(paymentRequest: PaymentCartType, locationId: number): Observable<PaymentCart> {
        const requestAddedToCart = this.approvalFlowService.isChargeToPayAvailable(paymentRequest.amount || 0);
        return this.cartService.startApprovalflow(paymentRequest, locationId).pipe(
            take(1),
            switchMap((paymentCart: PaymentCart) => {
                if (!requestAddedToCart) {
                    this.cartHandlerService.setAndSaveSubmittedItems(paymentCart);
                }
                return of(paymentCart);
            })
        );
    }

    /**
     * @method addPaymentRequest()
     * @param paymentRequest - The payment request to be added to the cart
     * @description Adds a payment request to the cart and updates the cart
     * @returns An Observable of type Cart
     */
    addPaymentRequest(paymentRequest: PaymentCartType): Observable<PaymentCart[]> {
        return this.cartService.addPaymentRequest(paymentRequest);
    }

    /**
     * @method removePaymentRequest()
     * @param paymentRequestId - The ID of the payment request to be removed
     * @description Removes a payment request from the cart and updates the cart
     * @returns An Observable of type Cart
     */
    removePaymentRequest(paymentRequestId: number): Observable<PaymentCart[]> {
        return this.cartService.removePaymentRequest(paymentRequestId);
    }
}
