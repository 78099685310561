import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Cart, CartAWB, CartBill } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { CartAPIService } from 'src/app/services/requests/cart-api.service';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { CartService } from '../../../../services/utils/cart/cart-v1.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { SessionService } from '../../../../services/utils/session.service';
import { sprintPaySource } from '../../../../utils/constants';

@Component({
    selector: 'app-invoices-list',
    templateUrl: './invoices-list.component.html',
})
export class InvoicesListComponent implements OnDestroy, OnInit {
    paymentRequest: CartAWB[] = [];
    openInvoices: any[];
    removeItem: Set<any>;
    uniqueRequestor: boolean;
    companyName: string;
    sprintPaySource: string = sprintPaySource;
    hasRequestWithExternalLookup!: boolean;
    public nonReservationFee = 0;
    private cartBill!: CartBill | null;
    private _customer: any;
    private _unsubscribe$: Subject<void>;
    private readonly _cartUrl: string;

    @Output() returnEmptyCart: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _restService: RestService,
        private _customerService: CustomerService,
        private _cartService: CartService,
        private _errorHandlerService: ErrorHandlerService,
        private _sessionService: SessionService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _summaryService: SummaryValidationService,
        private _cartApiService: CartAPIService,
        private cartBillService: CartBillService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._customer = this._customerService.getCustomer();
        this.companyName = this._customer?.approvalLevels?.company?.name || '';
        this.removeItem = new Set();
        this.paymentRequest = [];
        this.openInvoices = [];
        this._cartUrl = environment.uris.method.cart;
        this.uniqueRequestor = !!this._customerService.getCustomer().userType.includes('UNIQUE_REQUESTOR');
        // this._summaryService
        //     .hasRequestWithExternalLookup()
        //     .pipe(take(1))
        //     .subscribe({
        //         next: (response) => {
        //             this.hasRequestWithExternalLookup = !!response.length;
        //         },
        //         error: (error: any) => {
        //             this.hasRequestWithExternalLookup = false;
        //         },
        //     });
    }

    ngOnInit(): void {
        this.getCartGruoped();
    }

    getCartGruoped(): void {
        // if (this.hasRequestWithExternalLookup) {
        this.cartBillService
            .getCartBill$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (cartBillV2: Cart | null) => {
                    const cart = cartBillV2?.cartBillResponseDTOList || [];
                    this.openInvoices = [];
                    this.paymentRequest = cart;
                },
            });
        // } else {
        // this.cartBillService
        //     .getCartBill$()
        //     .pipe(takeUntil(this._unsubscribe$))
        //     .subscribe({
        //         next: (cartBill: Cart | null) => {
        //             const cart = cartBill?.cart || [];
        //             this.cartBill = cartBill;
        //             this.openInvoices = cart.filter((item: any) => item.originalInvoice);
        // const requests = cart.filter((item: any) => !item.originalInvoice);
        // this._sessionService.setPaymentRequest(requests.length);
        // this.paymentRequest = this.groupByAWB(requests); ******
        //             },
        //         });
        // }
    }

    // groupByAWB(requests: PaymentCart[]): any[] {
    //     if (requests.length) {
    //         const newRequest = requests.reduce((acc: any, item: any) => {
    //             const nonReservation = acc[item.awb + item.facility.id]?.nonReservationFee || 0;
    //             const currentNonReservation = item.nonReservationFee || 0;
    //             acc[item.awb + item.facility.id] = acc[item.awb + item.facility.id] || [];
    //             acc[item.awb + item.facility.id].awb = item.awb;
    //             acc[item.awb + item.facility.id].facilityName = item.facility.name;
    //             acc[item.awb + item.facility.id].discountNonReservationFee =
    //                 acc[item.awb + item.facility.id].discountNonReservationFee || false;
    //             acc[item.awb + item.facility.id].nonReservationFee =
    //                 currentNonReservation > nonReservation ? currentNonReservation : nonReservation;
    //             acc[item.awb + item.facility.id].push(item);
    //             return acc;
    //         }, []);
    //         return Object.keys(newRequest).map((key: any) => newRequest[key]);
    //     }
    //     return [];
    // }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    deleteCart(): void {
        this._ngxSpinnerService.show();

        this.cartBillService
            .deleteAndGetCart()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.paymentRequest = [];
                    this.openInvoices = [];
                    this.returnEmptyCartEvent(true);
                    this._ngxSpinnerService.hide();
                },
                error: (error: any) => {
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method deletePaymentRequest()
     * @param (paymentRequest: any)
     * @description
     */

    deletePaymentRequest(paymentRequest: any): void {
        this._ngxSpinnerService.show();
        this.removeItem.add(paymentRequest);

        const url: string = `${this._cartUrl}/removePaymentRequest?id=${paymentRequest.id}`;

        this._restService
            .delete(url)
            .then((result: any) => {
                // this._cartService.setUpdate('DELETE');

                if (result.length === 0) {
                    // this._cartService.setUpdate('Delete');
                    this.returnEmptyCartEvent(true);
                }

                this._ngxSpinnerService.hide();
            })
            .catch((error: any): void => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    allowOutsideClick: false,
                });
            });
    }

    trackBy(index: number): number {
        return index;
    }

    returnEmptyCartEvent(isEmpty: boolean): void {
        this.returnEmptyCart.emit(isEmpty);
    }
}
