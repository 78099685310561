import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';
import { ErrorMatcher } from '../../../utils/error-matcher';

@Component({
    selector: 'app-dropdown',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatSelectModule],
    templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit {
    dynamic_required!: boolean;
    showTooltip: boolean;
    matcher: ErrorMatcher = new ErrorMatcher();

    @Input() field!: DynamicFieldDTO;
    @Input() dynamicFormGroup!: FormGroup;

    constructor() {
        this.showTooltip = false;
    }

    ngOnInit(): void {
        this._checkValidations();
    }

    /**
     * @method _checkValidations()
     * @description Review and set the validators for the field
     */

    private _checkValidations(): void {
        this.dynamic_required = (this.field?.inputValidations?.['required']?.value as boolean) || false;
    }
}
