<div class="cart-rates">
    <div class="flex-column flex-grow-1">
        <div *ngFor="let rate of rates">
            <div class="mat-caption title" *ngIf="rate.value">{{rate.label}}:</div>
        </div>
    </div>
    <div class="flex-column ms-3">
        <div *ngFor="let rate of rates">
            <div class="mat-caption information" *ngIf="rate.value">{{rate.value | currency}}</div>
        </div>
    </div>
</div>
