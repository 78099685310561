import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { Statuses } from 'src/app/utils/statuses-enum';

@Component({
    selector: 'app-status',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './status.component.html',
})
export class StatusComponent {
    Statuses = Statuses;
    @Input() data!: any;

    constructor() {}
}
