import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PaymentMethod, PaymentMethods, PaymentMethodsType } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { LogoPaymentMethodComponent } from 'src/app/standalone-components/logo-payment-method/logo-payment-method.component';
import { CargoCreditMaterialModule } from './cargo-credit-material-modules.module';

@Component({
    selector: 'app-cargo-credit',
    standalone: true,
    imports: [CommonModule, LogoPaymentMethodComponent, RouterModule, CargoCreditMaterialModule],
    templateUrl: './cargo-credit.component.html',
    styleUrls: ['./cargo-credit.component.scss'],
})
export class CargoCreditComponent implements OnInit, OnDestroy {
    public isAllowedToPayWithCargoCredit: boolean = false;
    public isThereInvoicesInCart?: boolean;
    public isEnoughCredit?: boolean;
    public cargoCredit?: PaymentMethod;
    public PaymentMethodsEnum = PaymentMethods;
    public paymentMethodSelected: PaymentMethodsType | null = null;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() aboveThreshold: boolean = false;
    @Output() eventOnSelectCargoCredit: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private customerFeaturesService: CustomerFeaturesService,
        private paymentMethodsService: PaymentMethodsService,
        private summaryService: SummaryService
    ) {}

    ngOnInit(): void {
        this.subscribeCargoSprintCredit();
        this.subscribeIsAllowedToPayWithCargoCredit();
        this.subscribePaymentMethodSelected();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCargoSprintCredit(): void {
        this.paymentMethodsService
            .getPaymentMethodsByType$(PaymentMethods.CARGO_CREDIT)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (cargoCredit: PaymentMethod | undefined) => {
                    this.cargoCredit = cargoCredit;
                },
            });
    }

    subscribeIsAllowedToPayWithCargoCredit(): void {
        this.customerFeaturesService
            .isAllowedToPayWithCargoCredit$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isAllowedToPayWithCargoCredit) => {
                this.isAllowedToPayWithCargoCredit = isAllowedToPayWithCargoCredit.isAllowed;
                this.isEnoughCredit = isAllowedToPayWithCargoCredit.isEnoughCredit;
                this.isThereInvoicesInCart = isAllowedToPayWithCargoCredit.isThereInvoicesInCart;
            });
    }

    selectPaymentMethod(): void {
        this.eventOnSelectCargoCredit.emit(PaymentMethods.CARGO_CREDIT);
    }

    subscribePaymentMethodSelected(): void {
        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((paymentMethodSelected) => {
                this.paymentMethodSelected = paymentMethodSelected?.method || null;
            });
    }
}
