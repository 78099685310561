import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ErrorInputFieldComponent, PromoCodeListResponse } from '@cargos/sprintpay-models';
import { PromoCodeService } from '@cargos/sprintpay-services';
import { Subject, finalize, map, switchMap, take, takeUntil } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { AwbPromoCode, PromoCodeByAWBService } from '../../../../services/promo-code.service';
import { PromoCodeFormGroupType } from './models/customer-reference-form';
import { PromoCodeList } from './models/promo-code-list-input';

@Component({
    selector: 'app-promo-code',
    templateUrl: './promo-code.component.html',
    styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit, OnDestroy {
    public promoCodeForm!: PromoCodeFormGroupType;
    public isLoading = false;
    public promoCodeApplied: string | undefined = '';
    public messageConfig!: ErrorInputFieldComponent[];
    public promoCodesList: PromoCodeList[] = [];
    private unsubscribe$ = new Subject<void>();

    @Input() awbNumber: string = '';
    @Output() eventOnOpenDropDown = new EventEmitter<unknown>();
    @Output() eventOnCloseDropDown = new EventEmitter<unknown>();

    constructor(
        private formBuilder: NonNullableFormBuilder,
        private promoCodeForAWB: PromoCodeByAWBService,
        private promoCodeService: PromoCodeService,
        private errorHandlerService: ErrorHandlerService,
        private promoCodeByAWBService: PromoCodeByAWBService
    ) {}

    get promoCode(): string {
        return this.promoCodeForm.get('promoCode')?.value || '';
    }

    ngOnInit(): void {
        this.getPromoCodes();
        this.createForm();
        this.subscribePromoCodesApplied();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createForm(): void {
        this.promoCodeForm = this.formBuilder.group({
            promoCode: ['', [Validators.required]],
        });
    }

    deletePromoCode(): void {
        this.promoCodeForAWB.removePromoCode(this.awbNumber, this.promoCode);
    }

    subscribePromoCodesApplied(): void {
        this.promoCodeByAWBService
            .getAllPromoCodeApplied$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (promoCodesApplied) => {
                    this.promoCodeApplied = promoCodesApplied.find(
                        (promocodeApplied) => promocodeApplied.awb === this.awbNumber
                    )?.promoCodeId;
                    this.promoCodeForm.patchValue({
                        promoCode: this.promoCodeApplied,
                    });
                },
            });
    }

    validatePromoCode(): void {
        if (!this.promoCodeForm.valid) {
            return;
        }

        if (this.promoCodeForAWB.findPromoCode(this.promoCode)) {
            this.setPromoCodeError('The promo code has already been used');
            return;
        }

        this.isLoading = true;

        this.promoCodeService
            .validatePromoCode(this.promoCode)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe({
                next: (response) => {
                    if (!response.isValid) {
                        this.setPromoCodeError(response.message || '');
                        return;
                    }
                    this.promoCodeForAWB.setPromoCodesApplied(this.awbNumber, response.promoCodeId || '', response.awb);
                },
                error: (error) => {
                    const errorMsg = this.errorHandlerService.errorMsg(error.error);
                    this.setPromoCodeError(errorMsg);
                },
            });
    }

    get errorVisible(): boolean {
        if (!this.promoCodeForm.get('promoCode')?.errors) {
            return false;
        }

        return this.promoCodeForm.touched || this.promoCodeForm.dirty;
    }

    setPromoCodeError(message: string): void {
        const messageList = [
            {
                code: 'invalid',
                message: message,
            },
        ];
        this.messageConfig = Array.isArray(messageList) ? messageList : [messageList];
        this.promoCodeForm.get('promoCode')?.setErrors({ invalid: true });
    }

    getPromoCodes(): void {
        this.isLoading = true;
        this.promoCodeByAWBService
            .getPromoCodesByUser$()
            .pipe(
                switchMap((promoCodesList: PromoCodeListResponse[]) => {
                    return this.promoCodeByAWBService.getAllPromoCodeApplied$().pipe(
                        map((awbPromoCodes: AwbPromoCode[]) => {
                            const promoCodestoRemove = new Set(
                                awbPromoCodes.map((promoCodeUsed) => promoCodeUsed?.promoCodeId)
                            );

                            return promoCodesList.filter(
                                (promoCode) => !promoCodestoRemove.has(promoCode?.promoCodeId)
                            );
                        })
                    );
                }),
                map((promoCodesList: PromoCodeListResponse[]) => {
                    return promoCodesList.map((promoCodeValue) => ({
                        name: promoCodeValue.promoCodeId,
                    }));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (promoCodeList) => {
                    this.isLoading = false;
                    this.promoCodesList = promoCodeList;
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }

    onOpenDropDown(): void {
        this.eventOnOpenDropDown.emit();
    }

    onCloseDropDown(): void {
        this.eventOnCloseDropDown.emit();
    }
}
