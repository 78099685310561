import { ComponentType } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, finalize, map, of, switchMap, take, takeUntil } from 'rxjs';
import { AuthenticationFluxComponent } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.component';
import { ResponseMFA, TypeModuleMFA } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { HandlerVerificationService } from 'src/app/services/handler-verification.service';
import { SignUpAPIService } from 'src/app/services/requests/signup-api.service';
import { RestService } from 'src/app/services/rest.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { regex } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';

@Component({
    selector: 'app-password-form',
    templateUrl: './password-form.component.html',
})
export class PasswordFormComponent implements OnInit, OnDestroy {
    public forgotPasswordForm: FormGroup;
    public isForgotPassword?: boolean;
    public matcher: ErrorMatcher;
    private isLoading = false;
    public moduleType!: TypeModuleMFA;
    private readonly _authentication: string;
    public isEditable: boolean = false;

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<AuthenticationFluxComponent>;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private _router: Router,
        private _handlerVerificationService: HandlerVerificationService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorHandlerService: ErrorHandlerService,
        private _tokenService: TokenService,
        private _userSessionService: UserSessionService,
        private _signupService: SignUpAPIService,
        private summaryService: SummaryService,
        private _matDialog: MatDialog,
        private _restService: RestService,
        private location: Location
    ) {
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.minLength(5),
                Validators.pattern(regex.email),
                Validators.email, // Adding email validator
            ]),
        });
        this._authentication = environment.uris.method.authentication;
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this.setupFormSubscription();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setupFormSubscription(): void {
        let url = this.location.path();
        this.isForgotPassword = url.endsWith('forgot-password');
        if (!this.isForgotPassword) {
            this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
                if (params['email']) {
                    let email = params['email'].replace(/'/g, '');
                    this.forgotPasswordForm.patchValue({ email: email });
                    this.isEditable = true;
                }
            });
        }
    }

    /**
     * @method _signInAsGuest()
     * @description:
     */

    private _signInAsGuest(): Observable<string> {
        const token: string | null = this._tokenService.getCurrentUser();

        if (!token) {
            return this._userSessionService.signInAsGuest().pipe(map(() => 'Complete'));
        }

        return of('Complete');
    }

    /**
     * @method getEmail()
     * @description: Convenience getter for easy access to form fields
     */

    get getEmail(): AbstractControl<string> | null {
        return this.forgotPasswordForm.get('email');
    }

    /**
     * @method setEmail()
     * @param (event: string )
     * @description Convenience setter for easy access to form fields
     */
    set setEmail(event: string) {
        this.getEmail!.setValue(event);
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.forgotPasswordForm.invalid || this.isLoading) {
            return;
        }

        this.isLoading = true;
        this._ngxSpinnerService.show();
        const email = this.getEmail?.value || '';
        this._signInAsGuest()
            .pipe(
                take(1),
                switchMap(() => this._signupService.validateEmail(email)),
                finalize(() => {
                    this.isLoading = false;
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                error: (error: any) => {
                    const verificationState = this._handlerVerificationService.getStateForResetPassword(error?.error);
                    let template;
                    if (error?.error?.error?.body) {
                        template = error.error.error.body;
                    } else {
                        template =
                            error?.response?.status == 422
                                ? this._errorHandlerService.errorMsg(error.response.data)
                                : this._errorHandlerService.errorMsg(error);
                    }

                    if (this.isNewAdminUser) {
                        this.summaryService.setGuestPaymentInformation({
                            personalInformation: { email: this.getEmail?.value },
                        });
                        this.navigateToSignUp();
                        return;
                    }

                    if (verificationState.requestMFA && !verificationState.requestPassword) {
                        this.moduleType = verificationState.activeAccount ? 'FORGOT_PASSWORD' : 'REGISTRATION';
                        this._handlerVerificationService.setActivationUuid(verificationState.activationUuid || '');
                        this.openFactorAuthentication();
                        return;
                    }

                    if (verificationState.requestPassword) {
                        this.summaryService.setGuestPaymentInformation({ personalInformation: { email } });
                        this.navigateToSignUp();
                        return;
                    }
                    if (error?.error?.error) {
                        Swal.fire({
                            title: 'Oops...',
                            html: `<div>${template}</div>`,
                            icon: 'error',
                            allowOutsideClick: false,
                            cancelButtonText: 'SIGN IN',
                            showCancelButton: true,
                            showCloseButton: true,
                        }).then((result) => {
                            if (result?.dismiss == Swal.DismissReason.cancel) {
                                this._router.navigate(['/login']);
                            }
                        });
                    }
                },
            });
    }

    /**
     * @method navigateToSignUp()
     * @description Redirect customer to SignUp
     */

    navigateToSignUp(): void {
        this._router.navigate(['signup-guest']);
    }

    openFactorAuthentication(): void {
        this._matDialog.open(this.authenticationFlux as ComponentType<AuthenticationFluxComponent>, {
            id: 'authenticationFlux',
            disableClose: true,
            width: '55em',
            data: {
                email: this.getEmail?.value,
                activationUuid: this._handlerVerificationService.instant_activationUuid,
                accountVerifiedHandler: (event: ResponseMFA) => this._accountVerifiedHandler(event),
            },
        });
    }

    private _accountVerifiedHandler(response: ResponseMFA): void {
        const apiKey = response?.dynamicResponseParameter?.activationKey;
        if (response?.isVerified && this.moduleType === 'FORGOT_PASSWORD' && apiKey) {
            this._router.navigate(['/password/new-password'], { queryParams: { activationKey: apiKey } });
            return;
        }
        if (response && response.isVerified) {
            const source: string = 'sprintpay';
            const url: string = `${this._authentication}/recoverPassword`;
            const email = this.getEmail?.value;
            this._restService
                .post(url, { data: { email, source } })
                .then(() => {
                    Swal.fire({
                        html: `<h4 class='swal2-title pt-0 mb-0' id='swal2-title'>Good job...</h4>
                    </br>We emailed you with instructions on how to reset your password`,
                        icon: 'info',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                    }).then(() => {
                        this._router.navigate(['/login']);
                    });
                })
                .catch((error: any) => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorTemplate(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                })
                .finally(() => {
                    this._ngxSpinnerService.hide();
                });
        }
    }

    goToHome(): void {
        this._router.navigate(['/']);
    }

    get isNewAdminUser(): boolean {
        let url = this.location.path();
        const hasAdminSource = url.includes('source=forwarder-admin');
        return hasAdminSource;
    }
}
