<mat-selection-list [multiple]="false" #paypal>
    <mat-list-option
        [value]="paymentMethodSelected"
        (click)="selectPaymentMethod()"
        [selected]="paymentMethodSelected === PaymentMethodsEnum.PAYPAL"
        checkboxPosition="before"
        color="primary"
    >
        <div class="d-flex flex-row align-items-center pe-3">
            <app-logo-payment-method
                [paymentMethodName]="PaymentMethodsEnum.PAYPAL"
                [width]="'2.3em'"
                [height]="'24px'"
                class="me-3"
                alt="Paypal logo"
            ></app-logo-payment-method>
            <div class="d-flex">Paypal</div>
        </div>
    </mat-list-option>
</mat-selection-list>
