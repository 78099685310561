import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AddressValidationResult } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Observable,
    Subject,
    catchError,
    distinctUntilChanged,
    finalize,
    forkJoin,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { CompanyDetails } from 'src/app/models/utils/company';

import { MatRadioChange } from '@angular/material/radio';
import { AddressInformationComponent, GoogleAddressValidationService } from '@cargos/sprintpay-ui';
import { AddressInformationFormType } from '@cargos/sprintpay-ui/lib/modules/address/address-form/models/address-information-form';
import { CartService } from 'src/app/services';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { GoogleAddressValidatorAdapter, accountTypeItems } from 'src/app/utils/constants';
import { restrictedBranch } from 'src/app/validators/restricted-branch';
import { environment } from 'src/environments/environment';
import { ErrorMatcher } from '../../../../../utils/error-matcher';
import { FormSignupService } from '../../services/form-signup.service';
import { AccountFormGroupType, AddressForm, AddressFormType, CompanyForm } from '../models/account-form';

@Component({
    selector: 'app-step-two',
    templateUrl: './step-two.component.html',
    styles: ['.useEntered { border: 1px solid #9E9E9E; border-radius: 5px; padding: 8px }'],
})
export class StepTwoComponent implements OnInit, OnDestroy {
    public matcher: ErrorMatcher;
    public emailHasDomain!: boolean;
    public branchLocations: CompanyDetails[];
    public suggestedAddress: AddressValidationResult | null;
    public inputAddress: AddressFormType | null;
    public ignoredAddress: AddressFormType | null;
    public suggestedBillingAddress: AddressValidationResult | null;
    public inputBillingAddress: AddressFormType | null;
    public ignoredBillingAddress: AddressFormType | null;
    // TODO: Verify AddresInformation with AddressInformationFormType - low
    public instantGuestAddressInformation?: AddressInformationFormType | null;
    public accountTypes = accountTypeItems;
    public addressValidation: boolean = true;
    public addressBillingValidation: boolean = true;
    public unconfirmedAddressFound: boolean = false;
    public unconfirmedBillingAddressFound: boolean = false;
    private unsubscribe$: Subject<void>;

    @Input() accountForm!: AccountFormGroupType;
    @Input() stepper!: MatStepper;
    @ViewChild('addressFormTemplate') addressFormTemplate: AddressInformationComponent;
    @ViewChild('billingAddressFormTemplate') billingAddressFormTemplate: AddressInformationComponent;

    constructor(
        private formSignupService: FormSignupService,
        private googleAddressValidationService: GoogleAddressValidationService,
        private ngxSpinnerService: NgxSpinnerService,
        private cartService: CartService,
        private changeDetectorRef: ChangeDetectorRef,
        private summaryService: SummaryService
    ) {
        this.matcher = new ErrorMatcher();
        this.unsubscribe$ = new Subject<void>();
        this.branchLocations = [];
        this.suggestedAddress = null;
        this.inputAddress = null;
        this.suggestedBillingAddress = null;
        this.inputBillingAddress = null;
    }

    ngOnInit(): void {
        if (!this.billingAddress.value) {
            this.setBillingAddressValidators();
        }
        this.subscribeEmailDomain();
        this.onChangesUseBillingAddress();
        if (this.summaryService.instant_guest_address_information) {
            this.instantGuestAddressInformation = this.summaryService.instant_guest_address_information;
        }
        this.watchAddressesChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    disableGoogleAddresValidation(form: string): void {
        if (form === 'address') {
            this.addressValidation = !this.addressValidation;
            if (!this.addressValidation) {
                this.googleAddressValidationService.disableGoogleAddresValidation(this.addressForm);
            }
        } else if (form === 'billing') {
            this.addressBillingValidation = !this.addressBillingValidation;
            if (!this.addressBillingValidation) {
                this.googleAddressValidationService.disableGoogleAddresValidation(this.billingAddressForm);
            }
        }
    }

    trackBy(index: number, item: any): string {
        return item.id;
    }

    private findErrorUnconfirmed(form: string, formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((field: string) => {
            if (formGroup.controls[field].hasError('unconfirmed')) {
                if (form === 'address') {
                    this.unconfirmedAddressFound = true;
                    this.handleValidationError(this.addressForm);
                } else if (form === 'billing') {
                    this.unconfirmedBillingAddressFound = true;
                    this.handleBillingValidationError(this.billingAddressForm);
                }
            }
        });
    }

    private subscribeEmailDomain(): void {
        this.formSignupService
            .getEmailDomainExist()
            .pipe(
                switchMap((emailHasDomain) => {
                    this.emailHasDomain = emailHasDomain;
                    if (!emailHasDomain) {
                        return of(null);
                    }
                    return this.formSignupService.getBrachLocation();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (completeBranches: CompanyDetails[] | null) => {
                    if (this.emailHasDomain && completeBranches) {
                        const { parentCompany, branches } = this.getBranchesAndParentCompany(completeBranches);
                        this.branchLocations = branches || [];
                        this.companyNameForm?.setValue(parentCompany?.accountName || '');
                        this.companyIdForm?.setValue((parentCompany?.id || -1).toString());
                        this.setAccountType({ value: true } as MatRadioChange);
                        if (this.branchLocations.length) {
                            this.branchLocationForm?.addValidators([Validators.required, restrictedBranch()]);
                            this.accountForm.updateValueAndValidity();
                        }
                        this.clearValidators();
                        this.clearBillingAddressValidators();
                        this.updateValueAndValidityAccountForm();
                        this.setAccountRestricted(parentCompany?.accountSignUpRestricted || false);
                    }
                },
            });
    }

    private clearValidators(): void {
        this.streetAddressForm?.clearValidators();
        this.cityAddressForm?.clearValidators();
        this.stateAddressForm?.clearValidators();
        this.countryAddressForm?.clearValidators();
        this.zipCodeAddressForm?.clearValidators();
    }

    private updateValueAndValidityAccountForm(): void {
        this.streetAddressForm?.updateValueAndValidity();
        this.cityAddressForm?.updateValueAndValidity();
        this.stateAddressForm?.updateValueAndValidity();
        this.countryAddressForm?.updateValueAndValidity();
        this.zipCodeAddressForm?.updateValueAndValidity();
        this.streetBillingForm?.updateValueAndValidity();
        this.cityBillingForm?.updateValueAndValidity();
        this.stateBillingForm?.updateValueAndValidity();
        this.countryBillingForm?.updateValueAndValidity();
        this.zipCodeBillingForm?.updateValueAndValidity();
    }

    private setBillingAddressValidators(): void {
        this.streetBillingForm?.addValidators([Validators.required]);
        this.cityBillingForm?.addValidators([Validators.required]);
        this.stateBillingForm?.addValidators([Validators.required, Validators.maxLength(2), Validators.minLength(2)]);
        this.countryBillingForm?.addValidators([Validators.required]);
        this.zipCodeBillingForm?.addValidators([Validators.required]);
    }

    private clearBillingAddressValidators(): void {
        this.streetBillingForm?.clearValidators();
        this.cityBillingForm?.clearValidators();
        this.stateBillingForm?.clearValidators();
        this.countryBillingForm?.clearValidators();
        this.zipCodeBillingForm?.clearValidators();
    }

    private onChangesUseBillingAddress(): void {
        this.billingAddress.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((valueChanges) => {
            if (!valueChanges) {
                this.billingAddressForm.patchValue({
                    street: '',
                    street2: '',
                    city: '',
                    state: '',
                    country: '',
                    zipCode: '',
                });
                this.setBillingAddressValidators();
            } else {
                this.clearBillingAddressValidators();
            }
            this.suggestedBillingAddress = null;
            this.inputBillingAddress = null;
            this.updateValueAndValidityAccountForm();
        });
    }

    onSubmit(): void {
        this.ngxSpinnerService.show();
        const addressesToValidate = this.getAddressesToValidate();
        this.suggestedAddress = null;
        this.inputAddress = null;
        this.suggestedBillingAddress = null;
        this.inputBillingAddress = null;

        forkJoin(addressesToValidate)
            .pipe(
                take(1),
                finalize(() => {
                    this.ngxSpinnerService.hide();

                    if (this.accountForm.valid && !this.suggestedAddress && !this.suggestedBillingAddress) {
                        this.nextStep();
                    }
                }),
                catchError(() => of(null))
            )
            .subscribe();
    }

    private getBranchesAndParentCompany(completeBranches: CompanyDetails[]): {
        branches: CompanyDetails[];
        parentCompany: CompanyDetails | undefined;
    } {
        const parentCompany = completeBranches.find((branch) => branch.isParent);

        let branches =
            completeBranches.length === 1
                ? completeBranches.filter((branch) => branch.id !== parentCompany?.id)
                : completeBranches;

        if (branches?.length > 1) {
            branches = branches.map((branch) =>
                branch.id === parentCompany?.id ? { ...branch, accountName: 'Headquarter' } : branch
            );
        }

        return {
            branches,
            parentCompany,
        };
    }

    private nextStep(): void {
        if (this.billingAddress.value) {
            this.billingAddressForm.patchValue({
                ...this.addressForm.getRawValue(),
            });
        }

        this.suggestedAddress = null;
        this.inputAddress = null;
        this.suggestedBillingAddress = null;
        this.inputBillingAddress = null;

        this.formSignupService.setAccountInformation(this.accountForm.getRawValue());
        this.stepper.next();
    }

    private getAddressesToValidate(): Observable<AddressValidationResult | null>[] {
        const addressFormValue = this.addressForm.getRawValue();
        let addressesToValidate: Observable<AddressValidationResult | null>[] = [];

        if (this.emailHasDomain) {
            return [of(null)];
        }

        if (JSON.stringify(this.ignoredAddress) !== JSON.stringify(addressFormValue)) {
            this.ignoredAddress = null;
            if (this.addressValidation) {
                addressesToValidate.push(
                    this.googleAddressValidationService
                        .validate(
                            this.googleAddressValidationService.adaptAddressToValidate(addressFormValue),
                            GoogleAddressValidatorAdapter,
                            environment.googleAddressValidationApiKey
                        )
                        .pipe(
                            tap((result: AddressValidationResult) => {
                                const foundError = this.googleAddressValidationService.handleValidationResult(
                                    result,
                                    this.addressForm
                                );
                                foundError && this.handleValidationError(this.addressForm, result);
                                this.findErrorUnconfirmed('address', this.addressForm);
                            }),
                            catchError(() => of(null))
                        )
                );
            }
        }

        if (this.billingAddress.value) return addressesToValidate;

        const billingAddressFormValue = this.billingAddressForm.getRawValue();

        if (JSON.stringify(this.ignoredBillingAddress) !== JSON.stringify(billingAddressFormValue)) {
            this.ignoredBillingAddress = null;
            if (this.addressBillingValidation) {
                addressesToValidate.push(
                    this.googleAddressValidationService
                        .validate(
                            this.googleAddressValidationService.adaptAddressToValidate(billingAddressFormValue),
                            GoogleAddressValidatorAdapter,
                            environment.googleAddressValidationApiKey
                        )
                        .pipe(
                            tap((result: AddressValidationResult) => {
                                const foundError = this.googleAddressValidationService.handleValidationResult(
                                    result,
                                    this.billingAddressForm
                                );
                                foundError && this.handleBillingValidationError(this.billingAddressForm, result);
                                this.findErrorUnconfirmed('billing', this.billingAddressForm);
                            }),
                            catchError(() => of(null))
                        )
                );
            }
        }

        return addressesToValidate.length ? addressesToValidate : [of(null)];
    }

    private handleValidationError(form: FormGroup<AddressForm>, result?: AddressValidationResult): void {
        this.inputAddress = form.getRawValue();
        if (result) {
            this.suggestedAddress = result;
        }
    }

    private handleBillingValidationError(form: FormGroup<AddressForm>, result?: AddressValidationResult): void {
        this.inputBillingAddress = form.getRawValue();
        if (result) {
            this.suggestedBillingAddress = result;
        }
    }

    public handleSuggestedAddress(event: boolean): void {
        this.googleAddressValidationService
            .getSuggestionAddressRawForm(this.suggestedAddress)
            .pipe(take(1))
            .subscribe((address) => {
                if (event) {
                    this.addressForm.patchValue(address);
                } else {
                    this.ignoredAddress = this.addressFormRaw;
                    this.googleAddressValidationService.clearSuggestionError(this.addressForm);
                    this.disableGoogleAddresValidation('address');
                    this.unconfirmedAddressFound = false;
                }
                this.suggestedAddress = null;
                this.inputAddress = null;
            });
    }

    public handleSuggestedBillingAddress(event: boolean): void {
        this.googleAddressValidationService
            .getSuggestionAddressRawForm(this.suggestedBillingAddress)
            .pipe(take(1))
            .subscribe((billingAddress) => {
                if (event) {
                    this.billingAddressForm.patchValue(billingAddress);
                } else {
                    this.ignoredBillingAddress = this.billingAddressFormRaw;
                    this.googleAddressValidationService.clearSuggestionError(this.billingAddressForm);
                    this.disableGoogleAddresValidation('billing');
                    this.unconfirmedBillingAddressFound = false;
                }
                this.suggestedBillingAddress = null;
                this.inputBillingAddress = null;
            });
    }

    private watchAddressesChanges(): void {
        this.addressForm.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
            )
            .subscribe(() => {
                this.ignoredAddress = null;
            });
        this.billingAddressForm.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
            )
            .subscribe(() => {
                this.ignoredAddress = null;
            });
    }

    private setAccountRestricted(isRestricted: boolean): void {
        this.companyNameForm?.markAsUntouched();

        if (isRestricted) {
            this.companyNameForm?.setErrors({ restrictedBranch: true });
        }
        this.companyNameForm?.markAsTouched();
        this.changeDetectorRef.detectChanges();
    }

    setAccountType(accountType: MatRadioChange): void {
        if (accountType.value) {
            this.companyNameForm?.setValidators(Validators.required);
        } else {
            this.companyNameForm?.removeValidators(Validators.required);
        }
        this.companyNameForm?.updateValueAndValidity();
    }

    get selectedAccountType(): number | null {
        return this.accountAccountType?.value !== '' ? +this.accountAccountType?.value : null;
    }

    get accountAccountType(): FormControl<string> {
        return this.accountForm.controls['accountType'];
    }

    get addressForm(): FormGroup<AddressForm> {
        return this.accountForm.controls['addressForm'];
    }

    get addressFormRaw(): AddressFormType {
        return this.accountForm.controls['addressForm'].getRawValue();
    }

    get billingAddressForm(): FormGroup<AddressForm> {
        return this.accountForm.controls['billingAddressForm'];
    }

    get billingAddressFormRaw(): AddressFormType {
        return this.accountForm.controls['billingAddressForm'].getRawValue();
    }

    get billingAddress(): FormControl<boolean> {
        return this.accountForm.controls['billingAddress'];
    }

    get companyForm(): FormGroup<CompanyForm> {
        return this.accountForm.controls['companyForm'];
    }

    get companyNameForm(): FormControl<string> {
        return this.companyForm.get('companyName') as FormControl<string>;
    }

    get companyIdForm(): AbstractControl<string> | null {
        return this.companyForm.get('companyId');
    }

    get companyTaxIdForm(): FormControl<string> {
        return this.companyForm.get('taxID') as FormControl<string>;
    }

    get branchLocationForm(): AbstractControl | null {
        return this.companyForm.get('branchLocation');
    }

    get streetAddressForm(): AbstractControl | null {
        return this.addressForm.get('street');
    }

    get cityAddressForm(): AbstractControl | null {
        return this.addressForm.get('city');
    }

    get stateAddressForm(): AbstractControl | null {
        return this.addressForm.get('state');
    }

    get countryAddressForm(): AbstractControl | null {
        return this.addressForm.get('country');
    }

    get zipCodeAddressForm(): AbstractControl | null {
        return this.addressForm.get('zipCode');
    }

    get streetBillingForm(): AbstractControl | null {
        return this.billingAddressForm.get('street');
    }

    get cityBillingForm(): AbstractControl | null {
        return this.billingAddressForm.get('city');
    }

    get stateBillingForm(): AbstractControl | null {
        return this.billingAddressForm.get('state');
    }

    get countryBillingForm(): AbstractControl | null {
        return this.billingAddressForm.get('country');
    }

    get zipCodeBillingForm(): AbstractControl | null {
        return this.billingAddressForm.get('zipCode');
    }
}
