import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { VinEdocNextgenPipe } from '../../pipes/vin-edoc-nextgen.pipe';
import { PaymentService } from '../../services/payment.service';
import { RestService } from '../../services/rest.service';
import { CustomerService } from '../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../services/utils/payment-flux.service';
import { SessionService } from '../../services/utils/session.service';
import { Dynamic } from '../../utils/companies/dynamic';
import { companyName } from '../../utils/constants';
import { DetailsFieldComponent } from '../details-section/details-field/details-field.component';
import { DetailsHeaderComponent } from '../details-section/details-header/details-header.component';
import { FilesContainerComponent } from '../files/files-container';
export interface Detail {
    label: string;
    value: string;
    show?: boolean;
}
@Component({
    selector: 'app-payment-details',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        VinEdocNextgenPipe,
        FilesContainerComponent,
        DetailsHeaderComponent,
        DetailsFieldComponent,
    ],
    templateUrl: './payment-details.component.html',
})
export class PaymentDetailsComponent implements OnInit {
    hasExternalLookup: boolean = false;
    awbLabel: any;
    dynamicValuesDetails: any;
    addedPayments: any[];
    geodisUser: boolean;
    id!: string;
    geodisDomain: boolean;
    isStartingLevel: boolean;
    replacedFields: any;
    dynamicLabels: any;
    dynamicFieldsReplaced: any;
    paymentDetails!: any;
    private _customer: any;
    private _totalPayments: number;
    private readonly _companyName: any;
    private readonly _company: any;
    private readonly _cartUrl: string;
    private readonly _currentPayment: any;
    private _dynamicFields: any;

    @Input() set paymentDetail(paymentDetail: any) {
        if (paymentDetail) {
            this.paymentDetails = paymentDetail;

            this.hasExternalLookup =
                paymentDetail && paymentDetail.externalLookup
                    ? paymentDetail.externalLookup
                    : this._currentPayment && this._currentPayment.facility
                      ? this._currentPayment.facility.externalLookup
                      : false;
        }
    }
    @Input() values: Detail[];
    @Input() files: any;
    @Input() nameFiles: any;
    @Input() set replacementFields(replacementFields: any) {
        this.replacedFields = replacementFields;
        if (replacementFields) {
            this.dynamicLabels = this._paymentService.getLabelsdinamicAndReplaceable(
                Dynamic.ReplacementFieldsDetails,
                replacementFields
            );
        }
    }

    constructor(
        private _restService: RestService,
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService,
        private _customerService: CustomerService,
        private _router: Router,
        private _domSanitizer: DomSanitizer,
        private _paymentService: PaymentService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.dynamicValuesDetails = {};
        this._customer = this._customerService.getCustomer();
        this._company = this._customer?.approvalLevels?.company || '';
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this.geodisUser = this._companyName === companyName.geodis;
        this._dynamicFields = this._paymentFluxService.getDynamicFields();
        this._cartUrl = environment.uris.method.cart;
        this.addedPayments = [];
        this.dynamicLabels = [];
        this.dynamicFieldsReplaced = [];
        this._totalPayments = 0;
        this.geodisDomain = this._customerService.isCustomerEmailInDomain(companyName.geodis.toLowerCase());
        this.isStartingLevel = this._customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.id =
            this.paymentDetails && this.paymentDetails.id
                ? this.paymentDetails.id
                : this._currentPayment !== undefined
                  ? this._currentPayment.id
                  : undefined;
        this.getFormFields();
        this._getPayments();
        this.getLabels();
        this.awbLabel = this._verifyTypeOfFacility(
            this._currentPayment !== undefined ? this._currentPayment : this.paymentDetails
        );
    }

    /**
     * @method downloadFile()
     * @param (index: any)
     * @description
     */

    downloadFile(index: any) {
        const url: string = `${this._cartUrl}/getPaymentRequestAttachment/?&attachmentLocation=${this.files[index]}`;

        this._restService.getFiles(url, {}).then((result: any): void => {
            const link: HTMLAnchorElement = document.createElement('a');
            const file: Blob = new Blob([result], { type: result.type });
            const url: any = this._domSanitizer.sanitize(
                SecurityContext.URL,
                this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
            );
            link.href = url;
            link.download = this.nameFiles[index];
            link.click();
            window.URL.revokeObjectURL(url);
        });
    }

    /**
     * @method _verifyTypeOfFacility()
     * @param (currentPayment: any)
     * @description
     */

    private _verifyTypeOfFacility(currentPayment: any) {
        if (currentPayment && currentPayment.facility) {
            if (currentPayment.facility.externalLookup) {
                return 'File Number';
            } else {
                if (currentPayment.facility.awbFieldType === 'AWB') {
                    return this.isStartingLevel ? 'RELATED BOL / AWB #' : currentPayment.facility.awbFieldLabel;
                } else {
                    return currentPayment.facility.awbFieldLabel;
                }
            }
        }
    }

    /**
     * @method _getPayments()
     * @description
     */

    private _getPayments() {
        this.addedPayments = this._sessionService.getElement('payments_selected');
        this._totalPayments = 0;
        if (this.addedPayments) {
            this.addedPayments.map((item: any) => {
                this._totalPayments = this._totalPayments + item.amount;
            });
        }
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest() {
        this.paymentDetails.step = 1;
        this._paymentFluxService.setCurrentPayment(this.paymentDetails);
        if (!this._paymentFluxService.getDynamicValues()) {
            this._paymentFluxService.setDynamicValues(this.paymentDetails.dynamicValues);
        }
        this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
    }

    getLabels() {
        if (this.paymentDetails && this.paymentDetails.details && this._dynamicFields && this._dynamicFields[1]) {
            Object.keys(this._dynamicFields[1]).forEach((key: any) => {
                if (this._dynamicFields[1][key].isReplacement === false) {
                    this.dynamicValuesDetails[this._dynamicFields[1][key].label] =
                        this.paymentDetails.details[this._dynamicFields[1][key].name];
                }
            });
        }
    }

    keys(): Array<string> {
        return Object.keys(this.dynamicValuesDetails);
    }

    getFormFields(): void {
        const replacementFields = Dynamic.ReplacementFieldsDetails;
        if (!this._dynamicFields && this._companyName !== '') {
            this._dynamicFields = {};
            this._paymentService.getFormFields('1').then((result): void => {
                result.map((item) => {
                    if (this.paymentDetails.dynamicValues && item.id && !item.isReplacement) {
                        this.paymentDetails.details[item.name] =
                            this.paymentDetails.dynamicValues[item.id] !== ''
                                ? this.paymentDetails.dynamicValues[item.id] !== undefined
                                    ? this.paymentDetails.dynamicValues[item.id]
                                    : 'N/A'
                                : 'N/A';
                    }
                    if (this.paymentDetails.dynamicValues && item.id && item.isReplacement) {
                        if (replacementFields.indexOf(item.name) !== -1) {
                            this.dynamicLabels[`${item.name}`] = item.label;
                        }
                    }
                });
                this._dynamicFields[1] = result;
                this.getLabels();
            });
        }
    }

    get paymentAmount(): number | string {
        if (!this.addedPayments) {
            return !!this.paymentDetails.amount ? this.paymentDetails.amount : this.paymentDetails?.details?.amount;
        } else if (this.addedPayments && this.addedPayments.length === 1) {
            return this.addedPayments[0].amount;
        }

        return 0;
    }
}
