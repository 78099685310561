<section id="payment-detail" class="p-4">
    <div class="col-12 p-0">
        <!-- START: PAYMENT METHOD TEMPLATES GUEST -->
        <!-- <ng-container *ngxPermissionsOnly="'GUEST'">
                <app-guest-payment-method [paymentMethod]="paymentMethod"></app-guest-payment-method>
            </ng-container> -->
        <!-- END: PAYMENT METHOD TEMPLATES GUEST -->
        <span class="mat-headline-6 d-block mb-4">Balance</span>
        <!-- START: SUBTOTAL -->
        <div *ngIf="subTotal" class="d-flex justify-content-start flex-row align-items-center mb-3">
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100">Subtotal</span>
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end"
                    >USD {{ subTotal | currency }}</span
                >
            </div>
        </div>
        <!-- END: SUBTOTAL -->
        <!-- START: ISC FEE -->
        <div *ngIf="cartBill && iscFee" class="d-flex justify-content-start flex-row align-items-center mb-3">
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100"
                    >{{hasAGIPayments ? 'AGI Convenience Fee (ISC)':'ISC'}}</span
                >
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end">
                    USD {{ iscFee | currency }}
                </span>
            </div>
        </div>
        <!-- END: ISC FEE -->
        <!-- START: STORAGE -->
        <div *ngIf="cartBill && nonIscFee" class="d-flex justify-content-start flex-row align-items-center mb-3">
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100"
                    >{{hasAGIPayments ? 'AGI Convenience Fee (Storage/Others)':'Storage/Others'}}</span
                >
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end"
                    >USD {{ nonIscFee | currency }}</span
                >
            </div>
        </div>
        <!-- END: STORAGE -->
        <!-- START: CUSTOMS FEE -->
        <div *ngIf="cartBill && customsFee" class="d-flex justify-content-start flex-row align-items-center mb-3">
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100"
                    >{{ cartBill.customsFeeLabel }}</span
                >
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end"
                    >USD {{ customsFee | currency }}</span
                >
            </div>
        </div>
        <!-- END: CUSTOMS FEE -->
        <!-- START: CUSTOMS FEE -->
        <div
            *ngIf="cartBill && nonReservationFee"
            class="d-flex justify-content-start flex-row align-items-center mb-3"
        >
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100">Front Counter Service Fee</span>
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end"
                    >USD {{ nonReservationFee | currency }}</span
                >
            </div>
        </div>
        <!-- END: CUSTOMS FEE -->
        <!-- START: HANDLINGFEE -->
        <div
            *ngIf="cartBill && handlingFeeTotal >= 0 && !invoicesInCart"
            class="d-flex justify-content-start flex-row align-items-center mb-3"
        >
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100">Handling fee</span>
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end">
                    USD {{ handlingFeeTotal | currency }}
                </span>
            </div>
        </div>
        <!-- END: HANDLINGFEE -->
        <!-- START: CREDIT CARD FEE -->
        <div
            *ngIf="cartBill && creditCardFeeTotal >= 0 && isCreditCardFeeAvailable"
            class="d-flex justify-content-start flex-row align-items-center mb-3"
        >
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-body-2 payment-detail--split--titles d-block w-100">Credit card fee</span>
                <span class="mat-subtitle-1 payment-detail--split--amounts fw-bold w-100 text-end">
                    USD {{ creditCardFeeTotal | currency }}
                </span>
            </div>
        </div>
        <!-- END: CREDIT CARD FEE -->
        <!-- <mat-divider class="m-3 mb-4"></mat-divider> -->
        <!-- START: TOTAL -->
        <div class="space"></div>
        <div
            *ngIf="cartBill && cartBill.total && (paymentMethodBelowThreshold || paymentMethodAboveThreshold)"
            class="d-flex justify-content-start flex-row align-items-center mb-3 container-total"
        >
            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                <span class="mat-subtitle-1 payment-detail--split--titles m-0 d-block w-100 text-start text-lg-end">
                    TOTAL
                </span>
                <span class="mat-headline-6 payment-detail--split--amounts fw-bold w-100 text-end">
                    USD {{ cartBill.total | currency }}
                </span>
            </div>
        </div>
        <!-- END: TOTAL -->
        <!-- START: GUEST TERMS OF USE CHECKBOX -->
        <div class="mb-4">
            <app-terms-of-use></app-terms-of-use>
        </div>
        <!-- END: GUEST TERMS OF USE CHECKBOX -->
        <!-- START: PAY BUTTON AND WARNING -->
        <div
            class="mt-3"
            *ngIf="paymentMethodBelowThreshold !== paymentMethods.PAYPAL && paymentMethodAboveThreshold !== paymentMethods.PAYPAL"
        >
            <app-pay-button></app-pay-button>
            <span
                class="d-flex align-items-center justify-content-center p-3 mt-3 fw-normal payment-warning__msg"
                *ngIf="!isInvoiceCheckLimitValid && paymentMethodBelowThreshold === paymentMethods.CARGO_CREDIT"
                ><span class="material-symbols-outlined me-1">exclamation</span>Your total request is over the limit of
                your total balance available limit, please send an email to: support&#64;cargosprint.com</span
            >
        </div>
        <!-- END: PAY BUTTON AND WARNING -->
        <!-- START: PAYPAL -->
        <div *ngIf="paymentMethodBelowThreshold === paymentMethods.PAYPAL && !isCartAboveThreshold" class="my-3">
            <app-paypal-button></app-paypal-button>
        </div>
        <!-- END: PAYPAL -->
    </div>
    <!-- </div> -->
</section>
