<div
    class="container-brand-logo d-inline-flex justify-content-center align-items-center"
    [class.bg-gray-lighter]="!logo"
>
    <ng-container *ngIf="logo">
        <app-image-loader
            src="assets/images/payment-methods/types/{{logo}}.svg"
            [alt]="logo + '-logo'"
            [title]="logo + '-logo'"
            [width]="width"
            [height]="height"
        >
        </app-image-loader>
    </ng-container>
</div>
