import { Injectable } from '@angular/core';
import {
    Balance,
    BraintreeException,
    CardMethodsType,
    ComponentPaymentMethodName,
    ForteException,
    OrderIdPaymentMethod,
    PaymentMethod,
    PaymentMethodItem,
    PaymentMethods,
    PaymentMethodsEvents,
    PaymentMethodsType,
    PaymentToken,
} from '@cargos/sprintpay-models';
import {
    BraintreeService,
    ErrorBraintreeHandlerService,
    ErrorForteHandlerService,
    PaymentMethodsRequestService,
} from '@cargos/sprintpay-services';
import { PayPalCheckout } from 'braintree-web';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentMethodDetails, PaymentMethodsLatest } from '../models/payments/payment-methods';
import { calculateSprintpayCredit } from '../utils/calculate-sprintpay-credit';
import { PaymentMethodSelected } from '../utils/cart-types';
import { CartBillService } from './utils/cart/cart-service';
import { CustomerService } from './utils/customer-handler.service';
import { UserSessionService } from './utils/user-session.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentMethodsService {
    private handlerEvent: BehaviorSubject<PaymentMethodsEvents | null> =
        new BehaviorSubject<PaymentMethodsEvents | null>(null);
    private paymentMethods = new BehaviorSubject<PaymentMethod[]>([]);
    private guestPaymentMethods: PaymentMethodsType[] = [PaymentMethods.CREDIT_CARD, PaymentMethods.PAYPAL];
    private getPaymentMethodsInProcess = new BehaviorSubject<boolean>(false);
    private paymentMethodAddFromCart = new BehaviorSubject<boolean>(false);

    constructor(
        private customerService: CustomerService,
        private userSessionService: UserSessionService,
        private paymentMethodsRequestService: PaymentMethodsRequestService,
        private cartBillService: CartBillService,
        private braintreeService: BraintreeService,
        private errorBraintreeHandlerService: ErrorBraintreeHandlerService,
        private errorForteHandlerService: ErrorForteHandlerService
    ) {}

    newEventPaymentMethod(event: PaymentMethodsEvents): void {
        this.handlerEvent.next(event);
    }

    onEventPaymentMethod$(): Observable<PaymentMethodsEvents | null> {
        return this.handlerEvent.asObservable();
    }

    get instant_payment_methods(): PaymentMethod[] {
        return this.paymentMethods.value;
    }

    setPaymentMethods(paymentMethods: PaymentMethod[]): void {
        this.paymentMethods.next(paymentMethods);
    }

    getPaymentMethods$(): Observable<PaymentMethod[]> {
        return this.paymentMethods.asObservable();
    }

    getPaymentMethodsRequest$(): Observable<PaymentMethod[]> {
        return this.userSessionService.isAuthenticated$().pipe(
            switchMap((isAuthenticated) => {
                if (isAuthenticated) {
                    this.setIsPaymentMethodsLoading(true);
                    return this.paymentMethodsRequestService.getPaymentMethods().pipe(
                        map((paymentMethods: PaymentMethod[]) => this.buildPaymentsMethods(paymentMethods)),
                        map((paymentMethods: PaymentMethod[]) => this.sortPaymentMethods(paymentMethods)),
                        map((paymentMethods: PaymentMethod[]) => {
                            this.setPaymentMethods(paymentMethods);
                            this.setIsPaymentMethodsLoading(false);
                            return paymentMethods;
                        }),
                        catchError((error) => {
                            this.setPaymentMethods([]);
                            this.setIsPaymentMethodsLoading(false);
                            return throwError(() => error);
                        })
                    );
                }

                return this.getGuestPaymentMethod$().pipe(
                    map((paymentMethods: PaymentMethod[]) => this.buildPaymentsMethods(paymentMethods)),
                    map((paymentMethods: PaymentMethod[]) => this.sortPaymentMethods(paymentMethods)),
                    map((paymentMethods: PaymentMethod[]) => {
                        this.setPaymentMethods(paymentMethods);
                        return paymentMethods;
                    })
                );
            })
        );
    }

    private buildPaymentsMethods(paymentMethods: PaymentMethod[]): PaymentMethod[] {
        paymentMethods.forEach((paymentMethod) => {
            if (paymentMethod.name === PaymentMethods.CARGO_CREDIT) {
                const [avaliableCredit, creditPercentage] = calculateSprintpayCredit(
                    paymentMethod.balance?.avaliableCredit,
                    paymentMethod.balance?.creditLimit
                );

                paymentMethod.balance = {
                    avaliableCredit,
                    creditPercentage,
                    creditLimit: paymentMethod.balance?.creditLimit,
                };
            }
        });

        return paymentMethods;
    }

    private setIsPaymentMethodsLoading(isLoading: boolean): void {
        this.getPaymentMethodsInProcess.next(isLoading);
    }

    getPaymentMethodsInProcess$(): Observable<boolean> {
        return this.getPaymentMethodsInProcess.asObservable();
    }

    getGuestPaymentMethod$(): Observable<PaymentMethod[]> {
        const paymentMethodArray: PaymentMethod[] = [];

        this.guestPaymentMethods.map((method, index) => {
            const token = this.getGuestPaymentToken(method);

            const paymentMethod = PaymentMethod.fromJson({
                id: 0,
                name: method,
                paymentToken: token
                    ? token
                    : {
                          token: null,
                          paymentMethod: null,
                          paymentProcessor: null,
                      },
                balance: {},
                items: [],
                orderId: index,
            });

            paymentMethodArray.push(paymentMethod);
        });
        return of(paymentMethodArray);
    }

    getGuestPaymentToken(method: PaymentMethodsType): PaymentToken | undefined {
        const sourceToken = this.customerService.source;

        return sourceToken?.enabledPaymentMethods?.find(
            (guestMethod) => guestMethod.paymentMethod?.componentName === method
        )?.paymentMethod?.paymentToken;
    }

    private buildPaymentToken(paymentMethodSelected: PaymentMethod, token?: string): PaymentToken | undefined {
        const isAuthenticated = this.userSessionService.isAuthenticated();

        if (
            PaymentMethods.CARD_METHODS.includes(paymentMethodSelected.name as CardMethodsType) &&
            token &&
            isAuthenticated
        ) {
            const item = paymentMethodSelected.items.find((item) => {
                return item.paymentToken?.token === token;
            });

            if (item?.paymentToken) {
                item.paymentToken.paymentMethod = {
                    name: paymentMethodSelected.getComponentName(),
                    logTime: PaymentMethods.CREDIT_CARD === paymentMethodSelected.name ? 10 : 2,
                    componentName: paymentMethodSelected.name,
                };
                if (PaymentMethods.CREDIT_CARD === paymentMethodSelected.name) {
                    item.paymentToken.paymentMethodNumber = item?.accountingDetails?.lastFourDigits;
                    item.paymentToken.paymentInstitution = item?.accountingDetails?.cardType;
                    item.paymentToken.paymentMethodExpirationMonth = item?.accountingDetails?.expirationMonth;
                    item.paymentToken.paymentMethodExpirationYear = item?.accountingDetails?.expirationYear;
                }
            }

            return item?.paymentToken;
        }

        return paymentMethodSelected.paymentToken;
    }

    /**
     *
     * @returns {PaymentToken} The paymentToken
     * @param {PaymentMethodsType} type Type of payment to search 'CREDIT_CARD' | 'ECHECK' | 'CARGO_CREDIT' | 'SIGNET' | 'PAYPAL',
     * @param {string} token Token by item of credit card or echeck
     */
    getPaymentToken$(type: PaymentMethodsType, token?: string): Observable<PaymentToken | null> {
        return this.getPaymentMethodsByType$(type).pipe(
            map((paymentMethod: PaymentMethod | undefined) => {
                if (paymentMethod) {
                    return this.buildPaymentToken(paymentMethod, token) || null;
                }

                return null;
            })
        );
    }

    /**
     * Retrieves the payment method of the customer based on the specified type.
     * @param type The type of payment method to search for. Valid values are 'credit-card', 'echeck', 'paypal', and 'cargo-credit'.
     * @returns An Observable that emits the matching PaymentMethod object, or undefined if not found.
     * @example getPaymentMethodsByType$(PaymentMethods.CREDIT_CARD)
     */
    getPaymentMethodsByType$(type: PaymentMethodsType): Observable<PaymentMethod | undefined> {
        return this.paymentMethods.pipe(
            map((paymentMethods: PaymentMethod[]) => {
                return paymentMethods.find((paymentMethod) => paymentMethod.name === type);
            })
        );
    }

    getPaymentMethodsByType(type: PaymentMethodsType): PaymentMethod | undefined {
        return this.instant_payment_methods.find((paymentMethod) => paymentMethod.name === type);
    }

    sortPaymentMethods(paymentMethods: PaymentMethod[], ordered = true): PaymentMethod[] {
        if (!ordered) {
            return paymentMethods;
        }

        paymentMethods.forEach((paymentMethod) => {
            paymentMethod.orderId = OrderIdPaymentMethod[paymentMethod.getComponentName()];
        });

        return paymentMethods.sort((a, b) => {
            if (a.orderId === undefined) return 1;
            if (b.orderId === undefined) return -1;
            return a.orderId - b.orderId;
        });
    }

    isPaymentMethodAvailable(method: PaymentMethodsType): boolean {
        return this.instant_payment_methods.find((paymentMethod) => paymentMethod.name === method) !== undefined;
    }

    getAvailablePaymentMethodsList(): PaymentMethodsType[] {
        return this.instant_payment_methods.map((paymentMethod) => paymentMethod.name);
    }

    getFrequentPaymentMethodAvailable(
        paymentMethodsLatest: PaymentMethodsLatest[],
        paymentMethods: PaymentMethod[]
    ): PaymentMethodsLatest | undefined {
        return paymentMethodsLatest.find((paymentMethodLatest: PaymentMethodsLatest) => {
            const paymentMethodName: PaymentMethodsType = paymentMethodLatest.paymentMethod.componentName;
            const hasPaymentMethod = paymentMethods.find((method) => method.name === paymentMethodName);

            return hasPaymentMethod;
        });
    }

    /**
     * @method getPaymentMethodValidated()
     * @description Validates and returns the available payment method for the customer.
     * @param {PaymentMethodsType} method - The payment method type to validate.
     * @param {PaymentMethod[]} paymentMethods - The list of available payment methods.
     * @param {PaymentMethodDetails} [paymentDetails] - The details of the latest payment method used.
     * @returns {Observable<PaymentMethodSelected | null>} An observable that emits the selected payment method or null if not available.
     */
    getPaymentMethodValidated(
        method: PaymentMethodsType,
        paymentMethods: PaymentMethod[],
        paymentDetails?: PaymentMethodDetails
    ): Observable<PaymentMethodSelected | null> {
        const isPaymentMethodAvailable = this.isPaymentMethodAvailable(method);
        if (!isPaymentMethodAvailable) {
            return of(null);
        }
        const isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();

        if (method === PaymentMethods.CARGO_CREDIT && !isThereInvoicesInCart) {
            const availableCredit = this.getCSCreditAvailable();

            if (availableCredit >= this.cartBillService.getSubtotal() || this.customerService.isRequestorOrApprover()) {
                return of({
                    paymentAccount: availableCredit,
                    method: PaymentMethods.CARGO_CREDIT,
                });
            }
        }

        if (method === PaymentMethods.CREDIT_CARD) {
            const creditCards = paymentMethods.find((method) => method.name === PaymentMethods.CREDIT_CARD);

            if (paymentDetails && creditCards && !!creditCards?.items.length) {
                const lastCreditCard = creditCards.items.find(
                    (card) =>
                        card.accountingDetails?.lastFourDigits === paymentDetails.last4 &&
                        card.accountingDetails?.expirationYear === paymentDetails.expirationYear &&
                        card.accountingDetails?.expirationMonth === paymentDetails.expirationMonth
                );

                if (lastCreditCard) {
                    return of({
                        paymentAccount: {
                            ...lastCreditCard,
                            paymentToken: {
                                ...lastCreditCard?.paymentToken,
                                paymentMethod: {
                                    name: ComponentPaymentMethodName.CREDIT_CARD,
                                    logTime: 2,
                                    componentName: PaymentMethods.CREDIT_CARD,
                                },
                            },
                        },
                        method: PaymentMethods.CREDIT_CARD,
                        token: lastCreditCard.paymentToken?.token || undefined,
                    });
                }
            }

            if (creditCards && !!creditCards?.items.length) {
                return of({
                    paymentAccount: {
                        ...creditCards.items[0],
                        paymentToken: {
                            ...creditCards.items[0].paymentToken,
                            paymentMethod: {
                                name: ComponentPaymentMethodName.CREDIT_CARD,
                                logTime: 2,
                                componentName: PaymentMethods.CREDIT_CARD,
                            },
                        },
                    },
                    method: PaymentMethods.CREDIT_CARD,
                    token: creditCards.items[0].paymentToken?.token || undefined,
                });
            }

            return of({
                paymentAccount: null,
                method: PaymentMethods.CREDIT_CARD,
            });
        }

        // * Note: Echecks were not considered to be returned by the API when they are the latest payment method used.
        if (method === PaymentMethods.ECHECK) {
            const echecks = paymentMethods.find((method) => method.name === PaymentMethods.ECHECK);

            if (echecks && !!echecks?.items.length) {
                return of({
                    paymentAccount: {
                        ...echecks.items[0],
                        paymentToken: {
                            ...echecks.items[0].paymentToken,
                            paymentMethod: {
                                name: ComponentPaymentMethodName.BANK_DEBIT,
                                logTime: 2,
                                componentName: PaymentMethods.BANK_DEBIT,
                            },
                        },
                    },
                    method: PaymentMethods.BANK_DEBIT,
                    token: echecks.items[0].paymentToken?.token || undefined,
                });
            }

            return of({
                paymentAccount: null,
                method: PaymentMethods.BANK_DEBIT,
            });
        }

        if (method === PaymentMethods.PAYPAL) {
            return of({ paymentAccount: true, method: PaymentMethods.PAYPAL });
        }

        return of(null);
    }

    /**
     * @method connectWithPaypal
     * @description Creates a PayPal checkout instance and loads the PayPal script.
     * @returns {Observable<PayPalCheckout>} An observable that emits the PayPal checkout instance.
     */
    connectWithPaypal(): Observable<PayPalCheckout> {
        return this.braintreeService.getBraintreeApiKey().pipe(
            switchMap((braintreeToken) =>
                this.braintreeService.getBraintreeClient({
                    authorization: braintreeToken.clientToken,
                })
            ),
            switchMap((client) =>
                this.braintreeService.getPayPalInstance({
                    client,
                    options: { 'client-id': environment.paypalClientId },
                })
            ),
            catchError((error: BraintreeException) =>
                throwError(() => {
                    this.errorBraintreeHandlerService.handlerError(error);
                })
            )
        );
    }

    /**
     * @method getCSCreditAvailable
     * @description Retrieves the available credit for the CARGO_CREDIT payment method.
     * @returns {number} The available credit amount, or 0 if the CARGO_CREDIT payment method is not available.
     */
    getCSCreditAvailable$(): Observable<number> {
        return this.getPaymentMethodsByType$(PaymentMethods.CARGO_CREDIT).pipe(
            map((paymentMethod) => paymentMethod?.balance.avaliableCredit || 0)
        );
    }

    /**
     * @method getCSCreditAvailable
     * @description Retrieves the available credit for the CARGO_CREDIT payment method.
     * @returns {number} The available credit amount, or 0 if the CARGO_CREDIT payment method is not available.
     */
    getCSCreditAvailable(): number {
        return this.getPaymentMethodsByType(PaymentMethods.CARGO_CREDIT)?.balance?.avaliableCredit || 0;
    }

    getCSCreditBalance(): Balance {
        return this.getPaymentMethodsByType(PaymentMethods.CARGO_CREDIT)?.balance || {};
    }

    getCSCreditBalance$(): Observable<Balance | undefined> {
        return this.getPaymentMethodsByType$(PaymentMethods.CARGO_CREDIT).pipe(
            map((paymentMethod) => paymentMethod?.balance)
        );
    }

    /**
     * @method updatePaymentMethod
     * @description Updates a property of a payment method in the paymentMethods list based on the payment method name.
     * @param {PaymentMethodsType} methodName - The name of the payment method to update.
     * @param {Partial<PaymentMethod>} updatedProperties - An object containing the properties to update.
     */
    updatePaymentMethod(methodName: PaymentMethodsType, updatedProperties: Partial<PaymentMethod>): void {
        let paymentMethods: PaymentMethod[] = this.instant_payment_methods.map((paymentMethod) => {
            if (paymentMethod.name === methodName) {
                const updatedPaymentMethod = {
                    ...paymentMethod,
                    ...updatedProperties,
                };
                return updatedPaymentMethod as PaymentMethod;
            }
            return paymentMethod;
        });

        paymentMethods = this.buildPaymentsMethods(paymentMethods);
        this.setPaymentMethods(paymentMethods);
    }

    getMethod(paymentMethodSelected: PaymentMethod, token?: string): PaymentMethod | PaymentMethodItem | undefined {
        if (PaymentMethods.CARD_METHODS.includes(paymentMethodSelected.name as CardMethodsType)) {
            if (!token) {
                return undefined;
            }
            return paymentMethodSelected.items.find((item) => {
                return item.paymentToken?.token === token;
            });
        }

        return paymentMethodSelected;
    }

    getPaymentMethodByTypeAndToken(
        type: PaymentMethodsType,
        token?: string
    ): Observable<PaymentMethod | PaymentMethodItem | null> {
        return this.getPaymentMethodsByType$(type).pipe(
            map((paymentMethod: PaymentMethod | undefined) => {
                if (paymentMethod) {
                    return this.getMethod(paymentMethod, token) || null;
                }

                return null;
            })
        );
    }

    removeCard(token: string, emitChange = true): Observable<string> {
        return this.paymentMethodsRequestService.removeCard(token).pipe(
            map((response) => {
                if (emitChange) {
                    this.newEventPaymentMethod(PaymentMethodsEvents.creditCardRemoved);
                }

                return response;
            }),
            catchError((error: BraintreeException) =>
                throwError(() => this.errorBraintreeHandlerService.handlerError(error))
            )
        );
    }

    removeEcheck(token: string, emitChange = true): Observable<string> {
        return this.paymentMethodsRequestService.removeEcheck(token).pipe(
            map((response) => {
                if (emitChange) {
                    this.newEventPaymentMethod(PaymentMethodsEvents.echeckRemoved);
                }

                return response;
            }),
            catchError((error: ForteException) => throwError(() => this.errorForteHandlerService.handlerError(error)))
        );
    }

    setHandlerEventFromCart(paymentMethodAddFromCart: boolean): void {
        this.paymentMethodAddFromCart.next(paymentMethodAddFromCart);
    }

    getHandlerEventFromCart$(): Observable<boolean> {
        return this.paymentMethodAddFromCart.asObservable();
    }
}
