<!-- START: DOWNLOAD -->
<section id="refund-request-choice" class="refund-request-choice w-100">
    <div id="refund-request-choice__modal" class="refund-request-choice__modal container-fluid">
        <div class="refund-logo d-flex justify-content-center align-items-center">
            <img src="assets/images/ui/refund-request.svg" alt="Refund request logo" class="mx-2" />
        </div>
        <div class="row header">
            <!-- START: HEADER -->
            <div class="text-center">
                <h4 class="mb-0">Refund request</h4>
            </div>
            <!-- END: HEADER -->
        </div>
        <div class="sub-header">
            <div class="reference-number text-center d-flex justify-content-center align-items-center">
                <h6 class="description mb-0 text-center">
                    The cargo facility requires a refund request to be submitted directly to the cargo facility.
                </h6>
            </div>
        </div>

        <!-- START: BUTTON ACTIONS -->
        <div class="row">
            <div class="col-12">
                <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                    <button
                        mat-button
                        type="button"
                        (click)="closeDialog()"
                        class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                    >
                        Close
                    </button>
                    <button
                        mat-button
                        type="button"
                        (click)="refund()"
                        class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                    >
                        Request a refund
                    </button>
                </div>
            </div>
        </div>
        <!-- END: BUTTON ACTIONS -->
    </div>
</section>
<!-- END: DOWNLOAD -->
