import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ComponentPaymentMethodName,
    Customer,
    PaymentMethods,
    PaymentMethodsType,
    Source,
} from '@cargos/sprintpay-models';
import { EnabledPaymentMethod } from '@cargos/sprintpay-models/dist/src/payment-methods/payment-methods';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, from, map, of, shareReplay } from 'rxjs';
import { Permission } from 'src/app/models/payment-override/payment-override-type';
import { CompanyDetails } from 'src/app/models/utils/company';
import { companyName, userTypes } from 'src/app/utils/constants';
import { ProfileTypes } from 'src/app/utils/profile-types';
import { WhiteIPList } from 'src/app/utils/white-ip-list';
import { environment } from 'src/environments/environment';
import { CustomerAPIService } from '../requests/customer-api.service';
import { RestService } from '../rest.service';
import { SessionService } from './session.service';
import { StorageService, StorageTypes } from './storage.service';
import { TokenService } from './token.service';

interface EnabledPaymentType {
    paymentMethod: { name: ComponentPaymentMethodName };
}
@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    private activeProfileView = new BehaviorSubject<string>(ProfileTypes.FORWARDER);
    private parentBranchData$!: Observable<CompanyDetails>;

    constructor(
        private _tokenService: TokenService,
        private _restService: RestService,
        private _storageService: StorageService,
        private _sessionService: SessionService,
        private _customerApiService: CustomerAPIService
    ) {}

    /**
     * @method getCurrentUser()
     * @description
     */

    getCurrentUser(): string | null {
        const token = this._tokenService.getCurrentUser();
        return token ? token : null;
    }

    getCustomer(): any {
        const spCustomer = JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken()))?.SPRINT_PAY_CUSTOMER;
        if (spCustomer) {
            return JSON.parse(
                JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken()))?.SPRINT_PAY_CUSTOMER
            ).customer;
        }

        return null;
    }

    getCustomer$(): Observable<Customer | null> {
        return this._tokenService.getCurrentToken$().pipe(
            map(() => {
                const user = this._tokenService.getDecodedAccessToken()?.SPRINT_PAY_CUSTOMER;
                return user ? (JSON.parse(user)?.customer as Customer) : null;
            })
        );
    }

    getSource(): Source | null {
        if (this._tokenService.instant_token) {
            const source = this._tokenService.getDecodedAccessToken()?.SOURCE;
            return source ? JSON.parse(source) : {};
        }
        return null;
    }

    getSource$(): Observable<Source> {
        return this._tokenService.getCurrentToken$().pipe(
            map(() => {
                const source = this._tokenService.getDecodedAccessToken()?.SOURCE;
                return source ? JSON.parse(source) : {};
            })
        );
    }

    get source(): Source | null {
        const source = this._tokenService.getDecodedAccessToken().SOURCE;

        return source ? (JSON.parse(source) as Source) : null;
    }

    getSourceId(): number | null {
        return this.source?.id || null;
    }

    /**
     * @method getCustomerPermissions$()
     * @description Retrieves customer permissions from the decoded access token
     */

    getCustomerPermissions$(): Observable<string[]> {
        const tokenData = this._tokenService.getDecodedAccessToken()?.SPRINT_PAY_CUSTOMER;
        if (tokenData) {
            return of(JSON.parse(tokenData).customer?.permissions?.map((permission: Permission) => permission.name));
        } else {
            return of([]);
        }
    }

    /**
     * @method findPermissionByName()
     * @description Finds a permission by its name
     */

    findPermissionByName(permissionName: string): Observable<boolean> {
        return this.getCustomerPermissions$().pipe(
            map((permissions) => permissions.includes(permissionName)),
            catchError(() => of(false))
        );
    }

    /**
     * @method getCustomerType()
     * @description
     */

    getCustomerType(): any {
        const spCustomer = JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken()))?.SPRINT_PAY_CUSTOMER;
        if (spCustomer) {
            return JSON.parse(
                JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken()))?.SPRINT_PAY_CUSTOMER
            ).customerType;
        }

        return null;
    }

    getDecodedToken(): any {
        return JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken()));
    }

    /**
     * @method getCompanyName()
     * @description
     */
    getCompanyName(): string {
        const customer = this.getCustomer();
        return customer?.approvalLevels?.company?.name || '';
    }

    /**
     * @method isACompanyCustomer()
     * @description
     */
    isACompanyCustomer(): boolean {
        return this.getCompanyName() !== '';
    }

    /**
     * @method isRequestor
     * @description Checks if the current user is a requestor.
     * @returns True if the user is a requestor, false otherwise.
     */
    isRequestor(): boolean {
        return !!this.getCustomer()?.userType?.toLowerCase().includes(userTypes.REQUESTOR);
    }

    /**
     * @method isApprover
     * @description Checks if the current user is an approver.
     * @returns True if the user is an approver, false otherwise.
     */
    isApprover(): boolean {
        return !!this.getCustomer()?.userType?.toLowerCase().includes(userTypes.APPROVER);
    }

    /**
     * @method isFinancie
     * @description Checks if the current user is a finance user.
     * @returns True if the user is a finance user, false otherwise.
     */
    isFinancie(): boolean {
        return !!this.getCustomer()?.userType?.toLowerCase().includes(userTypes.FINANCIE);
    }

    isRequestorOrApprover(): boolean {
        return this.isRequestor() || this.isApprover();
    }

    /**
     * @method getStartingLevel()
     * @description Checks if the current user is at the starting level of approval.
     * @returns True if the user is at the starting level, false otherwise.
     */
    getStartingLevel(): boolean {
        const customer: Customer | null = this.getCustomer();
        return customer?.approvalLevels?.company?.startingLevel || false;
    }

    /**
     * @method isDynamicCompany()
     * @description Checks if the company is dynamic based on its name.
     * @returns True if the company is dynamic, false otherwise.
     */
    isDynamicCompany(): boolean {
        const companyType = this.getCompanyName();
        return companyType ? Object.keys(companyName).indexOf(companyType.toLowerCase()) < 0 : false;
    }

    /**
     * @method getCompanyName()
     * @description
     */
    getCompanyId(): string {
        const customer: Customer | null = this.getCustomer();
        return customer?.approvalLevels && customer.approvalLevels.company
            ? customer.approvalLevels.company?.id
                ? customer.approvalLevels.company?.id.toString()
                : ''
            : '';
    }

    /**
     * @method saveCurrentUser()
     * @param (token: any)
     * @param (refreshToken: any)
     * @description
     */

    saveCurrentUser(token: any, refreshToken?: any): void {
        this._tokenService.setCurrentUser(token);
        if (refreshToken) {
            this._tokenService.setAccessControl(refreshToken);
        }
    }

    /**
     * @method isCustomerEmailInDomain()
     * @param domain The domain of the company
     * @description Returns a boolean indicating if the customer's email belongs to the specified domain.
     * @returns True if the customer's email belongs to the specified domain, false otherwise.
     */
    isCustomerEmailInDomain(domain: string): boolean {
        const customer = this.getCustomer();
        return !!customer.email.includes(domain);
    }

    customerProfile(token: string): Observable<any> {
        const url = `${environment.uris.method.authentication}/customer-profile`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });
        return from(this._restService.get(url, { options: { headers } }));
    }

    setCustomerProfile(customerProfile: any): void {
        this._storageService.removeElement('customerProfile');
        this._storageService.saveElement('customerProfile', JSON.stringify(customerProfile));
    }

    getCustomerProfile(): any {
        const customer = this.getCustomer();
        if (customer?.customerProfile) {
            return customer.customerProfile;
        }
        const profileSession = this._storageService.getElement('customerProfile', StorageTypes.SESSION) || '';
        return profileSession && JSON.parse(profileSession);
    }

    /**
     * @method getSuperPermissions()
     * @param ()
     * @description returns the permission list for the super user
     */

    getSuperPermissions(): any {
        const customer = this.getCustomer();

        return customer?.permissions || [];
    }

    isAllowedDisplayNotifications(): boolean {
        return this._sessionService.getElement('startingLevel') === undefined;
    }

    setActiveProfileView(activeProfileView: string): void {
        this.activeProfileView.next(activeProfileView);
    }

    getActiveProfileView(): string {
        return this.activeProfileView.value;
    }

    getActiveProfileView$(): Observable<string> {
        return this.activeProfileView.asObservable().pipe(distinctUntilChanged());
    }

    getAvailablePaymentMethods(): PaymentMethodsType[] {
        const availablePaymentMethods = this.getCustomerProfile()
            .components.filter((component: { name: PaymentMethodsType }) =>
                (PaymentMethods.COMPONENT_NAMES as unknown as Omit<PaymentMethodsType, 'signet'>).includes(
                    component.name
                )
            )
            .map((paymentMethod: { name: PaymentMethodsType }) => paymentMethod.name);

        return availablePaymentMethods;
    }

    isCSCreditAvailable(): boolean {
        const availablePaymentMethods = this.getAvailablePaymentMethods();

        return availablePaymentMethods.includes(PaymentMethods.CARGO_CREDIT);
    }

    getPaymentMethodForNewPayments(): PaymentMethodsType {
        const profile = this.getCustomerProfile();
        return profile.paymentMethodForNewPayments || '';
    }

    getPaymentMethodForOpenInvoices(): PaymentMethodsType {
        const profile = this.getCustomerProfile();
        return profile.paymentMethodForOpenInvoices || '';
    }

    getCanDeleteCartAutomatically(): boolean {
        const customer = this.getCustomer();
        const canDeleteCartAutomatically = customer?.approvalLevels?.company?.canDeleteCartAutomatically;
        return canDeleteCartAutomatically || false;
    }

    isGuest(): boolean {
        const isGuest = this.getCustomer()?.isGuest;
        return !!isGuest;
    }

    /**
     * @method getProfileType();
     * @description return the profile type by user
     */

    getProfileType(): string {
        const customer: Customer | null = this.getCustomer();
        const profileType: string = customer?.profileType || '';

        return profileType;
    }

    /**
     * @method getProfileType();
     * @description return the profile type by user as observable based on token
     */

    getProfileType$(): Observable<string> {
        return this.getCustomer$().pipe(map((customer) => (customer as any)?.profileType || ProfileTypes.FORWARDER));
    }

    /**
     * @method getRoleUser()
     * @param ()
     * @description returns the role name
     */

    getRoleUser(): string {
        const customer: Customer | null = this.getCustomer();
        return customer?.roleName || '';
    }

    /**
     * @method isAllianceDomain()
     * @description Returns true if the user's email belongs to the alliance domain, false otherwise.
     * @returns True if the user's email belongs to the alliance domain, false otherwise.
     */
    isAllianceDomain(): boolean {
        return this.isCustomerEmailInDomain('@allianceground.com') || this.isCustomerEmailInDomain('@agi.aero');
    }

    isAgiAeroDomain(): boolean {
        return this.isCustomerEmailInDomain('@agi.aero');
    }

    /**
     * @method isCevaDomain()
     * @description Returns true if the user's email belongs to the ceva domain, false otherwise.
     * @returns True if the user's email belongs to the ceva domain, false otherwise.
     */
    isCevaDomain(): boolean {
        return this.isCustomerEmailInDomain('@cevalogistics.com');
    }

    hasFacilityProfile(): boolean {
        return this.getProfileType()?.toLowerCase().includes(ProfileTypes.FACILITY.toLowerCase());
    }

    hasAutomatedDebits$(): Observable<{
        customerAutomatedDebit: boolean;
        customerAccountAutomatedDebit: boolean;
    }> {
        return this.getCustomer$().pipe(
            map((customer) => {
                return {
                    customerAutomatedDebit: !!customer?.isAutomatedDebitEnabled,
                    customerAccountAutomatedDebit: !!customer?.customerAccount?.isAutomatedDebitEnabled,
                };
            })
        );
    }

    getEnabledPaymentMethods$(): Observable<EnabledPaymentMethod[]> {
        return this.getSource$().pipe(map((source) => source.enabledPaymentMethods || []));
    }

    getEnabledPaymentMethodByName$(enabledPaymentMethodName: string): Observable<EnabledPaymentMethod | undefined> {
        return this.getEnabledPaymentMethods$().pipe(
            map((enabledPaymentMethods) =>
                enabledPaymentMethods?.find(
                    (enabledPaymentMethod) => enabledPaymentMethod.paymentMethod.name === enabledPaymentMethodName
                )
            )
        );
    }

    getCustomerAccount$(): Observable<CompanyDetails> {
        return this.getCustomer$().pipe(map((customer: any) => customer?.customerAccount));
    }

    getParentBranchData$(force = false): Observable<CompanyDetails> {
        if (!this.parentBranchData$ || force) {
            this.parentBranchData$ = this._customerApiService.getParentBranch().pipe(shareReplay(1));
        }
        return this.parentBranchData$;
    }

    isYusenLogisticsUser(): boolean {
        return this.isCustomerEmailInDomain(WhiteIPList.YUSEN_LOGISTICS);
    }
    /**
     * @method isUniqueRequestor()
     * @description Checks if the current user is a unique requestor.
     */
    isUniqueRequestor(): boolean {
        return this.getCustomer()?.userType?.toLowerCase().includes(userTypes.UNIQUE_REQUESTOR);
    }

    /**
     *
     * @param url
     * @returns RETURN true if is Facility and active view is super-admin
     */
    isFacility(url: string): boolean {
        return this.getProfileType().includes(ProfileTypes.FACILITY) && url.includes('super-admin');
    }

    getInvoiceCheckLimit(): number | null {
        const customer: Customer | null = this.getCustomer();
        return customer?.invoiceCheckLimit || null;
    }
}
