<mat-list-option
    class="credit-card-list-item mb-4"
    [value]="creditCardItem.paymentToken?.token"
    (click)="selectPaymentMethod()"
    [selected]="token === creditCardItem.paymentToken?.token"
    checkboxPosition="before"
    color="primary"
>
    <div class="d-flex flex-row align-items-center pe-3">
        <app-card-type
            [cardType]="creditCardItem.accountingDetails.cardType"
            width="2.3em"
            class="me-3"
        ></app-card-type>
        @if (creditCardItem.accountingDetails.lastFourDigits && creditCardItem.accountingDetails.expirationMonth &&
        creditCardItem.accountingDetails.expirationYear) {
        <div class="d-flex flex-grow-1 flex-column">
            <app-last-four-digits
                [lastFourDigits]="creditCardItem.accountingDetails.lastFourDigits"
            ></app-last-four-digits>
            <app-expiration-date
                [expirationMonth]="creditCardItem.accountingDetails.expirationMonth"
                [expirationYear]="creditCardItem.accountingDetails.expirationYear"
            ></app-expiration-date>
        </div>
        } @if(cardDeletionAllowed) {
        <mat-icon
            *ngIf="!loading"
            class="md-icon-24 material-symbols-outlined color-error-300 color-error-default-hover"
            (click)="removeCard(creditCardItem)"
            >delete_outline</mat-icon
        >
        <mat-icon
            class="md-icon-24 material-symbols-outlined color-error-300 color-error-default-hover"
            color="error"
            *ngIf="loading"
        >
            <mat-spinner diameter="20" color="error"></mat-spinner> </mat-icon
        >}
    </div>
</mat-list-option>
