import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@cargos/sprintpay-models';
import { Observable, catchError, throwError } from 'rxjs';
import { ACH } from 'src/app/models/payments/ach.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable()
export class AchAPIService {
    private _paymentsURL: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._paymentsURL = environment.uris.method.payments;
    }

    /**
     * @method getACHFileByPost()
     * @param (achNumber: string)
     */
    getACHFileToDownload(achNumber: string): Observable<Blob> {
        const url = `${this._paymentsURL}/ach/payment/download/?achNumber=${achNumber}&downloadFormat=XLSX`;
        const headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);
        const responseType = 'blob';
        return this._httpClient
            .post(
                url,
                {},
                {
                    headers,
                    responseType,
                }
            )
            .pipe(
                catchError((error) => {
                    if (error.error instanceof Blob && error.error.type === 'application/json') {
                        return new Observable<Blob>((subscriber) => {
                            const reader = new FileReader();
                            reader.onload = () => {
                                try {
                                    const errorObj = JSON.parse(reader.result as string);
                                    subscriber.error(errorObj);
                                } catch (parseError) {
                                    subscriber.error(error);
                                }
                            };
                            reader.onabort = (error) => subscriber.error(error);
                            reader.onerror = (error) => subscriber.error(error);
                            reader.onloadend = () => subscriber.complete();
                            reader.readAsText(error.error);
                            return {
                                unsubscribe: () => {
                                    if (reader.readyState === 1) {
                                        reader.abort();
                                    }
                                },
                            };
                        });
                    }
                    return throwError(() => ({
                        error: { errors: error },
                    }));
                })
            );
    }

    /**
     * @method getAchPayments()
     * @param (url: string)
     */
    getAchPayments(url: string): Observable<Pagination<ACH>> {
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Pagination<ACH>>(url, { headers });
    }
}
