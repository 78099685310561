import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MethodCreditCardsService {
    expiredCard(expirationYear: string, expirationMonth: string): boolean {
        const exp = new Date(+expirationYear, +expirationMonth, 1).valueOf();
        const now = new Date();
        const currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();

        return exp <= currMonth;
    }
}
