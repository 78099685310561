import * as moment from 'moment/moment';
import { Facility } from 'src/app/models/payments/facility.model';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { paymentMethod } from '../constants';

export const customerReferenceRhenus: RegExp[] = [/^[0-9]{3,6}$/];

export const customerReferenceRhenusData = {
    title: 'Ops file #',
    placeholder: 'Enter Ops file #',
    titleNotificationEmail: 'Payment notification Email',
    placeholderNotificationEmail: 'Enter the payment notification Email',
};

export class Rhenus {
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');
    static readonly paymentReferencePipe = new FormatPaymentReferencePipe();

    static readonly paymentTypes = Object.freeze(
        sortPaymentTypes([
            'Bill of Lading',
            'Breakdown fee',
            'Charter/ULD Bypass Fees',
            'Import Service + Storage',
            'Import Service Fee (ISC)',
            'Ocean Freight',
            'Pallets/Wood skids',
            'Storage/Demurrage/Detention',
            'Other',
        ])
    );

    /*
     * OPEN INVOICES
     */

    static readonly rhenus_ColumnsOpenInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'requesterEmail',
        'facilityReference',
        'location',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];
    static readonly rhenus_ColumnsNameOpenInvoices = [
        'select',
        'Invoice number',
        'Created date',
        'Ops file#',
        'Requester email',
        'Facility reference',
        'Location',
        'Amount',
        'Short Payment Amount',
        'Balance due',
        'Due date',
    ];
    static readonly rhenus_getCompletedOpenInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        requesterEmail: item.paymentNotificationEmail
            ? item.paymentNotificationEmail
            : item.customer && item.customer.email
              ? item.customer.email
              : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        shortPaymentAmount: item.paymentShort ? item.paymentShort : 'N/A',
        balanceDue: item.amount
            ? '$' +
              (
                  Number.parseFloat(item.amount) -
                  (Number.parseFloat(item.shortPayment) ? Number.parseFloat(item.shortPayment) : 0)
              ).toFixed(2)
            : 'N/A',
        dueDate: item.dueDate ? moment.utc(item.dueDate).format('MMM DD, YYYY') : '',
        overDue:
            moment.utc(item.dueDate).startOf('day').diff(Rhenus.currentDate, 'days') >= 0 &&
            moment.utc(item.dueDate).startOf('day').diff(Rhenus.currentDate, 'days') < 7
                ? 'almostDue'
                : moment.utc(item.dueDate).startOf('day').diff(Rhenus.currentDate, 'days') < 0
                  ? 'overdue'
                  : 'onTime',
        id: item.id,
        location: item.location ? item.location.name : 'N/A',
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });
    static readonly rhenus_ColumnsSortableOpenInvoices: string[] = ['createdDate', 'amount', 'dueDate'];

    /*
     * PAID INVOICES
     */

    static readonly rhenus_ColumnsPaidInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerReference',
        'facilityReference',
        'location',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];
    static readonly rhenus_ColumnsNamePaidInvoices = [
        'select',
        'Invoice Number',
        'Created date',
        'Ops file#',
        'Facility reference',
        'Location',
        'Amount',
        'Payment date',
        'Payment method',
    ];
    static readonly rhenus_getCompletedPaidInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentDate: item.paymentDate ? moment(item.paymentDate).format('MMM DD, YYYY') : '',
        paymentMethod: item.paymentMethod ? (paymentMethod[item.paymentMethod.name] ?? item.paymentMethod.name) : 'N/A',
        confirmationId: item.confirmationId,
        id: item.id,
        location: item.location && item.location.name ? item.location.name : 'N/A',
        hasDownloadableContent: true,
    });
    static readonly rhenus_ColumnsSortablePaidInvoices: string[] = [
        'createdDate',
        'paymentDate',
        'amount',
        'paymentMethod',
    ];

    /*
     * REJECTED PAYMENTS
     */

    static readonly rhenus_ColumnsRejectedPayment = [
        'select',
        'id',
        'attachmentFound',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'externalReferenceNum',
        'amount',
    ];
    static readonly rhenus_ColumnsNameRejectedPayment = [
        'select',
        'Request#',
        'Attachment found',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'Ops file#',
        'Product',
        'Amount',
    ];
    static readonly rhenus_getCompletedRejectedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item.externalData.externalReferenceNum
              ? item.externalData.externalReferenceNum
              : 'N/A',
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
    });
    static readonly rhenus_ColumnsSortableRejectedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'location',
        'amount',
    ];

    /*
     * VOIDED PAYMENTS
     */

    static readonly rhenus_ColumnsVoidedPayment = [
        'select',
        'id',
        'attachmentFound',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'externalReferenceNum',
        'amount',
    ];
    static readonly rhenus_ColumnsNameVoidedPayment = [
        'select',
        'Request#',
        'Attachment found',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'Ops file#',
        'Product',
        'Amount',
    ];
    static readonly rhenus_getCompletedVoidedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item.externalData.externalReferenceNum
              ? item.externalData.externalReferenceNum
              : 'N/A',
    });
    static readonly rhenus_ColumnsSortableVoidedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'location',
        'amount',
    ];

    /*
     * HISTORY
     */

    static readonly rhenus_ColumnsHistory = [
        'select',
        'linkId',
        'attachmentFound',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'location',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'facilityPaymentDeliveryNotificationShort',
    ];
    static readonly rhenus_ColumnsNameHistory = [
        'select',
        'Payment reference #',
        'Attachment found',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Location',
        'Ops file#',
        'Product',
        'Amount',
        'Delivery type',
    ];
    static readonly rhenus_getCompletedPaymentsHistory = (item: any) => ({
        id: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        facilityName: item.facility ? item.facility.name : 'N/A',
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
        awb: item.awb ? item.awb : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        location: item.location ?? 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item.externalData?.externalReferenceNum
              ? item.externalData?.externalReferenceNum
              : 'N/A',
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        linkId: Rhenus.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
    });
    static readonly rhenus_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'location',
        'amount',
    ];

    /*
     * PENDING APPROVAL
     */

    static readonly rhenus_ColumnsPendingApproval = [
        'select',
        'id',
        'attachmentFound',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly rhenus_ColumnsNamePendingApproval = [
        'select',
        'Request#',
        'Attachment found',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Ops file#',
        'Product',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly rhenus_getCompletedPaymentsPendingApproval: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item.externalData.externalReferenceNum
              ? item.externalData.externalReferenceNum
              : 'N/A',
        approvalFlowLevel: item.approvalFlowLevel || '',
    });
    static readonly rhenus_ColumnsSortablePendingApproval: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'location',
        'amount',
        'approvalLevel',
    ];

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly rhenus_ColumnsPendingApprovalHomeComponent = [
        'select',
        'id',
        'attachmentFound',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly rhenus_ColumnsNamePendingApprovalHomeComponent = [
        'select',
        'Request#',
        'Attachment found',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Ops file#',
        'Product',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly rhenus_getCompletedPaymentsPendingApprovalHomeComponent: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../facilityPayments/pendingApproval/paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        attachmentFound: item.attachments ? ' Yes ' : 'N/A',
        externalReferenceNum: item.externalReferenceNum
            ? item.externalReferenceNum
            : item.externalData.externalReferenceNum
              ? item.externalData.externalReferenceNum
              : 'N/A',
    });
    static readonly rhenus_ColumnsSortablePendingApprovalHomeComponent: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerReference',
        'externalReferenceNum',
        'location',
        'amount',
        'approvalLevel',
    ];

    /*
     * USERS
     */

    static readonly rhenus_ColumnsUsers = [
        'firstName',
        'lastName',
        'email',
        'homeLocation',
        'approverType',
        'approverLocations',
        'notificationEmail',
        'isMegafileEnabled',
    ];
    static readonly rhenus_ColumnsNameUsers = [
        'First name',
        'Last name',
        'Email address',
        'Home location',
        'Approver type',
        'Locations for approver visibility',
        'Email notifications',
        'Megafile',
    ];
    static readonly rhenus_getCompletedPaymentsUsers = (item: any) => ({
        firstName: item.firstName ? item.firstName : 'N/A',
        lastName: item.lastName ? item.lastName : 'N/A',
        email: item.email ? item.email : 'N/A',
        homeLocation: item.homeLocation ? item.homeLocation : 'N/A',
        approverType: item.approverType ? item.approverType : 'N/A',
        approverLocations: item.approverLocations ? item.approverLocations : 'N/A',
        notificationEmail: item.notificationEmail ? item.notificationEmail : 'N/A',
        isMegafileEnabled: item.isMegafileEnabled ? 'Yes' : 'N/A',
    });

    static readonly getPaymentDetailsPaymentSummary = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'File Number'
                : facility.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : (facility.awbFieldLabel ?? 'N/A'),
            value: item.awb ? item.awb : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        {
            label: 'Amount',
            value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'HAWB/Container No.', value: item.hawb ? item.hawb : 'N/A', show: !facility.externalLookup },
    ];
    static readonly getCustomerReferencePaymentSummary = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceRhenusData.title,
            value: item.customerRef ? item.customerRef : 'N/A',
            show: true,
        },
        {
            label: customerReferenceRhenusData.titleNotificationEmail,
            value: item.email ? item.email : 'N/A',
            show: true,
        },
        { label: 'Product', value: item.externalReferenceNum ? item.externalReferenceNum : 'N/A', show: true },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly rhenus_getCompanyCustomerReferencePaymentSummary: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    static readonly getPaymentDetailsPaymentHistory = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup ? 'File Number' : 'Second facility reference #',
            value: item.hawb ? item.hawb : 'N/A',
            show: !facility.externalLookup && !id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        { label: 'Cargo facility', value: facility.name ? facility.name : 'N/A', show: true },
        { label: 'Amount', value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A', show: true },
    ];
    static readonly getCustomerReferencePaymentHistory = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceRhenusData.title,
            value: item.customerReference ? item.customerReference : 'N/A',
            show: true,
        },
        { label: 'Product', value: item.externalReferenceNum ? item.externalReferenceNum : 'N/A', show: true },
        {
            label: customerReferenceRhenusData.titleNotificationEmail,
            value: item.notificationEmail ? item.notificationEmail : 'N/A',
            show: true,
        },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly rhenus_getCompanyCustomerReferencePaymentHistory: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    paymentDetails = [
        {
            label: 'AWB',
            id: 'awb',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Paid To',
            id: 'paidTo',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Amount',
            id: 'amount',
            value: 'N/A',
            type: 'currency',
        },
        {
            label: ' HAWB/Container No. ',
            id: 'hawb',
            value: 'N/A',
            type: 'string',
        },
    ];
    customerReference = [
        {
            label: customerReferenceRhenusData.title,
            id: 'customerRef',
            value: 'N/A',
            type: 'string',
        },
        {
            label: customerReferenceRhenusData.titleNotificationEmail,
            id: 'email',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Product',
            id: 'externalReferenceNum',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Notes',
            id: 'notes',
            value: 'N/A',
            type: 'string',
        },
    ];
    rhenusCustomerReference = [
        {
            label: 'Payment amount',
            id: 'paymentAmount',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Type',
            id: 'type',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Location',
            id: 'locationName',
            value: 'N/A',
            type: 'string',
        },
    ];
}
