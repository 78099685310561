<mat-dialog-content id="two-factor-authentication">
    <div class="d-flex justify-content-end">
        <button class="close" matSuffix mat-icon-button (click)="closeDialogFromModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <section class="w-100 p-0 p-md-4">
        <div class="text-center">
            <div class="justify-content-center">
                <div class="col-12">
                    <img class="mx-auto d-block mb-5" src="assets/images/sp-logo.svg" alt="Logo" />
                </div>
                <div class="col-12 instrucctions">
                    <p class="mb-0">
                        We have sent a 6-digit verification code to: <span class="fw-bolder">{{contactMask}}</span>.
                    </p>
                    <p>Please check your messages for the code</p>
                </div>
                <ng-template [ngIf]="!loading" [ngIfElse]="codeVerified">
                    <app-input-code
                        [disabled]="maxAttempsExceeded"
                        [digits]="6"
                        (verifyCode)="verify($event)"
                        (eventOnChange)="eventOnChange()"
                    ></app-input-code>
                    <app-timer
                        *ngIf="maxAttempsExceeded"
                        [minutes]="1"
                        (eventOnTimeCompleted)="eventOnTimeCompleted($event)"
                    ></app-timer>
                    <div class="col-12 resendCode">
                        <p>
                            Didn't receive the code?
                            <button
                                (click)="errorMsg='';getPinCode();"
                                [disabled]="maxAttempsExceeded"
                                class="text-uppercase btn__sprintpay--link"
                            >
                                RESEND CODE
                            </button>
                        </p>
                    </div>
                    <div *ngIf="errorMsg" class="error mx-0 m-4">
                        <div>
                            <i class="bi bi-exclamation-triangle-fill me-2"></i> <strong> Oops...</strong> {{errorMsg}}
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center mb-4" *ngIf="enableChangeVerificationMethod">
                        <button
                            class="verificationButton text-uppercase btn__sprintpay--link"
                            [disabled]="maxAttempsExceeded"
                            (click)="updateVerificationMethod()"
                        >
                            <span class="material-symbols-outlined"> sync </span>
                            change verification method
                        </button>
                    </div>
                </ng-template>
                <ng-template #codeVerified>
                    <div class="d-flex justify-content-center mb-3">
                        <mat-spinner></mat-spinner>
                    </div>
                    <span>Redirecting...</span>
                </ng-template>
            </div>
        </div>
    </section>
    <mat-divider></mat-divider>
    <div class="d-flex justify-content-center mt-4 message">
        <p class="w-75 text-center">
            If you are still encountering issues, please contact Support at <b>{{cargosprintPhoneNumber}}</b> or send an
            email to
            <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
        </p>
    </div>
</mat-dialog-content>
