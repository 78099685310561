<!-- START: EMPTY CART -->
<section id="payment-confirmation" class="container-fluid p-4">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center payment-confirmation--height">
        <div class="row justify-content-center">
            @if(countPaymentErrors > 0) {
            <div class="logoContainer">
                <img class="logo" src="assets/images/sp-logo.svg" alt="SprintPay logo" />
            </div>
            <div class="col-12 error-information">
                <div class="errorBanner d-flex">
                    <div class="verticalAlign">
                        <mat-icon
                            color="warn"
                            class="material-symbols-outlined"
                            aria-hidden="false"
                            aria-label="cancel icon"
                            fontIcon="cancel"
                        ></mat-icon
                        >&nbsp;
                    </div>
                    <div>
                        <p class="verticalAlign">
                            <span class="mat-subtitle-1 banner-title"
                                >Unable to complete {{countPaymentErrors}} of your transactions</span
                            >
                        </p>
                        <p class="mat-subtitle-1 banner-description">
                            We could not process all of the transactions in your cart due to a technical issue on our
                            end. Please go to your cart and try again. If the issue keeps happening, contact
                            <a href="mailto: support@cargosprint.com"> support&#64;cargosprint.com </a>
                        </p>
                    </div>
                </div>
                <div class="button-cart">
                    <button mat-flat-button color="primary" (click)="goToCart()">GO TO CART</button>
                </div>
            </div>

            <div class="col-12">
                <section class="successBanner">
                    <h4 class="mat-headline-4 d-block text-center mt-4 mb-3 payment-confirmation-title">
                        {{countPaymentSuccessful || 0}} of {{totalPayments}} payment(s) were created successfully.
                    </h4>
                    <span class="mat-subtitle-1 mb-4 d-block text-center payment-confirmation-subtitle"
                        >Download your receipts below</span
                    >
                </section>
            </div>
            } @else {
            <div class="col-12">
                <img class="logo mx-auto my-2 d-block" src="assets/images/sp-logo.svg" alt="SprintPay logo" />
                <img
                    class="mx-auto d-block"
                    src="assets/images/payment-methods/success.svg"
                    alt="New user illustration"
                />
            </div>
            <div class="col-12 mb-3">
                <h4 class="mat-headline-4 d-block text-center mt-4 mb-3 payment-confirmation-title">
                    Your payment was created successfully!
                </h4>
                <span class="mat-subtitle-1 mb-4 d-block text-center payment-confirmation-subtitle">
                    Download your receipts below
                </span>
            </div>
            }
            <ng-container class="row" *ngIf="isThereInvoicesInCart && successfulResponse.length">
                <div class="col-sm-12 col-lg-6 my-3">
                    <div class="card">
                        <p class="card--title d-flex align-items-center mat-headline-6">
                            <i class="bi bi-download me-2"></i>Download
                        </p>
                        <p class="card--description">A copy of this payment(s)</p>
                        <div class="d-flex justify-content-start flex-row align-items-start">
                            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                                <div class="card--download w-100">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="getPaymentReceipt()"
                                        class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                                    >
                                        <i class="bi bi-file-earmark-pdf me-2"></i>Download Receipt
                                    </button>
                                </div>
                                <div class="card--download w-100">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="downloadExcel()"
                                        class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                                    >
                                        <i class="bi bi-file-earmark-spreadsheet me-2"></i>Download Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row justify-content-center" *ngIf="!isThereInvoicesInCart && successfulResponse.length">
                @if(successfulResponse && successfulResponse[0].completedPayments?.[0]?.isPaymentConfirmationAvailable){
                <div class="col-sm-12 col-lg-4 my-4">
                    <div class="card">
                        <p class="card--title d-flex align-items-center mat-headline-6">For the cargo facility</p>
                        <p class="card--description mat-subtitle-1">
                            A copy of the payment(s) confirmation has already been sent to the facility but you can
                            reprint it here.
                        </p>
                        <div class="card--download">
                            <button
                                (click)="getPaymentConfirmation()"
                                mat-button
                                type="button"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block align-items-center"
                            >
                                <span class="material-symbols-outlined"> description </span>

                                DOWNLOAD PAYMENT CONFIRMATION(S)
                            </button>
                        </div>
                    </div>
                </div>
                }
                <div class="col-sm-12 col-lg-4 my-4" *ngIf="!isPaymentsNotInvoicedEnabled">
                    <div class="card">
                        <p class="card--title d-flex align-items-center mat-headline-6">For your company</p>
                        <p class="card--description mat-subtitle-1">
                            The document(s) below are for your company use.
                            <span class="fw-bold">Please do NOT send to the cargo facility.</span>
                        </p>
                        <div
                            class="card--download"
                            *ngIf="paymentResponse.length && isCargoCreditPaymentMethod && !isTherePaymentMethodDifferentCargoCredit"
                        >
                            <button
                                mat-button
                                type="button"
                                (click)="downloadInvoices()"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <span class="material-symbols-outlined"> description </span>
                                Download invoice(s)
                            </button>
                        </div>
                        <div
                            class="card--download"
                            *ngIf="paymentResponse.length && isTherePaymentMethodDifferentCargoCredit"
                        >
                            <button
                                mat-button
                                type="button"
                                (click)="getPaymentReceipt(this.isCargoCreditPaymentMethod && this.isTherePaymentMethodDifferentCargoCredit ? undefined: paymentMethods.CARGO_CREDIT)"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <span class="material-symbols-outlined"> description </span>
                                {{paymentReceiptText}}
                            </button>
                        </div>
                        <div
                            class="card--download"
                            *ngIf="isThereInvoicesInCart && paymentResponse && !isCargoCreditPaymentMethod"
                        >
                            <button
                                mat-button
                                type="button"
                                (click)="downloadExcel()"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <span class="material-symbols-outlined"> description </span>
                                Download Statement
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: EMPTY CART -->
