import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { profileComponents } from 'src/app/utils/constants';
import { ProfileTypes } from 'src/app/utils/profile-types';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/utils/auth.service';
import { PortalSelectorComponent } from '../portal-selector/portal-selector.component';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    standalone: true,
    imports: [CommonModule, SharedModule, PortalSelectorComponent, MatToolbarModule, MatIconModule, RouterModule],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    @Output() toggleSidenav: EventEmitter<void> = new EventEmitter();
    @Output() redirectLink: EventEmitter<string> = new EventEmitter<string>();

    public ProfileTypesEnum = ProfileTypes;
    public profileType: string;
    public newPaymentSecurity: Array<string> = [''];
    public cartSecurity: Array<string> = [''];
    public showNewPayment: boolean;
    public showCart: boolean;
    public elementsInCart: number = 0;
    private userType: string = '';
    private _unsubscribe$: Subject<void>;

    constructor(
        private authService: AuthService,
        private customerService: CustomerService,
        private securityService: SecurityService,
        private router: Router,
        private cartBillService: CartBillService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.profileType = this.authService.getSprintPayProfileType();
        this.showNewPayment = String(this.router.url).indexOf('newPayment') <= 0;
        this.showCart = String(this.router.url).indexOf('cart') <= 0;
    }

    ngOnInit(): void {
        this.handleSecurity();
        this.subcribeToCurrentRoute();
        this.subscribeLengthCart();
        this.loadCustomer();
    }

    ngOnDestroy(): void {
        Swal.close();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    toggleMenu(): void {
        this.toggleSidenav.emit();
    }

    goToCart(path: string): void {
        this.redirectLink.emit(path);
    }

    /**
     * @method handleSecurity()
     * @description Handles the logic for the security based on user and roles
     */

    private handleSecurity(): void {
        this.userType = this.securityService.getUserType();
        if (this.securityService.verifyComponentsSecurity(profileComponents.newPaymentButton)) {
            this.newPaymentSecurity.push(this.userType);
        }
        if (this.securityService.verifyComponentsSecurity(profileComponents.cart)) {
            this.cartSecurity.push(this.userType);
        }
    }

    /**
     * @method subscribeLengthCart()
     * @description Return the number of element on the shopping cart
     */

    private subscribeLengthCart(): void {
        this.cartBillService
            .getLengthCart$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (length: number) => {
                    this.elementsInCart = length || 0;
                },
            });
    }

    private loadCustomer(): void {
        this.customerService
            .getProfileType$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((profileType) => {
                this.profileType = profileType;
            });
    }

    private subcribeToCurrentRoute(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd),
                takeUntil(this._unsubscribe$)
            )
            .subscribe((event: NavigationEnd) => {
                this.showNewPayment = String(event.url).indexOf('newPayment') <= 0;
                this.showCart = String(event.url).indexOf('cart') <= 0;
            });
    }
}
