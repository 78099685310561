import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    exports: [MatRadioModule, MatListModule, MatProgressSpinnerModule, MatIconModule],
})
export class CargoCreditMaterialModule {}
