// TODO: Update this class name - low
export class Config {
    static allowedDomains = [];

    static apisWithoutHeader = [
        '/uploadPaymentRequests',
        '/megaFileUpload',
        'bulk/startApprovalFlow',
        'bulk',
        'refund',
        'guest',
        'login',
        'downloadInvoicesByConfirmationId',
        'searchFacilities',
        'company',
        'preValidatePaymentRequest',
        'isValidCostCenter',
        'awbDuplicateCount',
        'downloadExternalReceipt',
        'approveMultiplePaymentRequests',
        'approvePaymentRequest',
        'editApprovalPaymentRequest',
        'cancelPaymentRequest',
        'addOpenInvoicesToCart',
        'countries',
        'activate',
        'compress',
        'recoverPassword',
        'logoff',
        'validateActivationKey',
        'recoverWithActivationKey',
        'sso/redirecturl',
        'maps/api',
    ];

    static apisWithoutContentType = [
        '/uploadPaymentRequests',
        '/megaFileUpload',
        'bulk/startApprovalFlow',
        'bulk',
        'refund',
        'guest',
        'login',
        'downloadInvoicesByConfirmationId',
        'searchFacilities',
        'company',
        'preValidatePaymentRequest',
        'isValidCostCenter',
        'awbDuplicateCount',
        'downloadExternalReceipt',
        'approveMultiplePaymentRequests',
        'approvePaymentRequest',
        'editApprovalPaymentRequest',
        'cancelPaymentRequest',
        'addOpenInvoicesToCart',
        'countries',
        'activate',
        'compress',
        'recoverPassword',
        'logoff',
        'validateActivationKey',
        'recoverWithActivationKey',
        'sso/redirecturl',
        'maps/api',
    ];

    static apisWithoutAccessControl = [
        '/uploadPaymentRequests',
        '/megaFileUpload',
        'bulk/startApprovalFlow',
        'bulk',
        'refund',
        'guest',
        'login',
        'downloadInvoicesByConfirmationId',
        'searchFacilities',
        'company',
        'preValidatePaymentRequest',
        'isValidCostCenter',
        'awbDuplicateCount',
        'downloadExternalReceipt',
        'approveMultiplePaymentRequests',
        'approvePaymentRequest',
        'editApprovalPaymentRequest',
        'cancelPaymentRequest',
        'addOpenInvoicesToCart',
        'countries',
        'activate',
        'compress',
        'recoverPassword',
        'logoff',
        'validateActivationKey',
        'recoverWithActivationKey',
        'sso/redirecturl',
        'maps/api',
    ];
}
