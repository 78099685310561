/**
 * This service acts as a helper for storing user actions, such as
 * *Payments submitted for the approver/requestor flow.
 * *Coda payments
 *
 * It provides functionality to handle the user's cart.
 */
import { Injectable } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CodaCharge } from 'src/app/models/utils/dynamic-fields-by-house';
import { SessionService } from '../session.service';
import { CartBillService } from './cart-service';

@Injectable({
    providedIn: 'root',
})
export class CartHandlerService {
    private thirdPartyChargesSubject: BehaviorSubject<CodaCharge[]> = new BehaviorSubject<CodaCharge[]>([]);
    private submitted: BehaviorSubject<PaymentCart[]> = new BehaviorSubject<PaymentCart[]>([]);

    constructor(
        private sessionService: SessionService,
        private cartBillService: CartBillService
    ) {
        const submitted: PaymentCart[] | null = this.sessionService.getElement('submittedItems');
        const charges: CodaCharge[] | null = this.sessionService.getElement('3rdPartyCharges');

        if (submitted) {
            this.submitted.next(submitted);
        }
        if (charges) {
            this.thirdPartyChargesSubject.next(charges);
        }
    }

    resetSubmittedItems(): void {
        this.saveSubmittedItems([]);
    }

    get instantSubmittedItems(): PaymentCart[] {
        return this.submitted.value;
    }

    setAndSaveSubmittedItems(submittedItem: PaymentCart): void {
        const newSubmittedItemsArray = [...this.submitted.value];
        const objIndex = newSubmittedItemsArray.findIndex((paymentCart) => paymentCart.id === submittedItem.id);

        if (objIndex === -1) {
            newSubmittedItemsArray.push(submittedItem);
        }

        this.saveSubmittedItems(newSubmittedItemsArray);
    }

    getSubmittedItems$(): Observable<PaymentCart[]> {
        return this.submitted.asObservable();
    }

    private saveSubmittedItems(submitted: PaymentCart[]): void {
        this.submitted.next(submitted);
        this.sessionService.saveElement('submittedItems', submitted);
    }

    reset3rdPartyItems(): void {
        this.save3rdPartyItems([]);
    }

    setAndSave3rdPartyItems(charge: CodaCharge): void {
        const newCharges = [...this.thirdPartyChargesSubject.value];
        const objIndex = newCharges.findIndex(
            (codaCharge) => codaCharge.MBL === charge.MBL && codaCharge.HBL === charge.HBL
        );

        if (objIndex === -1) {
            newCharges.push(charge);
        }

        this.save3rdPartyItems(newCharges);
    }

    get3rdPartyItems$(): Observable<CodaCharge[]> {
        return this.thirdPartyChargesSubject.asObservable();
    }

    has3rdPartyItems(): boolean {
        return !!this.thirdPartyChargesSubject.value.length;
    }

    hasSubmittedItems(): boolean {
        return !!this.submitted.value.length;
    }

    get3rdPartyItemsByHBL(): CodaCharge[] {
        let chargesGrouped: CodaCharge[] = [];
        if (this.thirdPartyChargesSubject.value) {
            const charges = this.thirdPartyChargesSubject.value;
            const groupedByHBL = charges.reduce((acc: CodaCharge[], charge: CodaCharge) => {
                if (charge['MBL']) {
                    acc[charge['MBL'] + charge['HBL']] = acc[charge['MBL'] + charge['HBL']] || [];
                    acc[charge['MBL'] + charge['HBL']].hbl = charge['HBL'];
                    acc[charge['MBL'] + charge['HBL']].mbl = charge['MBL'];
                    acc[charge['MBL'] + charge['HBL']].push(charge);
                }
                return acc;
            }, []);
            chargesGrouped = Object.keys(groupedByHBL).map((key: string) => groupedByHBL[key]);
        }
        return chargesGrouped;
    }

    private save3rdPartyItems(charges: CodaCharge[]): void {
        this.thirdPartyChargesSubject.next(charges);
        this.sessionService.saveElement('3rdPartyCharges', charges);
    }

    remove3rdPartyItems(amount: number, description: string, hbl: string): Observable<CodaCharge[]> {
        return this.get3rdPartyItems$().pipe(
            map((charges) => {
                return charges.filter((charge: CodaCharge) => {
                    return (
                        charge['HBL'] !== hbl ||
                        (charge['Amount to pay'] !== amount && charge['Description'] !== description) ||
                        (charge['Amount to pay'] !== amount && charge['Description'] === description)
                    );
                });
            }),
            map((charges) => {
                this.save3rdPartyItems(charges);
                return charges;
            })
        );
    }

    getChargeInPaymentCartByAmountAndTypeAndHawb$(
        amount: number,
        type: string,
        hawb: string
    ): Observable<PaymentCart | undefined> {
        return this.cartBillService.getCartPaymentRequests$().pipe(
            map((paymentRequests) => {
                return paymentRequests.find((paymentRequest: PaymentCart) => {
                    return (
                        paymentRequest.amount === amount &&
                        paymentRequest.paymentType === type &&
                        hawb === paymentRequest.hawb
                    );
                });
            })
        );
    }

    getChargeSubmittedByAmountAndTypeAndHawb$(
        amount: number,
        type: string,
        hawb: string
    ): Observable<PaymentCart | undefined> {
        return this.getSubmittedItems$().pipe(
            map((cart) => {
                return cart.find((paymentRequest: PaymentCart) => {
                    return (
                        paymentRequest.amount === amount &&
                        paymentRequest.paymentType === type &&
                        hawb === paymentRequest.hawb
                    );
                });
            })
        );
    }
}
