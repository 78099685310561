<div id="dropin-container"></div>
<div
    id="paypal-button"
    class="w-100 d-flex justify-content-center px-3"
    [ngClass]="{disabled: !isTermsOfUseValid}"
></div>
<div id="paypal-button-processing" class="d-none">
    <button class="btn__sprintpay--gray primary-gray block card-summary__btnPay">
        <span role="status" aria-hidden="true" style="margin-left: 10px"></span>
    </button>
</div>
