import { Component } from '@angular/core';
import { ImageLoaderComponent } from 'src/app/standalone-components/image-loader/image-loader.component';

@Component({
    standalone: true,
    imports: [ImageLoaderComponent],
    selector: 'app-empty-card-list',
    templateUrl: './empty-card-list.component.html',
    styleUrls: ['./empty-card-list.component.scss'],
})
export class EmptyCardListComponent {}
